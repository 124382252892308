@import '~stylesheets/utils/index';

.base {
  position: relative;

  h2 {
    border-bottom: 1px solid #68bbfd;
    padding-bottom: 16px;
    margin-bottom: 16px;
    font-family: $base-font-family;
    text-transform: none;
    color: $color-dark-blue;
    @include font-size(28px);
    margin: 32px 0 0;
  }

  table {
    border-collapse: collapse;
    border: none;

    @include media($min-sm) {
      table-layout: fixed;
    }
  }

  th,
  td {
    font-weight: 300;
    @include type-scale(16px, 12px);
    color: $color-dark-blue;
    letter-spacing: 0.75px;
    text-align: left;
    padding: 6px 8px 6px 0;
    margin: 0;
    border: none;

    &:last-child {
      padding-right: 0;
    }
  }

  th {
    vertical-align: bottom;
  }

  td {
    font-weight: 600;
  }

  @include media($max-sm) {
    tr,
    td {
      padding: 2px;
      height: 95px !important;
    }
  }
}
