@import '~stylesheets/utils/index';

.base {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include fill-viewport-height;

  h1 {
    color: $accent-secondary;
    text-transform: none;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    letter-spacing: -0.025em;
    line-height: 0.75em;
    margin: 32px 0;

    // Font-size controls layout
    @include type-scale(72px, 46px);

    .ampersand {
      color: #bcdffe;
      transform: scale(1.6);
      z-index: 1;
      display: block;
    }

    span {
      display: block;
      position: relative;
      z-index: 2;

      &:first-child {
        width: 100%;
      }
    }
  }

  h2 {
    @include type-scale(28px, 24px);
    color: $accent-primary;
    line-height: 1;
    font-weight: 300;
    padding: 0;
    margin: 0;

    strong {
      font-weight: 900;
    }
  }

  hr {
    margin: 32px 0;
    background: #bcdffe;
  }
}

.coverImage {
  @include wallpaper;
  background-image: url('./images/splash-image.jpg');
  position: absolute;
  z-index: -1;
  @include trbl;

  @include media($min-sm) {
    @include trbl(0, 0, 0, 25%);
  }

  &:after {
    content: '';
    position: absolute;
    @include trbl;

    @include media( $max-sm ) {
      background: white;
      opacity: 0.8;
    }

    @include media($min-sm) {
      background-image: linear-gradient(
        90deg,
        white 30%,
        transparentize(white, 1)
      );
    }
  }
}

.container {
  @include content-container;

  > * {
    max-width: 768px;
  }
}

.timelineSummary {
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
  line-height: 1.2rem;

  > *:first-child {
    height: 42px;
    margin-right: 16px;
    width: 80px;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
  }

  > *:nth-child(2) {
    flex-grow: 1;
  }

  h3 {
    margin: 0;
    padding: 8px 0 4px;
    @include font-size(24px);
    line-height: 1.6rem;
    color: $accent-secondary;
    text-transform: uppercase;
  }

  ul {
    @include font-size(16px);
    padding-left: 16px;
    margin: 0;

    @include media($max-sm) {
      display: none;
    }
  }
}

.scrollPrompt {
  display: flex;

  > *:first-child {
    height: 42px;
    margin-right: 16px;
    width: 80px;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
  }

  span {
    @include font-size(18px);
    font-weight: bold;
    text-transform: uppercase;
    color: $accent-primary;
  }
}