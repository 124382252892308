@import '~stylesheets/utils/index';

.container {
  padding-top: 16px;

  @include content-container;

  h3 {
    color: $base-font-color;
  }
}

.chartContainer {
  position: relative;
}

.mainPlotHeader {
  position: relative;
  z-index: $middleground;
}

.mainPlot {
  position: relative;
  margin: 16px auto 32px;
}

.countryPlots {
  @include media($min-sm) {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    > * {
      width: calc(50% - 24px);
    }
  }
}

.contentTop {
  margin-top: -150px;
  padding-top: 150px;
  margin-bottom: 20px;
}

.details {
  @include media($min-sm) {
    // @include grid(2);
    flex-direction: column;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 16px;
  }

  h5 {
    margin: 0 0 16px;
  }
}

.stateLegendItem {
  display: flex;
  align-items: flex-start;
  @include font-size(14px);
  line-height: 1.2;
  color: #606161;

  img {
    flex-shrink: 0;
    display: block;
    margin-right: 16px;
  }

  .greener {
    color: #63a89d;
    font-weight: bold;
  }

  .redder {
    color: #cd7561;
    font-weight: bold;
  }

  &#USLegend1 {
    @include media( $max-sm ) {
      display: none;
    }
  }
}
