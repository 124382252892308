@import "./var";

.approach__icon {
  width: 40px;
  height: 40px;
  align-self: center;
  &.actionable {
    cursor: pointer;
  }
}

.table__wrapper {
  width: 100%;
  position: relative;
  max-width: 1088px;
  margin: 0 auto;
}

.table__container {
  margin: 30px 0;
  clear: both;
  position: relative;
}

table.table td,
table.table th,
table.table {
  border: none;
  margin: 0;
}

.table__scroll_indicator {
  display: none;
  float: right;
  height: 1.2em;
  width: auto;
  margin: 0.5em;
}

@media only screen and (max-width: $s--phone) {
  table.table {
    max-width: 100%;
    overflow-x: scroll;
    display: flex;
    flex-direction: column;
    position: relative;

    .table__body,
    .table__head {
      width: calc(300% - 184px);
    }
  }

  .table__scroll_indicator {
    display: block;
  }

  .table__scroll_fade {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    min-width: 40px;
    width: 15%;
    background: linear-gradient(to left, white 20%, transparentize(white, 1));
    pointer-events: none;
    transition: opacity 0.35s;

    &.hidden {
      opacity: 0;
    }
  }
}

.table__filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: $s--medium;
  @media only screen and (max-width: $s--phone) {
    flex-wrap: wrap;
    .region__select,
    .how__to__read__graphic {
      flex: 0 0 100%;
      margin-bottom: $s--medium;
    }
  }
  .how__to__read__graphic button {
    border: 1px solid #002e72;
    background-color: white;
    color: $c--blue-dark;

    &:focus {
      outline: none;
      border: 1px solid #002e72;
    }
  }
}

.table__filters__left {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

table.table {
  display: flex;
  flex-direction: column;
}

table .table__head {
  display: flex;
  width: 100%;
  background-color: $c--gray-lightest;
}

table .table__head .table__cell {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

table .table__sort {
  flex-direction: row;
  &:hover {
    color: $c--blue-dark;
  }

  &.MuiTableSortLabel-active {
    color: $c--blue-dark;
    opacity: 1;
  }

  > svg {
    opacity: 0.3;
  }
}

.table__row {
  .table__body & {
    border-color: $c--gray-lighter;
  }
}

.table .table__row {
  display: flex;
  width: 100%;

  .table__cell {
    font-family: $ff--regular;
    padding: 10px 15px;
    border-bottom: 1px solid $c--gray-lighter;
    border-right: 1px solid $c--gray-lighter;

    &:first-child {
      flex: 0 0 150px;
      width: 150px;
      &.positivity {
        justify-content: center;
      }
    }

    &:last-child {
      padding-right: 10px;
      border-right: none;
    }

    &:not(:first-child) {
      flex: 0 0 calc(33.3333% - 50px);

      &.positivity {
        flex: 1;
      }
    }

    &.selected {
      border: 3px solid #ddae11;
    }

    &.not-supported::before {
      content: "";
      position: absolute;
      background: linear-gradient(0deg, rgba(232, 117, 99, 0.1), rgba(232, 117, 99, 0.1)), rgba(255, 255, 255, 0.8);
      z-index: 10;
      //state cell + width of each column * skip items(STOPPED_APPROACH - 1)
      left: calc(150px + (100% - 150px) / 5 * 2);
      top: 0;
      width: calc((100% - 150px) / 4.9);
      height: 100%;

      @media only screen and (max-width: $s--phone) {
        //state cell + width of each column * skip items(STOPPED_APPROACH - 1)
        left: calc(90px + (100% - 90px) / 1.67 * 2);
        width: calc((100% - 90px) / 1.67);
      }
    }

    &.no__data {
      cursor: pointer;
      display: flex;
      justify-content: center;
      img:last-child {
        display: none;
      }
      &:hover img:last-child {
        display: block;
      }
      &:hover img:first-child {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: $s--phone) {
  .table .table__row .table__cell {
    padding: 10px 5px;
    &:first-child {
      flex: 0 0 92px;
      padding: 5px;
      position: sticky;
      background-color: #fff;
      left: 0;
    }

    &:not(:first-child) {
      flex: 0 0 calc(100vw - 144px);
    }
  }
  .table .table__head .table__row .table__cell:first-child {
    z-index: 100;
    background-color: $c--gray-lightest;
  }
}

.table__body .table__row .table__cell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.table__cell--state {
    justify-content: flex-start;
    background-color: #fff;
    z-index: 3000;

    :last-child {
      padding-top: 5px;
    }
  }
}

@media only screen and (max-width: $s--phone) {
  .table__body .table__row .table__cell.table__cell--state {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.table .table__head .table__row > .table__cell {
  border-bottom: 3px solid $c--gray-lighter;
  padding-bottom: 0;
}

.table__state-name {
  margin-right: 5px;
}

.table__heading {
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  color: $c--blue-dark;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.table__sub-heading {
  color: $c--text;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  line-height: 1.2;

  > span {
    width: auto;
  }

  @media only screen and (max-width: $s--tablet) {
    align-items: flex-end;
    > span {
      &:first-child {
        flex: 0 0 60%;
        margin-right: 15px;
      }

      &:last-child {
        flex: 0 1 calc(40% - 15px);
      }
    }
  }
  @media only screen and (max-width: $s--phone) {
    > span {
      &:first-child {
        flex: 0 0 55%;
        // margin-right: 40px;
        margin-right: 15px;
      }

      &:last-child {
        flex: 0 1 calc(45% - 15px);
      }
    }
  }
}

.table__state-name {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: $c--text;

  @media only screen and (max-width: $s--phone) {
    font-size: 12px;
    flex: 0 0 100%;
    text-align: center;
  }
}

.table__chart {
  display: flex;
  flex-direction: column;
  width: 50%;
  @media only screen and (max-width: $s--phone) {
    flex: 0 0 55%;
    margin-right: 15px;
  }
}

.table__trend {
  display: flex;
  flex-direction: row;
  width: 34%;
  @media only screen and (max-width: $s--phone) {
    flex: 1 1 calc(45% - 15px);
  }
}

.table__trend__column {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  line-height: 1.2;
  align-items: center;
  text-align: center;
  &:first-child {
    margin-right: 5px;
  }
  .this__week {
    color: #515c6a;
    margin-bottom: 5px;
  }

  .last__week {
    margin-bottom: 5px;
    color: #cbcbcb;
  }
}

.calc__method {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  width: 85px;
  .numerator {
    padding-bottom: 3px;
    border-bottom: 1px solid black;
  }
  .denominator {
    padding-top: 3px;
  }
}

.visualization__container {
  width: 100%;
  height: 100%;
}

.number {
  font-size: 16px;
  line-height: 1.1;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: $c--text;
  font-family: $ff--regular;
}

.tooltip__group {
  text {
    font-size: 10px;
    text-anchor: middle;
  }
}

.table__header {
  font-size: 26px;
  font-weight: bold;
  text-align: left;
  color: $c--blue-dark;
  margin-bottom: 10px;
}

.region__select > div {
  margin-left: 0;
  margin-right: 0;
}

.table__body {
  position: relative;
  .stopped {
    position: relative;
  }
  .stopped::before {
    content: "";
    position: absolute;
    background: linear-gradient(0deg, rgba(232, 117, 99, 0.1), rgba(232, 117, 99, 0.1)), rgba(255, 255, 255, 0.8);
    z-index: 3001;
    width: 100%;
    height: 100%;
  }
  .stopped-reporting-text {
    position: absolute;
    top: 0px;
    left: 150px;
    z-index: 3001;
    display: flex;
    flex-direction: column;
    width: calc(100% - 150px);
    padding: 0 10px;

    h3 {
      font-size: 16px;
      margin: 0;
    }
    p {
      line-height: 16.8px;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
    .stopped-reporting-date {
      color: #e87563;
      padding-bottom: 0;
      font-weight: 500;

      span {
        font-weight: 400;
        color: #8e8e8e;
      }
    }

    @media only screen and (max-width: $s--phone) {
      left: 90px;
      width: calc(100% - 90px);
    }
  }
}

.not-supported-text {
  position: absolute;
  top: 105px;
  z-index: 3000;

  h3 {
    font-size: 16px;
    margin: 10px 0;
  }
  p {
    line-height: 16.8px;
    font-weight: 400;
  }

  width: calc((100% - 150px) / 6);

  @media only screen and (max-width: $s--phone) {
    width: calc((100% - 90px) / 1.67);
    padding: 0 10px;
  }
}
