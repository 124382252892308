@import '~stylesheets/utils/index';

.container {
  @include content-container;
  margin-bottom: 32px;

  ul {
    margin-top: 0;
  }
}

.intro {
  @include media($min-md) {
    @include grid(2);

    > * {
      margin-bottom: 0;
    }
  }
}

.introText {
  position: relative;
  >ul{
    padding-left: 15px;
  }
}

.introVideo {
  margin-bottom: 0;

  @include media($max-md) {
    margin-top: 32px;
  }
}

.introRight{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border-left: 2px solid; */
    align-items: center;  
}

.videoContainer {
  @include video-container;
  border: 1px solid #e8f5ff;
  box-shadow: 0 0 0 8px #d7e9f7;
}

.modules {
  @include content-section;
  border-top: 1px solid #d9d9d9;
  padding: 32px 16px;

  @include media($min-md) {
    padding: 48px 0;
  }

  h2 {
    margin-top: 0;
  }
}

.moduleCards {
  @include media($min-sm) {
    @include grid(2);
  }
}

.button {
  @include generic-button(  );
  display: inline-block;
  border: 1px solid;
  background-color: transparent;
  color: #226fb7;
  padding: 8px 16px;
  border-radius: 3px;
  text-decoration: none;
  margin: 16px 0 32px;  
}
