@import "~stylesheets/utils/index";

.container {
  @include vw-container($sm);

  > ul {
    list-style-type: none;
    padding-bottom: 32px;
    border-bottom: 1px solid lightgray;
    margin-bottom: 32px;

    > li {
      font-size: 22px;
      font-weight: 500;
      color: #110f24;
    }
    > li:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  ul li::before {
    content: "\25AA"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: $color-blue; /* Change the color */
    font-size: 30px;
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }
}

.blockQuote {
  color: $color-dark-blue;
  font-style: italic;
  font-weight: 600;

  @include media($min-md) {
    font-size: 39px;
    line-height: 57px;
    margin: 40px -96px;
  }
  @include media($max-md) {
    font-size: 24px;
    line-height: 34px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
