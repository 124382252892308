@import "~stylesheets/utils/index";

.base {
  overflow: hidden;
}

.chooser {
  @include select-wrapper($accent-secondary, transparent, white);
  border-radius: 4px;
}

.chartContainer {
  margin: 32px 0;

  @include media($max-sm) {
    width: 110vw;
    @include centerX;
  }
}

.snapshotMenu {
  display: none;
}

.controlsContainer {
  display: flex;

  @include media($min-sm) {
    align-items: center;
    > :not(:last-child) {
      margin-right: 24px;
    }
  }
  @include media($max-sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.zoom {
  cursor: move;
  fill: none;
  pointer-events: all;
}
