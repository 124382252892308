@import "~stylesheets/utils/index";

.base {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 9px 8px;

  .selectIcon {
    position: absolute;
    z-index: 250;
    right: 26px;
    height: 30px;
    width: 90%;
    display: flex;
    justify-content: flex-end;
    &.chevronUp {
      transform: rotate(270);
    }
    &.chevronDown {
      transform: rotate(90);
    }
  }

  .selectDisplay {
    margin: 0;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    min-width: 180px;

    font-size: 14px !important;
    font-weight: 600 !important;
    color: $color-dark-blue;
    text-transform: capitalize;
    text-overflow: ellipsis;
    line-height: 1.8;
    // letter-spacing: 0.1em;

    @include media($min-md) {
      max-width: 296px;
    }
  }
}

.triangleIcon {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 5px 0 5px;
  border-color: $color-dark-blue transparent transparent transparent;
  &.open {
    border-width: 0 5px 10px 5px;
    border-color: transparent transparent $color-dark-blue transparent;
  }
}
