@import "~stylesheets/utils/index";

.main {
  display: block;

  table {
    border-collapse: collapse;
    border: none;
    table-layout: fixed;
    margin-top: 0;

    @include media($max-sm) {
      width: 135%;
    }
    @media screen and (max-width: 360px) {
      width: 165%;
    }

    tr {
      height: 32px;
    }
  }

  th,
  td {
    text-align: left;
    padding: 6px 8px 6px 0;
    margin: 0;
    border: none;

    &:last-child {
      padding-right: 0;
    }
  }

  thead th {
    padding-bottom: 0;

    div {
      display: flex;
      padding-bottom: 0.75rem;

      > div {
        flex: 1;
        padding: 0;
      }

      &.inactive {
        margin-bottom: -0.9px;
      }

      &.inactive:hover {
        border-bottom: 2px solid;
        padding-bottom: calc(0.75em - 0.9px);
        margin-bottom: -0.9px;
        opacity: 0.7;
      }

      &.descending,
      &.ascending {
        border-bottom: 2px solid;
        padding-bottom: calc(0.75em - 0.9px);
        margin-bottom: -0.9px;
      }

      &.inactive:hover::after {
        content: "\25BC";
        align-self: flex-end;
        font-size: 12px;
      }

      &.descending::after {
        content: "\25BC";
        align-self: flex-end;
        font-size: 12px;
      }

      &.descending:hover::after {
        content: "\25B2";
        align-self: flex-end;
        font-size: 12px;
      }

      &.ascending::after {
        content: "\25B2";
        align-self: flex-end;
        font-size: 12px;
      }

      &.ascending:hover::after {
        content: "x";
        font-weight: bolder;
        align-self: flex-end;
        font-size: 12px;
      }
    }
  }

  th {
    @include type-scale(14px, 14px);
    color: $color-blue;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 400;
    vertical-align: bottom;
    padding-bottom: 0.75rem;
    cursor: pointer;
  }

  td {
    @include type-scale(18px, 16px);
    color: $color-dark-blue;
    letter-spacing: 0.025em;
    font-weight: 400;
    a {
      @include type-scale(18px, 16px);
      height: fit-content;
      img {
        margin-left: 6px !important;
      }
    }
  }

  thead {
    box-shadow: 0 1px 0 0 $color-light-gray;
  }

  tbody tr:first-child td {
    padding-top: 1rem;
  }

  .buttonContainer {
    padding-bottom: 16px;

    button {
      background: none;
      border: 1px solid gray;
      border-radius: 0;
      font-size: 1.1rem;
      padding: 0.5rem 1.75rem 0.5rem 2.25rem;
      cursor: pointer;

      display: flex;
      align-items: center;
      svg {
        @include svg-color($color-blue);
        width: 1.5em;
        height: 0.75em;
        margin: 0.4rem 0 0.4rem 0.5rem;
        transition: transform 0.3s;
      }

      &.expand-button svg {
        transform: rotate(90deg);
      }
      &.collapse-button svg {
        transform: rotate(-90deg);
      }
    }
  }
}

@include media($max-md) {
  .tableOverflow {
    overflow-x: auto;

    &.useMobileColumn {
      overflow-x: scroll;
    }

    @include media($max-xs) {
      width: 400px;
    }
    @media screen and (max-width: 414px) {
      width: 369px;
    }
    @media screen and (max-width: 375px) {
      width: 354px;
    }
    @media screen and (max-width: 320px) {
      width: 288px;
    }
  }
  .tableBase {
    margin-top: 0;
    min-width: 556px;

    @media screen and (max-width: 414px) {
      min-width: 540px;
    }
    @include media($min-sm) {
      min-width: 656px;
    }
  }
}

.mobileColumn {
  width: 152px;
  position: absolute;
  z-index: 0;
  padding-right: 8px;
  background: white;
  box-shadow: 10px 0 5px -2px #8888884a;

  > table {
    margin-top: 0;
    margin-bottom: 0;
  }
}

thead {
  th.firstCol {
    width: 80px !important;

    > div {
      width: min-content;
    }
  }
}
