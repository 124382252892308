@import '~stylesheets/utils/index';

.frequently-asked-questions,
.protecting-your-health {
  margin: 32px 0;

  a {
    width: 100%;
    display: block;
    box-shadow: 0 2px 12px #d1d1d1;
    border-radius: 4px;
    padding: 12px 16px;
    margin-bottom: 16px;

    @include font-size(20px);
    font-weight: 600;

    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 9px 24px #7d7d7d;
      transform: scale(1.05);
      background-color: $color-blue;
      color: white;

      svg {
        @include svg-color(white);
      }
    }
  }
}
