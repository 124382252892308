// ------------------------------------------------------------
//   Miscellaneous Mixins
// ------------------------------------------------------------

// Background Mask
// Masks over the background of the element but under its content.
@mixin mask-bg($bg-color, $opacity: 1) {
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: $bg-color;
    opacity: $opacity;
  }
}

@mixin circle($diameter: 10px) {
  width: $diameter;
  height: $diameter;
  border-radius: $diameter/2;
}

@mixin content-section {
  padding: 32px;
  border-bottom: 1px solid $base-border-color;

  @include media($min-sm) {
    padding: 72px 0;
  }

  h2 {
    color: $base-font-color;
  }
}

@mixin content-container {
  @include vw-container();
  width: 100%;
}

@mixin section-intro {
  @include media($min-sm) {
    // max-width: 768px;

    display: flex;
    align-items: center;
    margin-bottom: 48px;

    @include media($min-sm) {
      margin-bottom: 56px;
    }

    h2 {
      margin-top: 0;
      margin-right: 32px;
      padding-right: 32px;
      margin-bottom: 0;
      border-right: 1px solid $accent-primary;
      width: 40%;
      flex-shrink: 0;
    }

    p {
      padding: 0;
    }
  }
}

@mixin svg-animate-color() {
  transition: stroke 123ms, fill 123ms;
  g,
  path,
  polygon,
  text {
    transition: stroke 123ms, fill 123ms;
  }
}

@mixin svg-color($color) {
  stroke: $color;
  fill: $color;

  g,
  path,
  polygon,
  text {
    stroke: $color;
    fill: $color;
  }
}

@mixin new-badge-styles() {
  display: inline-block;
  background: $color-yellow;
  padding: 0.3em 0.77em 0.25em;
  @include font-size(13px);
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  color: $color-dark-blue;
}

@mixin new() {
  position: relative;

  &::after {
    content: "New";
    position: absolute;
    top: 6px;
    right: -2px;
    @include new-badge-styles();
  }
}

@mixin remove-button-appearance {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}
