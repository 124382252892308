.container {
  position: relative;
}
.tooltip {
  line-height: 1.1;
  pointer-events: none;
  padding: 2px;
  font-size: 12px;
  transition: opacity .1s;
}
