@import '~stylesheets/utils/index';

.container {
  p,
  ol,
  ul,
  table {
    max-width: 92ch;
  }

  table {
    border-collapse: collapse;
  }
}
