@import './var';

.map {
  width: 100%;
  position: relative;
  max-width: 1088px;
  margin: 0 auto;

  .filter__row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    @media only screen and (max-width: $s--phone) {
      flex-wrap: wrap;
      .dataset__select {
        flex: 0 0 100%;
        margin-bottom: $s--medium;
      }
    }
    .download__wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      :last-child {
        margin-left: 5px;
      }
    }
  }

  .filter__row--branding {
    margin-bottom: 2rem;
  }

  .filter__left__side {
    display: flex;
    flex-direction: column;
  }

  .filter__legend {
    display: flex;
    margin-top: $s--medium;
    margin-bottom: $s--medium;
  }

  .map__row {
    display: flex;
  }

  .map__cell {
    color: $c--text;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: $border-radius;

    transition-duration: $t--dur;
    transition-property: transform, background-color, color;

    &.is-state {
      background-color: $c--gray-light;
      cursor: pointer;

      &::before {
        content: '';
        background-color: transparent;
        position: absolute;
        width: calc(100% - 3px);
        height: calc(100% - 3px);
        border: 0 solid $c--black;
        border-radius: $border-radius;

        z-index: 1;
        transition: $t--dur opacity, $t--dur border-width;
        opacity: 0;
      }

      &:hover,
      &:focus {
        z-index: 5;
      }
      &:focus { outline: 0; }


      &:hover:not(.background-color--und)::before,
      &:focus:not(.background-color--und)::before {
        opacity: 1;
        border-width: 3px;
      }
    }
  }

  .map__legend-wrapper {
    display: flex;
    justify-content: center;
    min-height: 35px;
  }

  .map__legend {
    text-align: center;
    height: 35px;
  }

  .no__data {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 12px;
    color: #9697A0;
    margin-left: 50px;
    align-items: center;

    .color__block {
      background-color: #D3D3D3;
      width: 46px;
      height: 11.3px;
      margin-bottom: 4px;
    }
  }

  .map__legend-items {
    display: flex;
    align-items: center;
  }

  .map__legend-label {
    text-transform: uppercase;
    padding: 0 4px;
    font-size: 15px;
    color: $c--gray;
  }

  .map__legend-label-decreasing {
    margin-right: 5px;
    @media screen and (min-width: 460px){
      margin-right: 37.8px;
    }
    @media screen and (min-width: 411px){
      margin-right: 15px;
    }
  }

  .map__legend-label-increasing {
    margin-right: 5px;
    @media screen and (min-width: 460px){
      margin-right: 37.8px;
    }
    @media screen and (min-width: 411px){
      margin-right: 15px;
    }
  }

  .map__legend-item {
    width: 35px;
    height: 10px;
  }

  @for $i from 1 through 15 {
    .delay-#{$i - 1} {
      transition-delay: $t--delay-int * $i;
      transition-duration: $t--dur;
    }
  }
}

.filter__header-section {
  display: flex;
  flex-direction: row;
  align-items: center;

  .filter__header {
    font-size: 26px;
    font-weight: bold;
    text-align: left;
    color: $c--blue-dark;
  }
}

.filter {
  position: relative;
}

.map__inner {
  position: relative;
  @media only screen and (max-width: $s--phone) {
    width: calc(100vw - 10px);
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 768px){
    padding-left: 10px;
  }

  @media screen and (min-width: 1280px) and (max-height: 920px) {
    transform: scale(0.70);
    margin-top: -64px;
    margin-left: -128px;
  }
}

.map__download-link {
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: $s--small;
}

.dataset__select > div {
  margin-left: 0;
  margin-right: 0;
}

.map__attribution {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 2em 0 0;
  text-align: center;
  padding: 0;
}

.page__map.for-export {
  max-width: 550px;
  padding: $s--small;
  .map__download-link,
  .filter__row--nav,
  .popover--info {
    display: none;
  }
}
