@import '~stylesheets/utils/index';

.base {
  display: flex;
  align-items: center;
  margin: 8px 0 16px;

  button {
    margin: 0 3px;
    display: block;
    height: 24px;

    &:hover {
      opacity: 0.5;
    }
  }
  @media print {
    display: none;
  }
}

.label {
  color: $color-blue;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  @include font-size(12px);
  margin-right: 4px;
}
