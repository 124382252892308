// -----------------------------------------------
//    Variables
// -----------------------------------------------

// Color palette
$color-dark-blue: #092c74;
$color-blue: #226fb7;
$color-light-blue: #79bffe;
$color-yellow: #f1c400;
$color-black: #110f24;
$color-dark-gray: #535353;
$color-medium-gray: #8e8e8e;
$color-light-gray: #e5e7e9;
$color-white: #ffffff;
$tran: transparent;

// Secondary color
$color-red: #cf4520;
$color-red-text: #76232f;

// Color Roles:
// Roles can change more easily than named colors.
$accent-primary: $color-blue;
$accent-secondary: $color-dark-blue;
$accent-dk: $color-black;
$base-border-color: $color-light-gray;
$base-font-color: $color-dark-gray;
$base-font-color-light: $color-medium-gray;
$base-link: $accent-secondary;
$base-link-hover: $accent-primary;
$base-form-color: $accent-secondary;

$background-color: $color-white;
$foreground-color: $color-white;

// Fonts:
$em-base: 16px;

$base-font-family: "gentona", Tahoma, sans-serif;
$base-font-size: em(16);
$base-line-height: 1.5;

$heading-font-family: "gentona", Tahoma, sans-serif;
$heading-font-size: em(16);
$heading-line-height: 1.5;

// Measurements:
$padding: 16px;
$header-height: 120px;
$header-height-lg: 150px;
$sidebar-width: 400px;
$sidebar-width-lg: 500px;
$sidebar-width-xlg: 600px;

// Depths:
$background: 1000;
$middleground: 2000;
$foreground: 3000;

// Animation Styles:
$interaction-shift: 222ms ease-out;
$layout-shift: 333ms ease-out;
$overlay-shift: 456ms ease-out;
