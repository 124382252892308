@import "~stylesheets/utils/index";

.base {
  display: flex;
  background: #fbfbfb;
  border-left: 8px solid #226fb7;
  padding: 20px 20px;

  @include media($max-lg) {
    gap: 32px;
  }
  @include media($max-sm) {
    @include clearfix;
    gap: 0;
  }

  @include media($min-lg) {
    align-items: center;
  }

  .contentBlock {
    display: flex;
    flex-direction: row;
    gap: 16px;

    @include media($max-sm) {
      gap: 14px;
      flex-direction: column;
      margin-bottom: 32px;
    }
  }

  .imageBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 129.23px;
    max-height: 118px;
    gap: 12px;

    .imageContainer {
      img {
        height: 100%;
        width: 100%;
      }
    }
    &.multi {
      .imageContainer {
        max-height: 54px;
      }
    }
  }

  .textBlock {
    @include media($min-sm) {
      align-self: center;
    }

    max-width: 712px;
    flex: 1;

    font-size: 14px;
    .title {
      margin-top: 0;
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: bold;
      color: #226fb7;
    }

    p {
      padding: 0;
      line-height: 17px;
    }
  }

  .buttonBlock {
    display: flex;
    gap: 12px;

    @include media($min-lg) {
      flex-direction: column;
      width: 229px;
      margin-left: auto;
      .button {
        justify-content: space-between;
      }
    }
    @include media($max-lg) {
      .button {
        justify-content: space-between;
      }
    }

    @include media($max-sm) {
      flex-direction: column;
      width: fit-content;
    }
  }

  @include media($max-lg) {
    flex-direction: column;
    .imageBlock {
      width: 129.23px;
      max-height: 118px;
      gap: 12px;

      .imageContainer {
        img {
          height: 100%;
        }
      }
    }

    .buttonBlock {
      float: right;

      .button {
        font-size: 16px;
      }
    }
  }

  @include media($max-sm) {
    .imageBlock {
      flex-direction: row;
      width: 224.23px;
      max-height: 118px;
      gap: 24px;
      align-items: center;

      &.multi {
        .imageContainer {
          img {
            max-height: 54px;
          }
        }
      }
    }
  }
}
