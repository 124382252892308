@import "~stylesheets/utils/index";

.base {
  display: flex;
  flex-direction: column;
  flex: 1;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #535353;
    padding: 0;
  }

  .introBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    gap: 8px;

    .header {
      margin: 0;
      font-family: "Gentona";
      font-style: normal;
      font-weight: 400;
      font-size: 29px;
      line-height: 35px;
      text-transform: capitalize;

      color: #092c74;
    }
  }

  .contentBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;

    .pageBlock {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .button {
        justify-content: flex-start;
        padding-left: 0;
        height: max-content;
        background-color: transparent;
        color: #092c74;

        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;

        @include media($max-md) {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }

  @include media($max-sm) {
    margin-top: 30px;
    @include clearfix;
  }

  display: flex;
  @include media($min-md) {
    align-items: center;
  }

  .textBlock {
    max-width: 772px;
  }

  .textBlock {
    flex: 1;

    font-size: 14px;
    .title {
      margin-top: 0;
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: bold;
      color: #226fb7;
    }

    p {
      padding: 0;
      line-height: 17px;
    }
  }
}
