@import '~stylesheets/utils/index';

.base {
  @include content-section;
  padding: 75px 0;
  flex-grow: 1;

  @include media($min-sm) {
    padding: 134px 32px;
  }
}

.container {
  @include content-container;

  code {
    @include dont-break-out;
    display: block;
    @include font-size(12px);
    padding: 4px 8px;
    background-color: #eee;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    margin-top: 4px;
    overflow: hidden;
  }
}

.pageHeader {
  @include section-intro;
}

.mapButton {
  display: inline-block;
  border: none;
  background: $color-blue;
  padding: 8px 16px;
  border-radius: 6px;
  color: white;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 12px;
    vertical-align: middle;

    &:last-child {
      margin-right: 0;
      margin-left: 12px;
    }
  }

  span {
    vertical-align: middle;
  }
}
