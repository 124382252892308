@import "~stylesheets/utils/index";

.base {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 696px;
  margin-bottom: 20px;

  .joinLegend {
    display: flex;
  }

  > * {
    margin-bottom: 16px;
  }
  > *:not(:last-child) {
    margin-right: 20px;
  }
}
