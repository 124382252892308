@import '~stylesheets/utils/index';

.authorsTabContainer {
  @include content-container;
  display: flex;
  margin-bottom: 32px;
  flex-direction: row;
  align-items: flex-start;

  @media screen and (max-width: 678px) {
    flex-direction: column;
  }

  .authorsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .title {
    display: flex;
    padding: 12px 0;

    span {
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      color: $color-blue;
      align-self: center;
      margin-right: 24px !important;
    }
  }
}

.authorTab {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  border-radius: 6px;
  background-color: #e8f0f8;
  min-width: 250px;
  margin: 0 16px 8px 0;

  @include media($max-sm) {
    width: 100%;
    margin-right: 0;
  }

  .portrait {
    width: 56px;
    margin-right: 16px;

    div {
      height: 56px;
      width: inherit;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
    }
    > div,
    img {
      border-radius: 100%;
    }
    img {
      max-height: 150px;
    }
  }

  span {
    color: $color-dark-blue;
    @include font-size(16px);
    font-weight: 500;
  }
}

.authorBlocks {
  > *:not(:last-child) {
    margin-bottom: 8px;
  }
}

.authorBlockBase {
  background-color: #e8f0f8;
  padding: 32px 0;

  @include media( $max-sm ) {
    padding: 16px 0;
  }

  .authorBlockContainer {
    @include content-container;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 678px) {
      flex-direction: column;
      .portrait {
        align-self: center;
      }
    }

    .portrait {
      width: 216px;
      margin-right: 32px;
      display: flex;
      align-items: center;

      div {
        height: 216px;
        width: inherit;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
      }
      > div,
      img {
        border-radius: 100%;
      }
      img {
        max-height: 150px;
      }
    }

    .authorDetails {
      h4 {
        color: $color-blue;
        text-transform: uppercase;
        @include type-scale( 30px, 24px );
        font-weight: 500;
        margin-bottom: 16px;
        margin-top: 0;
      }

      p {
        color: #535353;
        @include type-scale( 18px, 16px );
        font-weight: 300;
      }
    }
  }
}
