@import '~stylesheets/utils/index';

.base {
  position: relative;
  flex-grow: 1;
  padding: 8px 0;
  display: flex;
  align-items: stretch;

  @include media( $max-sm ) {
    flex-shrink: 0;
  }
}

.timeSpan {
  margin-right: 16px;
  flex-shrink: 0;

  @include media( $max-md ) {
    display: none;
  }
}

.timeline {
  flex-grow: 1;
  background-image: repeating-linear-gradient(
    #3f5f8f,
    transparent 1px,
    transparent 72px
  );

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;

  @include media( $max-sm ) {
    padding-bottom: 120px;
  }
}

.phase {
  margin-bottom: 8px;
  min-height: 100px;
}

.phase-preclinical-trials {
  order: 1;
  height: calc(20% - 8px);
  width: 80%;
  margin: 0 auto 8px;
}

.phase-trials-group {
  position: relative;
  order: 1;
  width: 75%;
  height: calc(40% - 8px);
  margin-right: 8px;
  border: 2px dashed transparentize(white, 0.8);
  border-radius: 6px;
  display: flex;
  margin-bottom: 8px;

  @include media( $max-sm ) {
    width: 64%;
  }
}

.phase-phase-1-clinical-trials {
  order: 2;
  height: 50%;
  width: calc(33.333333% - 8px);
  margin-right: 8px;
  margin-bottom: 0;
  flex-grow: 1;
}

.phase-phase-2-clinical-trials {
  order: 2;
  height: 50%;
  width: calc(33.333333% - 8px);
  margin-right: 8px;
  margin-bottom: 0;
  align-self: center;
  flex-grow: 1;
}

.phase-phase-3-clinical-trials {
  order: 2;
  height: 50%;
  width: calc(33.333333% - 8px);
  margin-bottom: 0;
  align-self: flex-end;
  flex-grow: 1;
}

.phase-approval {
  order: 3;
  height: calc(20% - 8px);
  width: 80%;
  margin: 0 auto 8px;
}

.phase-manufacturing {
  order: 2;
  width: calc(25% - 8px);
  height: calc(40% - 8px);

  @include media( $max-sm ) {
    width: calc(36% - 8px);
  }
}

.phase-safety-monitoring {
  order: 4;
  height: calc(20% - 8px);
  width: 80%;
  margin: 0 auto;
}

.reactourHelper {
  @include media( $max-xs ) {
    margin-left: 10px;
    padding: 14px 20px !important;
    max-width: 280px !important;
    transform: translate(5px, 40px) !important;
  }
}