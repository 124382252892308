@import "~stylesheets/utils/index";

.base {
  padding: 16px;

  @media only screen and (min-width: 80em) {
    padding: 0;
    margin-left: 32px;
  }
}
.graphAndChart {
  width: 100%;
  overflow: hidden;
}

.button {
  @include generic-button();
  background-color: $color-light-gray;
  color: $base-font-color;
  margin-right: 8px;
  cursor: pointer;
  margin: 16px 16px 32px 0;

  @include media($max-sm) {
    margin: 0 0 8px 0;
    width: 100%;
  }

  &:hover,
  &.active {
    color: white;
    background: $accent-secondary;
  }
}

@include media($max-sm) {
  .graph {
    width: 120vw;
    @include centerX();
  }
}
@media screen and (max-width: 769px) {
  .buffer {
    top: 178px !important;
  }
}

@media only screen and (min-width: 1025px) {
  .graphAndChart {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
  }

  // .graph {
  //   width: 80%;
  //   margin-left: -32px;
  // }

  .table {
    width: 80%;
    margin-left: 16px;

    table {
      margin-top: 0;
    }
  }
}
