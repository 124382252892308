@import "src/stylesheets/utils/index";

.base {
	position: relative;
}

.alert {
	cursor: pointer;
	position: absolute;
	top: 150px;
	right: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	@include media($max-md){
		right: 10%;
	}

	&:hover {
		.popover {
			visibility: visible;
			opacity: 1;
			transition: all 0.3s;
		}
	}
}

.stoppedDate {
	display: flex;
	align-items: flex-start;
	padding: 12px;
	text-align: right;

	h4 {
		font-family: Gentona;
		font-size: 14px;
		font-weight: 400;
		line-height: 17px;
		color: #e87563;
		margin: 0;
		padding-left: 5px;
	}

	span {
		color: #8e8e8e;
	}

	svg {
		path {
			fill: #e87563 !important;
			stroke-width: 0 !important;
		}
	}
}

.line {
	width: 100%;
	height: 8px;
	background-color: rgba($color: #e87563, $alpha: 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	span {
		height: 1px;
		width: 100%;
		background-color: #e87563;
	}
}

.popover {
	left: -290px;
	visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}