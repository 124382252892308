@import "~stylesheets/utils/index";

.container {
  @include content-container;

  & > p,
  & > ul {
    max-width: 640px;
  }

  h2 {
    color: $accent-secondary;
    margin-bottom: 8px;
    @include type-scale(32px, 24px);
    line-height: 1.2;
  }

  h3 {
    margin: 0 0 16px;
    color: darkgrey;
    @include type-scale(24px, 18px);
    line-height: 1.2;
  }
}

.tables-wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  & > div {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 300px;
    margin: 0 2rem 3rem;

    @media (max-width: 480px) {
      margin: 0 0.5rem 3rem;
    }
  }

  .table-header {
    font-family: $base-font-family;
    text-transform: none;
    color: $color-dark-blue;
    @include font-size(28px);
    margin: 32px 0 0;
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 1.25;

    // color values copied from chart source
    &.negative span {
      color: rgb(113, 153, 73);
    }
    &.positive span {
      color: rgb(255, 158, 27);
    }
    &.undetermined span {
      color: $color-medium-gray;
    }
  }
}

@media screen and (max-width: 769px) {
  .buffer {
    top: 178px !important;
  }
}
