@import '~stylesheets/utils/index';

.container {
  @include content-container;

  p {
    max-width: 92ch;
  }
}

.wideHeadingCard h3 {
  max-width: none !important;
}

.faq-button {
  line-height: 1.25;
  font-size: 1.25rem;
}

.noBgColor {
  >div {
    background: none !important;
  }
}
.noBgColorNested {
  >div{
    a>div:first-child {
      background: none !important;
    } 
  }
}

.bladeAlignEnd {
  >div{
    align-items: flex-end;
  }
}