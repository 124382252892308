@import "~stylesheets/utils/index";

.base {
  display: flex;
  flex-direction: column;
  border-left: 2px solid $color-blue;
  background-color: $color-light-gray;
  font-weight: 400;
  padding: 16px 12px;
  pointer-events: none;

  @include media($min-sm) {
    font-size: 14px;
  }

  @include media($max-md) {
    font-size: 16px;
  }

  .title {
    text-transform: uppercase;
    font-weight: 400;
    color: $color-blue;
    font-size: 18px;
  }

  .listContainer {
    margin: 0;
    padding: 0 0 0 18px;
    color: $color-medium-gray;
    width: 100%;

    li {
      width: 100%;
    }
  }

  &.modal {
    padding: 24px 44px 24px 12px;
  }

  &.onHover {
    flex: 1;
    min-width: 166px;
    position: absolute;
    z-index: 100;
    left: 22%;
    top: -8px;

    @include media($min-sm) {
      font-size: 14px;
      .title {
        font-size: 14px;
      }
    }
  }
}
