@import '~stylesheets/utils/index';

.base {
  position: fixed;
  @include trbl;
  background: rgba(0, 0, 0, 0.75);
  z-index: $foreground;
}

.modalContent {
  background: white;
  padding: 32px;
  border-radius: 9px;

  @include media($max-sm) {
    @include centerXY;
    width: calc(100% - 32px);
  }

  @include media($min-sm) {
    @include centerXY;
    max-width: 560px;
    top: calc(50% + 77px);
  }
}

.closeModalContent {
  position: absolute;
  @include trbl(16px, 16px, auto, auto);
  width: 32px;
  height: auto;
  cursor: pointer;
  @include svg-color($accent-primary);

  &:hover,
  &:active,
  &:focus {
    @include svg-color($accent-secondary);
  }

  @include media($min-sm) {
    @include trbl(16px, 16px, auto, auto);
  }
}
