@import '~stylesheets/utils/index';

.base {
  background: rgb(235, 229, 229);
  margin-bottom: 32px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  h4 {
    margin-top: 0;
    font-family: $base-font-family;
    text-transform: uppercase;
    margin-bottom: 4px;
    @include font-size(20px);
    color: #797979;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 16px;
    @include font-size(31px);
    color: #b9b9b9;
    line-height: 1.2;
  }

  &.active {
    display: flex;
    cursor: pointer;
    transition: all $interaction-shift;

    h3 {
      color: $accent-secondary;
    }

    &:hover {
      box-shadow: 0 9px 24px #7d7d7d;
      transform: scale(1.05);
    }
  }
}

.button {
  @include generic-button;
  margin: 0 0 16px;

  &.disabled {
    background: #b9b9b9;
  }
}

.moduleHeader {
  padding-bottom: 24px;
  background: white;
  @include media($min-sm) {
    padding: 24px 24px 8px;
    display: flex;
  }
}

.moduleHeaderLeft {
  @include media($min-sm) {
    flex-basis: 65%;
  }
  padding-right: 15px;
  h4 {
    font-size: 12px;
    color: $color-blue;
  }
}

.moduleHeaderRight {
  @include media($min-sm) {
    flex: 1;
    text-align: end;
  }
  >a {
    white-space: nowrap;
  }
}

.moduleBio {
  padding-top: 24px;
  background: white;
  flex-grow: 1;
  border-top: 1px solid #d8e5ef;
  
  @include media($min-sm) {
    padding: 24px;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
  }
}

.modulePortrait {
  flex-shrink: 0;
  margin-bottom: 16px;
  width: 120px;

  @include media($max-sm) {
    float: left;
    margin: 0 16px 8px 0;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    border: 1px solid #ccc;
  }
}

.moduleBio {
  color: $base-font-color;
  flex-grow: 1;

  p {
    @include font-size(14px);

    a {
      font-weight: bold;
    }
  }

  h4 {
    margin: 0 0 8px;
    @include font-size(12px);
    color: $color-blue;
  }
}
