@import '~stylesheets/utils/index';

.base {
  position: relative;
}

.searchInput {
  max-width: 274px;
  position: relative;
  z-index: 30;
  form {
    display: flex;

  }

  .input {
    display: block;
    width: 100%;

    width: 150px;
    @include media($min-lg) {
     width: 234px;
    }

    padding: 8px 12px;
    border: 1px solid #8B98A6;
    border-radius: 0;
    transition: border-color .3s;
    font-size: 14px;
    &::placeholder {
      font-style: italic;
      color: #535353;
      opacity: .4;
    }
    &:focus {
      outline: 0;
      border-color: #226FB7;
    }
  }

  .button {
    background: #07225A;
    border: 0;
    @include trbl(0, 0, auto, auto);
    height: 72px !important; // its at 1/2 scale...
  }
}
