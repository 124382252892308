@import '~stylesheets/utils/index';

.base {
  display: flex;
  flex-direction: column;
  max-width: 500px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $accent-primary;
    padding-right: 0;
    padding-left: 16px;
    color: white;
    font-size: 14px;

    > div {
      display: flex;
      flex-direction: row;

      > button {
        font-size: 14px;
        color: white;
        text-transform: uppercase;
        padding: 8px 16px;

        &.disabled {
          opacity: 0.5;
        }
      }
      button:first-child {
        padding: 10px;
      }
    }
  }

  .contentContainer {
    padding: 16px;
    background-color: white;
  }

  .arrowContainer {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding-right: 20px;
    padding-left: 20px;

    .arrow {
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 32px 32px 0 32px;
      border-color: white transparent transparent transparent;

      &.position-center {
        align-self: center;
      }

      &.position-left {
        align-self: left;
        align-self: flex-start;
      }

      &.position-right {
        align-self: right;
        align-self: flex-end;
      }
    }
  }
}
