@import "~stylesheets/utils/index";

.tooltip {
  background-color: #fff;
  opacity: 0;
  padding: 10px;
  border-radius: 20px;
  text-align: center;
  color: black;
  pointer-events: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.tooltipH5 {
  margin: 0;
}

.tooltipText {
  font-size: 75%;
  margin: 0;
  padding: 0;
}

.resetButton {
  width: 100%;
}

.howto {
  font-size: 75%;
  text-align: right;
  margin-right: 70px;
}
