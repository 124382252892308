@import "~stylesheets/utils/index";

.base {
  padding-bottom: 32px;
}

.title {
  display: flex;
  align-items: center;
}
.titleIcon {
  width: 40px;
  margin-right: 16px;
  svg {
    width: 100%;
    height: auto;
  }
}
.graph {
  min-height: 300px;
}

.facetGraph {
  min-height: 500px;
}

.facetContainer {
  display: flex;
  justify-content: space-between;
  @include media($max-md) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

.facetControls {
  display: flex;
  flex-direction: column;
  @include media($max-md) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  @include media($max-sm) {
    flex-direction: column;
  }
}

.facetSelector {
  max-width: 240px;
  padding: 30px;
  @include media($max-md) {
    max-width: 500px;
    padding: 15px;
  }
}

.facetCheckboxes {
  @include media($max-md) {
    flex-direction: row !important;
  }
  @include media($max-sm) {
    flex-direction: row !important;
    flex-wrap: wrap !important;
  }
}

.subHeading {
  color: #226fb7;
}

.headerRow {
  display: flex;
  @include media($max-sm) {
    flex-direction: column-reverse;
    margin-bottom: -54px;
  }
  @include media($min-sm) {
    justify-content: space-between;
    align-items: center;
    margin-bottom: -28px;
  }
  position: relative;
  z-index: 1;
}

.legend {
  display: flex;
  align-items: center;
  .line,
  .bar {
    display: flex;
    align-items: center;
    padding-right: 38px;
    .sample {
      margin-right: 8px;
    }
  }
  .bar .sample {
    width: 8px;
    height: 16px;
  }
  .line .sample {
    width: 12px;
    height: 3px;
    border-radius: 900px;
  }
}

.zoomControls,
.facetZoomControls {
  display: flex;
  @include media($max-sm) {
    margin-bottom: 20px;
  }
  > *:not(:first-child) {
    margin-left: 10px;
  }
  button {
    white-space: nowrap;
    font-size: 16px;
    @include media($max-lg) {
      font-size: 14px;
    }
  }
}

.customSelect {
  z-index: 10;
}

.popover {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;

  &.show {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s;
  }
}
