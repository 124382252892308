@import "~stylesheets/utils/index";

.base,
.base > button {
  cursor: pointer;
}

.base {
  margin-bottom: -1px;

  > button {
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: 500;
  }
  > button:focus {
    outline-color: none;
    outline: none;
  }
}

.base {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  // min-width: 128px;
  max-width: 256px;
  padding: 8px 16px 4px 16px;

  @include media($max-xs) {
    display: flex;
    justify-content: center;
    padding: 8px 12px 4px;
  }

  .newTag {
    margin-right: 8px;
    @include new-badge-styles;
  }
}

.inactive,
.active {
  @include media($min-xs) {
    border-bottom: 3.3px solid $color-blue;
  }
}
.inactive {
  background: #f0f0f0;
  > button {
    color: $color-blue;
  }
}
.inactive:hover {
  background: #e5e7e9;
}
.active {
  background: $color-blue;
  > button {
    color: white;
  }
}
