@import '~stylesheets/utils/index';

.base {
  @include content-container;
  min-height: 300px;
}

.button {
  @extend .mapButton;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  transition: all $interaction-shift;

  @include media($max-sm) {
    width: 100%;
  }
}
