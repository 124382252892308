// .base {}

.noMargins {
    margin: 0;
}
.noMarginBtm {
    margin-bottom: 0;
}
.noMarginTop {
    margin-top: 0;
}
.noWrap {
    white-space: nowrap;
}
.allCaps {
    text-transform: uppercase;
}