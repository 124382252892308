@import '~stylesheets/utils/index';

.base {
  overflow: hidden;
  color: $color-white;
  pointer-events: none;
  display: flex;
  flex-direction: column;

  .links-container {
    background: $color-blue;
    box-shadow: -50vw 0 0 0 $color-blue, 50vw 0 0 0 $color-blue;
    pointer-events: all;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: stretch;
    list-style-type: none;
    font-size: 14px;
    height: 50px;
    padding: 0;
    margin: 0;
    @include vw-container($lg);

    @include media($min-md) {
      font-size: 1rem;
    }

    li {
      display: block;
      position: relative;
      font-size: 1rem;
      margin: 0 1.5rem 0 0;       

      &:last-child {
        margin-right: 0;
      }
      
      @include media( $min-md ) {
        margin: 0 2.5rem 0 0;        

        &:last-child {
          margin-right: 0;
        }
      }

      & > * {
        display: inline-flex;
        height: 100%;
        align-items: center;

        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: 1;

        padding: 0 0.1rem;
        margin: 0;
        box-shadow: inset 0 0 0 0 $color-light-blue;

        cursor: pointer;
        transition: background-color $interaction-shift,
          box-shadow $interaction-shift;

        &.active {
          font-weight: 700;
          box-shadow: inset 0 -4px 0 0 $color-light-blue;
        }
      }

      &.has-button {
        $left-overreach: 1.2rem;
        $right-overreach: 0.9rem;

        & > button {
          color: inherit;

          img {
            transition: transform $interaction-shift;
          }

          box-shadow: -$left-overreach 0 0 0 transparent,
            $right-overreach 0 0 0 transparent,
            inset 0 0 0 0 $color-light-blue;

          &.active {
            box-shadow: -$left-overreach 0 0 0 transparent,
              $right-overreach 0 0 0 transparent,
              inset 0 -4px 0 0 $color-light-blue;
          }
        }
        &.open > button {
          background-color: $color-dark-blue;
          // Add additional shadows on the left/right to make the background wider
          box-shadow: -$left-overreach 0 0 0 $color-dark-blue,
            $right-overreach 0 0 0 $color-dark-blue,
            // And hide the bottom border shadow
              inset 0 0 0 0 $color-light-blue;

          img {
            transform: rotate(-180deg);
          }
        }
      }
    }

    .divider {
      padding: 0.85rem 0;
      margin-left: -0.5rem;
      margin-right: 2rem;

      &::after {
        content: '';
        display: block;
        height: 100%;
        width: 3px;
        background: transparentize($color-white, 0.2);
        transform: scaleX(0.5);
      }
    }
  }
}

// Mobile layout

@include media($max-sm) {
  .base.mobile-hide {
    display: none;
  }

  .base {
    .links-container {
      padding: 0;
      max-width: 100%;
      display: block;

      // Disappear the items that don't have the active element
      li > :not(.active),
      .divider {
        display: none;
      }
      // Let the li that does have the active element take on new full-width styles
      li {
        margin: 0;
        position: static;
      }
      li.has-button > button.active {
        height: 50px;
        display: flex;
        box-shadow: none;
        margin: 0;
        width: 100%;
        @include vw-container($lg);
        justify-content: flex-start;
      }
      // Tweak “opened” styles
      li.has-button.open > button {
        box-shadow: inset 0 -1px 0 0 transparentize($color-light-gray, 0.9);
      }
    }
  }
}

// Content area pops out below the links-container “nav bar”

// The “content size” box fills the maximum height under the header that the
// content area could possibly occupy
.content-size {
  flex: 1 1 0;
  margin: 0;
  position: relative;
}

.content-area {
  position: absolute;
  width: 100%;
  background-color: $color-dark-blue;
  padding: 2rem 0 1rem;
  display: flex;
  flex-direction: column;
  // Styles when closed
  pointer-events: none;
  opacity: 0;
  max-height: 0;
  overflow: hidden;

  // IE
  @media all and (-ms-high-contrast:none) {
    top: 0;
    bottom: 0;
  }

  // Override those styles when opened
  &.open {
    max-height: 100%;
    opacity: 1;
    pointer-events: all;
    transition: max-height $layout-shift, opacity $interaction-shift;
  }

  & > * {
    width: 100%;
  }

  .top-bar {
    flex: 0 0 auto;
    @include vw-container($lg);

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;

    &::after {
      display: none;
    }

    h2 {
      color: white;
      text-transform: none;
      font-size: 1.1rem;
      margin: 0;
      font-weight: 500;
    }

    .button-container {
      display: flex;
      transform-origin: right center;
      transform: scale(0.9);
    }

    .close-button {
      box-shadow: inset 0 0 0 6px $color-white;
    }
  }

  .hr-container {
    flex: 0 0 auto;
    @include vw-container($lg);

    hr {
      margin: 1rem -0.25rem 0;
      height: 1px;
      background-color: transparentize($color-light-gray, 0.75);
    }
  }

  .main {
    flex: 1 1 0;
    padding: 1rem 0 0.5rem;
    overflow-y: scroll;
    @include vw-container($lg);
  }
}

@include media($max-sm) {
  .content-area {
    padding: 0;

    .top-bar {
      padding-top: 0.75rem;
      padding-bottom: 0;

      .button-container {
        transform: scale(0.75);
      }
    }

    hr {
      margin: 0.75rem -16px 0;
      background: transparentize($color-light-gray, 0.9);
    }

    .main {
      padding-bottom: 1rem;
    }
  }
}

.content-nav {
  flex: 1 1 0;
  margin-top: 0;
  margin-bottom: 0;
}
