@import '~stylesheets/utils/index';

.base {
  position: relative;
}

.input {
  display: block;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid $color-blue;
  border-radius: 0;
  box-shadow: inset 2px 2px 0 #cacaca;
}

.button {
  position: absolute;
  @include trbl(0, 0, auto, auto);
  height: 72px !important; // its at 1/2 scale...
}
