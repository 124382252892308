.axis {
  text {
    text-rendering: optimizeLegibility;
    font-size: 12px;
    stroke: none;
    fill: gray;
  }

  .x--axis {
    text {
      text-anchor: start;
    }
  }

  path,
  line {
    fill: none;
    stroke: gray;
    stroke-width: 2px;
    shape-rendering: crispEdges;
  }
}
