@import "~stylesheets/utils/index";

.container {
  @include content-container;
}

.mapGrid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  > * {
    @include media($max-sm) {
      margin-top: 24px;
      width: 100%;
      > svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  @include media($btn-md-lg) {
    margin-left: -32px;
    margin-right: -32px;
    justify-content: center;
  }
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  .demographicSelector {
    margin-bottom: 24px;
  }
  .categorySelector {
    @include media($min-sm) {
      max-width: 240px;
      margin-bottom: 64px;
    }
  }
}
