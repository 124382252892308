@import '~stylesheets/utils/index';

.container {
  @include content-container;
  margin-bottom: 32px;

  ul {
    margin-top: 0;
  }
}

.intro {
  @include media($min-md) {
    display: flex;
    flex-direction: row;

    > * {
      margin-bottom: 0;
    }
    >div:first-child {
      margin-right: 32px;
    }
  }
}

.introText {
  position: relative;
}

.introVideo {
  margin-bottom: 0;

  @include media($max-md) {
    margin-top: 32px;
  }

  img {
    height: unset;
  }
}

.videoContainer {
  @include video-container;
  border: 1px solid #e8f5ff;
  box-shadow: 0 0 0 8px #d7e9f7;
}

.modules {
  @include content-section;
  border-top: 1px solid #d9d9d9;
  background-color: #f6f6f6;
  padding: 32px 16px;

  @include media($min-md) {
    padding: 48px 0;
  }

  h2 {
    margin-top: 0;
  }
}

.moduleCards {
  @include media($min-sm) {
    @include grid(2);
  }
}

.button {
  @include generic-button(  );
}

.airTable {
  @include vw-container($xlg);
}

.notes {
  display: flex;

  @media screen and (min-width: 970px){
    p {
      display: flex;
      flex-basis: 63%;
    }    
  } 
}