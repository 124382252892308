@import "src/stylesheets/utils/index";

.container {
  @include content-container;
}

.ethics-section {
  h2 {
    text-transform: none;
  }

  .ethics-principles-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: $color-dark-blue;
    line-height: 1.2;

    img {
      width: 8rem;
      height: 8rem;
      // IE
      display: block;
      margin: auto;
    }

    // IE
    .ethics-principles-item-content {
      width: 100%;
    }
  }

  .ethics-actions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;

    a {
      margin: 0 0.5rem 0.5rem 0;
      white-space: nowrap;
    }

    @include media($max-xs) {
      flex-direction: column;
      align-items: stretch;

      a {
        margin: 0 0 0.5rem;
      }
    }
  }
}

.faq-button {
  line-height: 1.25;
  font-size: 1.25rem;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  img {
    align-self: center;
    margin-left: auto !important;
  }
}

.largeCards {
  margin-bottom: 64px;
}

.dualCardBlade {
  position: relative;
  margin-bottom: 64px;
}

.topBlade {
  @include media($min-sm) {
    margin-bottom: 64px;
  }
  @include media($max-sm) {
    border-bottom: 1px solid rgba(148, 201, 249, 0.5);
    margin-bottom: 16px;
  }

  .topCard {
    @include media($max-sm) {
      margin-bottom: 16px;
    }
    > * p:last-child {
      padding-bottom: 14px;
    }
  }
}

.articleReverse {
  @include media($min-sm) {
    display: flex;
    flex-direction: row !important;
  }
}
