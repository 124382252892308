.base {
  enable-background: new 0 0 900 650;
  .shape {
    fill: #F2F2F2;
    transition: fill-opacity .3s;
    &.hasLink:hover {
      fill-opacity: .6;
    }
  }
  .label {
    fill:#1A1A1A;
    font-family:'OpenSans-Bold';
    font-size:11.69px;
  }
}
