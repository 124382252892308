@import '~stylesheets/utils/index';

.container {
  @include content-container;
}

.sources {
  h6 {
    color: $color-medium-gray;
    @include font-size(14px);
    margin: 32px 0 8px;
  }

  p {
    max-width: 640px;
    @include font-size(14px);
  }
}

.table {
  @include media($min-sm) {
    column-count: 2;
    column-gap: 32px;
  }
}

.row {
  display: flex;
  border-bottom: 1px solid #ebebeb;
  padding: 6px 0;

  &:last-child {
    border-bottom: none;
  }

  @include media($min-sm) {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

.rowImportant,
.rowUnImportant {
  color: $accent-secondary;
  font-weight: bold;
  @include font-size(28px);
  line-height: 24px;
  margin-right: 8px;

  &::before {
    content: '•';
  }
}

.rowUnImportant {
  color: $color-yellow;
}

.rowDate {
  margin-right: 8px;
  color: #727272;
  flex-shrink: 0;
  width: 50px;
}

.rowEvent {
  color: $accent-secondary;
}
