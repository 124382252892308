@import './var';

#positivity__comparison-table {
  .MuiTableCell-root {
   width: fit-content;
  }
  .approach__number {
    text-transform: uppercase;
    color: $c--blue-dark;
    font-size: 16px;
    line-height: 17px;
  }

  .approach__name {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .state__count--container{
    display: flex;
    flex-direction: row;
  }

  .state__count {
    font-size: 14px;
    line-height: 17px;
    background-color: white;
    height: 24px;
    border-radius: 12px;
    padding: 4px 10px 4px;
    width: fit-content;
    display: flex;
    color: $c--blue-dark;
    margin-top: 5px;
  }

  .table__chart {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    @media only screen and (max-width: $s--phone) {
      margin-right: 15px;
    }
    max-height: 90px;
  }
}
