@import '../StyleGuide.module.scss';

.container {
  @include vw-container($max-width: $xlg);
}

.column-placeholder {
  background: $color-light-gray;
  height: 20rem;
}
