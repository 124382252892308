@import "~stylesheets/utils/index";

.base {
  padding: 14px;
  background: #eee;
  border-left: 7px solid #226fb7;

  @include media($max-sm) {
    margin-top: 30px;
    @include clearfix;
  }
  @include media($min-sm) {
    display: flex;
    padding: 14px 28px;

    &.default {
      justify-content: space-between;
      align-items: center;
    }
    &.homepage {
      flex-direction: column;
      border-left: 8px solid #226fb7;
      padding: 20px 28px;
    }
  }

  &.default {
    .text {
      max-width: 624px;
      @include media($min-sm) {
        padding-right: 50px;
      }
      :last-child {
        padding-bottom: 5px;
      }
    }
  }

  .text {
    font-size: 14px;
  }
  .title {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #226fb7;
  }

  &.alert {
    border-left: 7px solid #e67668;
    .title {
      color: #e67668;
    }
  }
  &.homepage {
    .title {
      text-transform: uppercase;
    }
    .button {
      margin-top: 32px;
    }

    // Hover transition for homepage
    transition: transform $interaction-shift;

    &:hover {
      transform: translateY(-4px);

      h3 {
        color: $color-blue;
      }
    }
  }

  @include media($max-sm) {
    .button {
      float: right;
    }
  }
}
