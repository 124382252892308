@import "~stylesheets/utils/index";
@import "../TestingPositivity/TestingPositivity.module.scss";
@import "../TestingViz/TestingGraphAndTable/TestingGraphAndTable.module.scss";

.container {
  @include outer-container;
  max-width: rem($lg);

  .chooser {
    @include select-wrapper($accent-secondary, transparent, white);
    border-radius: 4px;
  }
  .controlsContainer {
    display: flex;

    @include media($min-sm) {
      align-items: center;
      > :not(:last-child) {
        margin-right: 24px;
      }
    }
    @include media($max-sm) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  ul[class*="DataSources"] {
    max-width: 768px;
    margin-bottom: 15px;

    p {
      padding-bottom: 2px;
    }
  }

  .graph {
    max-width: 100%;
  }

  .plot-container {
    margin: 2em 0 3.5em;
  }

  @media only screen and (min-width: 1025px) {
    .graph {
      margin-right: 20px;
    }
    .table {
      width: 80%;
      margin-left: 16px;
    }
  }
}

@media screen and (max-width: 769px) {
  .buffer {
    top: 178px !important;
  }
}
