@import "~stylesheets/utils/index";

.careResourceHeader {
  display: flex;
  flex-direction: column;
  margin: 32px 0 48px 0;

  h3 {
    margin: 0;
  }
  h3:not(strong) {
    display: inline-block;
    color: $color-dark-blue;
  }
  > *:first-child {
    font-size: 22px;
  }
  > *:not(:first-child) {
    font-weight: 600;
  }
  > *:not(:last-child) {
    margin-bottom: 8px;
  }

  strong {
    color: #3e3e3e;
    font-weight: 400;
  }
}

.mapPie {
  > div {
    width: 100%;
    overflow: hidden !important;

    &:first-child > svg {
      width: 100%;
      height: auto;
    }
  }

  @include media($min-sm) {
    column-count: 2;
    column-gap: 32px;
  }

  &.appPie {
    @include media($min-xs) {
      > div {
        transform: scale(0.9);
        margin-left: -24px;
      }
      > div:last-child {
        transform: scale(0.8);
        margin-left: -78px;
      }
    }

    @include media($min-md) {
      > div {
        transform: scale(0.9);
        margin-left: -24px;
      }
      > div:last-child {
        transform: scale(0.8);
        margin-left: -128px;
      }
    }
  }
}

.sources {
  h6 {
    color: $color-medium-gray;
    @include font-size(14px);
    margin: 32px 0 8px;
  }

  p {
    max-width: 640px;
    @include font-size(14px);
  }
}

.table {
  @include media($min-sm) {
    column-count: 2;
    column-gap: 32px;
  }
}

.row {
  display: flex;
  border-bottom: 1px solid #ebebeb;
  padding: 6px 0;

  &:last-child {
    border-bottom: none;
  }

  @include media($min-sm) {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

.rowImportant,
.rowUnImportant {
  color: $accent-secondary;
  font-weight: bold;
  @include font-size(28px);
  line-height: 24px;
  margin-right: 8px;

  &::before {
    content: "•";
  }
}

.rowUnImportant {
  color: $color-yellow;
}

.rowDate {
  margin-right: 8px;
  color: #727272;
  flex-shrink: 0;
  width: 50px;
}

.rowEvent {
  color: $accent-secondary;
}
