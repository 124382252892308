@import "src/stylesheets/utils/index";

.alert {
	display: flex;
	align-items: flex-start;
}

.stoppedDate {
	display: flex;
	align-items: flex-start;
	background-color: rgba($color: #e87563, $alpha: 0.1);
	padding: 12px;
	text-align: right;

	h4 {
		font-family: Gentona;
		font-size: 14px;
		font-weight: 400;
		line-height: 17px;
		color: #e87563;
		margin: 0;
		width: 140px;
	}

	span {
		color: #8e8e8e;
	}

	svg {
		path {
			fill: #e87563 !important;
			stroke-width: 0 !important;
		}
	}
}

.line {
	width: 8px;
	background-color: rgba($color: #e87563, $alpha: 0.1);
	display: flex;
	justify-content: center;
	span {
		height: 100%;
		width: 1px;
		background-color: #e87563;
	}
}

