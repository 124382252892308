@import '~stylesheets/utils/index';

.base {
  background: black;
  padding: 0;
  flex-grow: 1;
  margin-top: -8px;
}

.container {
  @include media($min-sm) {
    width: 100%;
    max-width: none;
    flex-direction: column;
    justify-content: stretch;
  }
}

.footNote {
  text-align: center;
  color: white;
  @include font-size(14px);
  margin: 0 auto;

  @include media($max-sm) {
    margin-top: 8px;
  }

  .mapButton {
    display: block;
    max-width: 360px;
    margin: 0 auto 16px;
  }
}

.embedContainer {
  position: relative;
  height: 0;
  max-width: 100%;
  width: 100%;
  margin-bottom: 0;
  padding-bottom: calc(100vh - 126px);

  @include media($min-sm) {
    padding-bottom: calc(100vh - 140px);
  }
}

.embedContainer iframe,
.embedContainer object,
.embedContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mapButton {
  display: inline-block;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  color: white;
  background: $color-blue;

  &:hover,
  &:active,
  &:focus {
    background: $accent-secondary;
    color: white;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 12px;
    vertical-align: middle;

    &:last-child {
      margin-right: 0;
      margin-left: 12px;
    }
  }

  span {
    vertical-align: middle;
  }
}

.actions {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    width: 100%;
    max-width: 480px;
    flex-grow: 1;
    margin: 0 8px 8px;
  }
}

.subNav {
  text-align: center;
  color: white;
  @include font-size(14px);
  margin: 0 auto;
  padding: 0;

  @include media($max-sm) {
    margin-top: 8px;
  }

  .mapButton {
    display: block;
    max-width: 360px;
    margin: 0 auto 16px;
  }

  .footNote {
    font-weight: bold;
    font-style: italic;
    @include media($min-sm) {
      br {
        display: none;
      }
    }
  }
}

.mapModal {
  position: fixed;
  @include trbl;
  background: rgba(0, 0, 0, 0.5);

  color: white;
  text-align: center;

  h2 {
    color: white;
    margin-top: 8px;
    margin-bottom: 16px;
    @include font-size(32px);
  }

  p {
    @include font-size(18px);
  }
}

.mapInfoBox {
  background: black;
  padding: 48px 32px 32px;

  @include media($max-sm) {
    margin-top: 66px;
    height: 100%;
  }

  @include media($min-sm) {
    max-width: 560px;
    @include centerXY;
    top: calc(50% + 77px);
  }

  p {
    @include font-size(14px);
  }

  a {
    width: 100%;
    margin-bottom: 8px;
  }
}

.closeInfoBox {
  position: absolute;
  @include trbl(66px + 16px, 16px, auto, auto);
  width: 32px;
  height: auto;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    @include svg-color($accent-primary);
  }

  @include media($min-sm) {
    @include trbl(16px, 16px, auto, auto);
  }
}
