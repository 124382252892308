@import "~stylesheets/utils/index";

.container {
  @include content-container;
}

.snapshotMenu {
  display: none;
}

.dataNotesLinkContainer {
  p,
  .linkButton {
    display: inline;
  }
}

@media screen and (max-width: 769px) {
  .buffer {
    top: 178px !important;
  }
}
