@import '~stylesheets/utils/index';

.base .item {
  margin-bottom: .5em; }

.item {
  .trigger {
    background: $color-blue;
    color: $color-white;
    padding: .5em 1.5em;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media($max-sm) {
      padding: .65em .85em .65em 1em;

      .expand-arrow svg {
        transform: scale(.85); } }

    h3, h4 {
      color: $color-white; }

    .expand-arrow,
    .expand-arrow svg {
      display: block;
      width: 1.4rem;
      height: 1.4rem; }

    .expand-arrow {
      margin-right: 0;
      margin-left: 1.5rem;

      @include media ($max-xs) {
        margin-left: .5rem; } } }

  .body {
    font-weight: normal;
    overflow: hidden;

    .content-wrapper {
      padding: 1em 1.5em; } } }
