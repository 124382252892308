@import '~stylesheets/utils/index';

.base {
  position: relative;
  border-radius: 4px;
  background-color: $accent-secondary;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.28);
  margin-bottom: 16px;
  padding: 16px;
  color: white;
}

.heading {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  @include font-size(18px);
  margin-bottom: 16px;
  line-height: 1;

  span {
    margin-top: 2px;
  }

  > *:first-child {
    width: 30px;
    height: 24px;
    margin-right: 16px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      img {
        width: 30px;
        height: auto;
      }
    }
  }
}

.timeline {
  background-image: repeating-linear-gradient(
    #37598b,
    transparent 1px,
    transparent 42px
  );

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.phase {
  background-color: transparentize(#55709b, 0.22);
  border-radius: 6px;
  padding: 8px;
  flex-grow: 1;
  margin-bottom: 8px;

  transition: background-color $layout-shift;

  &:last-child {
    margin-bottom: 0;
  }

  > * {
    width: 32px;
    height: 32px;
    margin: 0 auto;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      img {
        width: 32px;
        height: auto;
      }
    }
  }

  &.active {
    background-color: #e3edf6;
  }
}

#preclinical-trials {
  order: 1;
  width: 100%;
  margin: 0 42px 8px;
}

#phase-1-clinical-trials {
  order: 2;
  margin-right: 8px;
  height: 62px;
}

#phase-2-clinical-trials {
  order: 2;
  margin-right: 8px;
  height: 62px;
  margin-top: 31px;
}

#phase-3-clinical-trials {
  order: 2;
  margin-right: 8px;
  height: 62px;
  margin-top: 62px;
}

#approval {
  order: 3;
  width: 100%;
  margin: 0 42px 8px;
}

#manufacturing {
  order: 2;
  margin-top: 32px;
  height: 80px;
}

#safety-monitoring {
  order: 4;
  width: 100%;
  margin: 0 42px 8px;
}

.timeSpan {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #869cbb;
  @include font-size( 18px );
  margin-top: 8px;
  text-transform: uppercase;

  img {
    opacity: 0.5;
    margin-right: 12px;
  }
}
