@import "~stylesheets/utils/index";

.selected {
  fill: #0E2C74;
}

.default {
  fill: #ddd;
}

.hovered {
  fill: #aaa;
}
