@import '~stylesheets/utils/index';

.base {
  position: relative;
  @include vw-container($md);
}

.selectorRow {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 50%;
  margin-bottom: 16px;
}

.selector {
  max-width: 375px;
  width: calc(100% + 160px)
}
