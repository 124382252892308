@import "~stylesheets/utils/index";

.base {
  z-index: $foreground;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: none;

  & > * {
    pointer-events: all;
    flex: 0 0 auto;
    width: 100%;
  }
  @media print {
    display: none;
  }
}

.container {
  @include vw-container($lg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  background: $color-dark-blue;
  box-shadow: -50vw 0 0 0 $color-dark-blue, 50vw 0 0 0 $color-dark-blue;

  @include media($min-sm) {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  // IE
  @media all and (min-width: $screen-sm) and (-ms-high-contrast: none) {
    height: 70px;
  }

  @include media($min-md) {
    padding-top: 32px;
    padding-bottom: 32px;

    > .banner {
      margin-right: 32px;
    }
  }
}

.banner {
  flex-grow: 1;
  display: flex;
  align-items: center;
  transition: all $interaction-shift;
}

.brand {
  display: block;
  height: 30px;
  width: auto;

  @include media($min-md) {
    height: 40px;
  }
}

.pageNavContainer {
  background: white;
  box-shadow: 0 4px 12px -4px rgba(128, 128, 128, 0.712);
  > div {
    @include vw-container($lg);
    .pageNav {
      // display: block !important;
    }
  }
}
.regionSearchContainer {
  @media screen and (min-width: 769px) {
    display: none;
  }
}
