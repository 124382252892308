@import '~stylesheets/utils/index';

.container {
  @include content-container;
}

.countryPlots {
  @include media($min-sm) {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    > * {
      width: calc(50% - 24px);
      margin-bottom: 32px;
    }
  }
}

.chartFrame {
  max-width: 720px;
}
