@import "~stylesheets/utils/index";

.base {
  display: flex;
  flex-direction: column;
}

.topContent {
  margin-bottom: 32px;
}
.container {
  @include content-container;
}

.content {
  max-width: 800px;
}

.map {
  @include media($btn-sm-md) {
    @include content-container;
  }

  @include media($btn-md-lg) {
    @include content-container;
  }
}

.lists {
  @include media($btn-sm-md) {
    @include content-container;
  }

  @include media($btn-md-lg) {
    @include content-container;
  }

  @include media($min-lg) {
    padding: 0 32px 0;
    flex-shrink: 100;
    width: calc(50% - 200px);
  }
}

.qanda {
  @include media($min-lg) {
    width: 100%;
  }
}

.checkIcon {
  g {
    stroke: #005200;
  }
}
.crossIcon {
  g {
    stroke: #932b1b;
  }
}
