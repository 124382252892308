@import "~stylesheets/utils/index";

.base {
  position: relative;
  display: flex;
  justify-content: center;

  .shadeBar {
    flex: 1;

    background: rgb(204, 204, 204);
    background: linear-gradient(
      256deg,
      #ececec 0%,
      rgba(255, 255, 255, 1) 100%
    );
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    &.right {
      background: rgb(204, 204, 204);
      background: linear-gradient(
        101deg,
        #ececec 0%,
        rgba(255, 255, 255, 1) 100%
      );

      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  .tooltip {
    display: none;
    z-index: 1999;
    background-color: white;
    position: absolute;
    bottom: calc(100% + 21px);
    right: calc(-102px);

    flex-direction: column;
    flex: 1;
    min-width: 248px;
    max-width: 325px;

    @include media($min-md) {
      right: calc(-128px);
      width: 325px;
    }

    padding: 20px 27px;
    border: 1px solid #f1f1f1;
    border-radius: 12px;
    -webkit-box-shadow: 0px 2px 33px 0px rgba(152, 152, 152, 0.5);
    box-shadow: 0px 2px 33px 0px rgba(152, 152, 152, 0.5);

    &.active {
      display: flex !important;
      position: absolute;
    }

    &::after {
      position: absolute;
      top: 99%;
      right: calc(50% - 25px);
      content: "";
      width: 0;
      height: 0;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-top: 25px solid white;
      border-radius: 23px;
    }

    .category {
      padding-bottom: 8px;
      font-size: 14px;
      text-transform: uppercase;
      color: #2e6fb3;
      letter-spacing: 1px;
    }

    h2 {
      margin: 0;
      line-height: 26px;
      margin-bottom: 11px;
      font-size: 22px;
    }

    .content {
      color: #8a8a8a;
    }

    .linkWrap {
      padding-bottom: 0;
      a {
        color: #2e6fb3;
        text-decoration: underline;
      }
    }
  }

  .tooltipWrap {
    display: flex;
    justify-content: center;
    position: relative;

    .icon {
      cursor: pointer;
      &:hover {
        .tooltip {
          display: flex !important;
        }
      }
    }
  }

  img {
    display: flex;
    height: 72px;
    width: 72px;
  }
}
