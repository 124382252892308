@import "~stylesheets/utils/index";

div.base {
  font-size: 18px;
  font-weight: 400;
  background-color: $tran;
  color: $color-dark-blue;
  
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 20px;

  @include media($max-md) {
    padding: 0px 16px;
  }

  .icon {
    display: flex;
    margin-top: 8px;
    width: 42px;
    height: 42px;

    @include media($max-md) {
      max-height: 28px;
      max-width: 28px;
    }
  }

  .textBlock {
    width: 100%;
    margin-left: 14px;
    font-weight: 400;

    .label {
      text-transform: uppercase;
      font-size: 18px;
    }
    .content,
    .defaultMetric {
      font-size: 14px;
      line-height: 16px;
      color: $color-medium-gray;
      @include media($min-md) {
        max-width: 248px;
      }
    }
    .defaultMetric {
      font-weight: 700;
      text-transform: uppercase;
    }
    .link {
      font-size: 14px;
      color: $color-dark-blue;
      border-bottom: 1px solid $color-dark-blue;
    }
  }

  &:hover {
    color: $color-dark-blue;
    background: $tran;
  }

  &.tabbar {
    font-size: 24px !important;
    font-weight: 300 !important;
    line-height: 63px !important;
  }
  &.active {
    background: $tran;
    .textBlock {
      .label {
        font-size: 24px;
      }
    }
  }
  &.active.tabbar {
    font-weight: 500;
  }
}
