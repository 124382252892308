@import './var';

.export-view {
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: $s--small;

  &.layout-alternate {
    max-width: 330px;
  }
}

.export-view__header {
  display: flex;
  margin-bottom: 2rem;
}

.export-view__table {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .export-view__col {
    display: flex;
    flex-direction: column;
    &.export-view__col--labels {
      margin-right: $s--medium;
      flex: 0 0 100px;
    }
    &.export-view__col--values {
      flex: 0 0 300px;
    }
  }
}


.export-view__cell {
  display: flex;
  // justify-content: space-between;
  padding-bottom: $s--small;
  margin-bottom: $s--small;

  &:not(:first-child) {
    height: 110px;
  }
}

.export-view__col--values .export-view__cell {
  border-bottom: 1px solid $c--gray-light;
}

.export-view__chart {
  flex: 0 0 160px;
  display: flex;
  flex-direction: column;
  margin-right: $s--medium;
}

.export-view__trends > * {
  &:first-child {
    width: 120px;
    padding-left: 8px;
  }
  width: 100px;
}

.export-view__label {
  margin-bottom: 5px;
  font-size: 14px;
}

.export-view__footer {
  text-align: center;

  p {
    margin: 0;
    font-size: 14px;
    padding-bottom: 10px;
  }
}

p.export-view__attribution {
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: .5em;
}
