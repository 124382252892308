@import "~stylesheets/utils/index";

.container {
  @include content-container;

  h3 {
    font-size: 26px;
    margin-bottom: 14px;
  }
  p {
    font-size: 14px;
  }
}

.tabBar {
  margin-top: 32px;
}
