@import "~stylesheets/utils/index";

.container {
  @include content-container;
}

.summaryTable {
  td {
    font-size: 14px;
  }
}
