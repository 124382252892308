@import "~stylesheets/utils/index";


.dropdown {
  @include select-wrapper(white, $accent-secondary, $accent-secondary);
  border-radius: 4px;
  width: fit-content;
}

.outer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
