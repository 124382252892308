@import "~stylesheets/utils/index";

.base {
  display: flex;
  justify-content: center;
  position: relative;

  span.groupName::after {
    content: "\25BC";
    align-self: flex-end;
    font-size: 12px;
    margin-left: 5px;
  }

  .groupHeaderContainer {
    cursor: pointer;
    position: relative;

    > div {
      height: 16px;
      width: 100%;
      position: absolute;
    }
  }

  ul.sectionGroup {
    display: none;
    position: absolute;
    z-index: 2000;
    top: 18px;
    padding-top: 24px;
    background-color: $color-dark-blue;

    &.open {
      display: block !important;
    }

    >* {
      cursor: pointer;
    }

    li {
      margin: 0;
      padding: 16px 32px;
      border-top: 1px solid rgba(211, 211, 211, 0.596);
      font-size: 14px !important;
      
      &:hover,
      &.active {
        color: $color-yellow;
        border-color: $color-yellow;
        > a, div {
          color: $color-yellow !important;
        }
      }
    }
  }

  span.groupName {
    color: #fff;
    display: block;
    @include font-size(10px);
    line-height: 1;
    transition: color $interaction-shift;
    text-align: center;

    &:hover,
    &.active {
      color: $color-yellow;
    }

    &.active {
      font-weight: bold;
    }

    @include media($min-md) {
      @include font-size(14px);
    }

    @include media($min-lg) {
      @include font-size(16px);
    }
  }

  li > a.link {
    font-size: 14px !important;
  }
}
