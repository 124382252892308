@import "~stylesheets/utils/index";

.iconHeader {
  margin: 0 5px 0 0;
  display: flex;
  align-items: center;
  svg {
    margin-right: 16px;
  }
}
