@import '~stylesheets/utils/index';

.place {
  font-weight: 600;
}

.tipBox {
  background-color: #fff;
  opacity: .9;
  padding: 10px;
  border-radius: 20px;
}

.span {
  display: block;
  font-size: 70%;
}
