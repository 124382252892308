@import '~stylesheets/utils/index';

.place {
  font-weight: 600;
}
//
// .tipBox {
//   // background-color: #fff;
//   // opacity: .9;
//   padding: 8px 12px;
//   margin-bottom: 2px;
// }

.tipBox {
	position: relative;
	background: #eee;
	border: 1px solid #999;

  &::after, &::before {
    top: 100%;
  	left: 50%;
  	border: solid transparent;
  	content: "";
  	height: 0;
  	width: 0;
  	position: absolute;
  	pointer-events: none;
  }

  &::after {
    border-color: rgba(238, 238, 238, 0);
  	border-top-color: #eee;
  	border-width: 8px;
  	margin-left: -8px;
  }

  &:before {
    border-color: rgba(153, 153, 153, 0);
  	border-top-color: #999;
  	border-width: 9px;
  	margin-left: -9px;
  }

  p {
    padding: 0;
    text-align: center;
    font-size: 70%;
  }

}

.span {
  display: block;
  font-size: 70%;
}

.pos {
  border: 2px green solid;
}

.neg {
  border: 2px red solid;
}
