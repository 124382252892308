@import '~stylesheets/utils/index';

.base {
  position: absolute;
  top: 0;
  left: 0;

  .pointOuter {
    background-color: $color-yellow;
    @include circle(32px);
    @include centerXY;
    transition: all $interaction-shift;

    animation: sonar 2s infinite;
  }

  .pointInner {
    background-color: $color-yellow;
    @include circle(16px);
    @include centerXY;
    transition: all $interaction-shift;
  }

  &.active {
    .pointOuter {
      background-color: $accent-primary;
    }

    .pointInner {
      background-color: $accent-primary;
    }
  }
}

.clickable {
  cursor: pointer;

  &:hover {
    .pointOuter {
      background-color: $accent-primary;
    }

    .pointInner {
      background-color: $accent-primary;
    }
  }
}

@keyframes sonar {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.5);
  }

  50%,
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.2);
  }
}
