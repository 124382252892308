@import '~stylesheets/utils/index';

.container {
  @include content-container;

  h2 {
    margin-bottom: 8px;
  }

  h3 {
    color: $base-font-color;
    margin-top: 0;
  }
}

.chartContainer {
  position: relative;
}

.mainPlotHeader {
  position: relative;
  z-index: $middleground;
}

.mainPlot {
  position: relative;
  margin: 16px auto 32px;
}

.countryPlots {
  @include media($min-sm) {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    > * {
      width: calc(50% - 24px);
    }
  }
}
