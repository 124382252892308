@import '~stylesheets/utils/index';

.base {
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

ul.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  li {
    @include media($min-md) {
      margin-left: 14;

      &:first-child {
        margin-left: 0;
      }
    }

    @include media($min-md) {
      margin-left: 20px;
    }

    @include media($min-lg) {
      margin-left: 32px;
    }
  }
}

a.link {
  color: #fff;
  display: block;
  @include font-size(10px);
  line-height: 1;
  transition: color $interaction-shift;
  text-align: center;

  &:hover,
  &.active {
    color: $color-yellow;
  }

  &.active {
    font-weight: bold;
  }

  @include media($min-md) {
    @include font-size(14px);
  }

  @include media($min-lg) {
    @include font-size(16px);
  }

  &.grouped {
    font-size: 14px !important;
    white-space: nowrap;
  }
}

@include media($max-md) {
  .base {
    position: fixed;
    @include trbl(70px, 0, 0, 0);
    z-index: $foreground;
    height: auto;
    border-bottom: 0;
    opacity: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    transform: translateY(-100vh);
    transition: opacity $layout-shift;
    height: auto;
    padding-top: 0;
    background: #002d74;
  }

  .open {
    opacity: 1;
    visibility: visible;
    transform: none;
  }

  ul.container {
    display: block;
    padding: 0;

    li {
      border-top: 1px solid #001a44;
    }

    .subNav li {
      border-top: 0;
    }
  }

  a.link {
    font-size: 16px;
    padding: 12px 16px;
    text-align: left;
  }
}

.subNav {
  // No subnav on desktop devices
  @include media($min-md) {
    display: none;
  }

  background-color: #226fb7;

  ul,
  li {
    padding: 0;
    list-style: none;
  }

  a {
    color: $color-white;
    font-size: 14px;
    padding: 8px 24px 4px 32px;
    $border-transparent: transparentize($color-light-blue, 1);
    border-left: 4px solid $border-transparent;
    display: block;

    &.subNavActive {
      font-weight: bold;
      border-color: $color-light-blue;
    }

    &:not(.subNavActive):hover {
      color: $color-yellow;
    }
  }
}
