@import "~stylesheets/utils/index";

.base {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  font-size: 5em;
  z-index: 1000;

  flex-direction: row;
  align-self: baseline;

  .dot {
    cursor: pointer;
    border: 1px solid $color-blue;
    @include circle(13px);

    &.active {
      border: 1px solid $color-blue;
      background: $color-blue;
    }
  }
  > .dot:first-child {
    padding-left: 0;
  }
  > .dot:not(:last-child) {
    margin-right: 14px;
  }
}
