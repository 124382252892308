@import "~stylesheets/utils/index";

.base {
  overflow: hidden;

  g a:hover {
    opacity: 0.5;
  }
}

.baseUSA {
  svg {
    margin-left: -25px;
  }
}

.chooser {
  @include select-wrapper($accent-secondary, transparent, white);
  border-radius: 4px;
  padding: 0;

  @include media($max-sm) {
    flex-grow: 1;
  }
}

.chooser select {
  background-color: transparent;
  color: black;
}

.chooser::before {
  color: black;
}

.chartContainer {
  margin: 32px 0;

  &.usa {
    @media screen and (max-width: 375px) {
      margin: 0;
      svg {
        margin-left: -85px;
        transform: scale(0.88);
      }
    }

    @include media($btn-xs-sm) {
      margin: 0;
      svg {
        @include centerX;
      }
    }
    @media screen and (min-width: 1280px) and (max-height: 920px) {
      transform: scale(0.75);
      margin-top: -32px;
      margin-left: -128px;
    }
  }
}

.snapshotMenu {
  display: none;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  border-top: 1px solid #cde8ff;
  padding-top: 16px;

  @include media($min-sm) {
    padding-top: 32px;
  }
}

.buttonRow button {
  height: 100%;
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
  display: inline-block;
  padding: 0 12px;
  border-radius: 4px;
  margin-right: 20px;
  text-align: center;
  cursor: pointer;
}

.activeState {
  background: #002d72;
  color: white;
  select {
    color: white;
  }
  option {
    background-color: #002d72;
  }
}

.activeState::before {
  color: white;
}

.inactiveState {
  background-color: lightgrey;
  color: black;
}

.statePageLink {
  @include media($max-sm) {
    margin-top: 16px;
  }

  @include media($min-sm) {
    margin-left: 16px;
  }
}
