@import "~stylesheets/utils/index";

.base {
  display: flex;
  flex-direction: column;

  @include media($min-md) {
    margin-top: 64px;
    margin-bottom: 44px;
  }
  @include media($max-md) {
    margin-bottom: 24px;
  }

  .intro {
    @include content-container;
    margin-bottom: 24px;

    .header,
    .content {
      max-width: 832px;
    }

    .header {
      margin: 0;
      margin-bottom: 12px;
    }
    .content p {
      padding: 0;
      
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      color: #535353;
    }
  }

  .cardsBlockContainer {
    @include media($min-md) {
      @include content-container;
    }
  }
  .cardsBlock {
    max-width: 1280px;
    margin: auto;

    @include grid(1, 20px);

    @include media($min-md) {
      @include grid(2, 20px);
    }
    @include media($max-md) {
      margin-right: 0;

      .card {
        margin-right: 0;
        width: 100%;
      }
    }
  }
}
