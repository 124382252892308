@import '~stylesheets/utils/index';

.base {
  position: relative;
  background: black;
  opacity: 0.1;
  border-radius: 9px;
  min-height: 180px;
  animation: wave 2s infinite;
  min-height: 32px;
  margin-bottom: 32px;
}

@keyframes wave {
  0% {
    opacity: 0.05;
  }
  50% {
    opacity: 0.15;
  }
  100% {
    opacity: 0.05;
  }
}
