@import "~stylesheets/utils/index";

.container {
  @include content-container;

  p {
    max-width: 720px;
  }

  h2 {
    font-family: $base-font-family;
    text-transform: none;
    color: $color-dark-blue;
    @include font-size(32px);
    margin: 0 0 8px;
  }

  h3 {
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 16px;

    &:first-child {
      margin-top: 0;
    }
  }

  h4 {
    margin-top: 0;
    text-transform: none;
  }

  h3,
  h4 {
    @include font-size(22px);
    font-family: $base-font-family;
    font-weight: 300;
    color: #092c74;
    letter-spacing: 0.75px;
  }
}

@media screen and (max-width: 769px) {
  .buffer {
    top: 178px !important;
  }
}
