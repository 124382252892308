@import '~stylesheets/utils/index';

.base {
  position: relative;
  margin-top: 16px;
}

.header {
  display: flex;
  align-items: center;
  color: $accent-primary;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 8px;

  img {
    width: 24px;
    height: 19px;
    margin-right: 8px;
  };
}

.content {
  padding-left: 32px;

  p {
    padding: 0 0 8px;
  }

  a {
    color: $accent-primary;
    text-decoration: underline;

    &:hover {
      color: $color-yellow;
    }
  }
}