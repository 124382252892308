@import '~stylesheets/utils/index';

.base {
  position: relative;
}

.container {
  @include content-container;
}

.controls {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid $base-border-color;

  @include media($min-sm) {
    @include grid(2);
    padding-bottom: 0;
  }
}

.grid-footer {
  text-align: center;
}

.paginator {
  margin: 32px auto;
}
