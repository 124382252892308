@import '~stylesheets/utils/index';

.base {
  display: flex;
  align-items: center;
  height: fit-content;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: auto;
  }
  padding: 10px 16px 12px 32px !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.28);

  &.left {
    padding: 10px 32px 12px 16px !important;
  }

  .textContainer {
    order: 1;
    text-align: initial;

    p {
      padding: 0;
    }
    > :first-child {
      font-size: 26px;
      font-weight: 700;
      text-transform: uppercase;
    }
    > :not(first-child) {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .icon {
    order: 2;
    font-size: 70px;
    font-weight: 400;
    margin-left: 20px;
    margin-top: -10px;
    margin-bottom: -10px;

    &.left {
      order: 0;
      margin-right: 20px;
      margin-left: 0;
    }
  }

  &.light {
    background-color: white;
    color: $accent-primary;
  }

  :hover {
    background-color: inherit;
  }

  &.light:hover {
    background-color: $accent-secondary;
    color: white;
  }
}
