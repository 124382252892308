@import "~stylesheets/utils/index";

.base {
  background-color: #fbfbfb;
  border: 1px solid #f0f0f0;
  padding: 15px;

  > div > h4 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    color: $color-dark-blue;
    margin-bottom: 16px;
  }

  > div > div {
    margin-left: 16px;
  }

  ul {
    padding-left: 16px;
  }
}