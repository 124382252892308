@import "~stylesheets/utils/index";

.main {
  display: flex;
  flex-direction: column;
  font-family: "gentona", Tahoma, sans-serif !important;

  h4,
  h3 {
    font-family: "gentona", Tahoma, sans-serif !important;
    text-transform: none !important;
    letter-spacing: none !important;
    margin-bottom: 10px;
  }

  h3 {
    color: #002961;
    font-weight: bold;
    margin: 20px 0 10px 0;
    font-size: 18px;
  }
  h3:first-child {
    margin: 4px 0 10px 0;
  }

  p {
    font-size: 14px;
  }
  p,
  h4 {
    font-weight: 500;
  }
}

.row {
  display: flex;
  flex-direction: column;

  @include media($min-md) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  padding-bottom: 20px;

  > div:not(:first-child) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    align-items: flex-start;
    align-self: flex-start;

    > div:last-child {
      flex: 1.5;
    }
  }
}

.row:last-child {
  > div:not(:first-child) {
    > div:last-child {
      flex: 1;
    }
  }
}

.percPositiveContainer {
  font-size: 14px;
  color: #515c6a;

  .row {
    @include media($min-md) {
      > * {
        margin-right: 24px;
      }
    }

    .columnHead {
      display: flex;
      flex-direction: row;
      align-self: flex-start;
      max-width: 504px;
      flex: 1;

      @include media($max-sm) {
        flex-direction: column;
        img {
          object-fit: contain;
          margin-bottom: 8px;
        }
      }

      @media screen and (min-width: 535px) {
        p {
          flex-basis: 55%;
          padding-bottom: 0;
        }
      }
    }
  }
  border-bottom: 2px solid lightgrey;
}

.columnHead {
  display: flex;

  @include media($max-sm) {
    flex-direction: column;
  }
  img {
    min-width: 150px;
    width: 200px;
    max-height: 84px;

    @include media($min-sm) {
      margin-right: 8px;
    }
  }
  p {
    min-width: 200px;
  }
}

.highlightImageContainer {
  display: flex;
  align-items: center;
  padding: 8px;

  top: 214px;
  left: 560px;
  width: 174px;
  height: 79px;
  border: 3px solid var(--unnamed-color-ddae11);
  border: 3px solid #ddae11;
  opacity: 1;
  > svg {
    width: 100%;
  }
}

.trendDescription {
  display: flex;
  flex-direction: row;
  > * {
    flex: 1;
  }
}

.svgs {
  max-width: 45px;
  align-self: center;
  padding-right: 10px;
}

.modal {
  top: 80px !important;
}

.toolTipIconContainer {
  display: flex;
  margin-right: 48px;
  > .iconContainer:not(:last-child) {
    margin-right: 8px;
  }
}

.fiveApproachesContainer {
  display: flex;
  flex-direction: column;

  @include media($min-md) {
    margin-right: 64px;
  }

  .header {
    margin: 14px 0 28px 0 !important;
  }

  .detailsContainer {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    max-width: 504px;

    @media screen and (min-width: 535px) {
      p {
        flex-basis: 55%;
        padding-bottom: 0;
      }
    }

    @include media($min-sm) {
      flex: 1;
      max-width: 184px;
      margin-right: 56px;
    }

    p {
      font-size: 16px;
      color: #515c6a;
    }
  }

  .toolTipIcons {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
    
    @include media($max-sm) {
      margin-bottom: 16px;
    }

    > * :not(:last-child) {
      margin-bottom: 8px;
    }
    .iconContainer {
      display: flex;

      .icon {
        max-width: 40px;
      }

      .tooltipContent {
        display: flex;
        align-items: center;
        margin-left: 12px;

        color: #0e3071;
        text-transform: uppercase;
        font-size: 16px;
      }
    }
  }
}

.definitionsContainer {
  display: flex;
  flex-direction: column;
  align-self: flex-start !important;
  max-width: 356px;

  p {
    color: #515c6a;
  }
}

.infoSet {
  display: flex;
  @include media($min-sm) {
    flex-wrap: nowrap !important;

    .highlightImageContainer,
    .noReportIcon {
      margin-right: 24px;
    }
    p {
      min-width: 180px;
      max-width: 278px;
    }
  }

  @include media($max-sm) {
    flex-direction: column;

    > *:first-child {
      margin-bottom: 8px;
    }
  }
}
