@import '~stylesheets/utils/index';

.container {
  @include content-container;

  @media screen and (min-width: 970px) and (max-width: 1328px) and (max-height: 900px) {
    @include vw-container($md);
  }

  .videoContainerRef {
    min-height: 280px;

    @include media($max-sm) {
      iframe {
        width: 100%;
        min-height: 280px;
      }
    }

    @include media($min-sm) {
      @include video-container;
    }
  }

  .contentContainer {
    @media screen and (min-width: 1280px) and (max-height: 900px) {
      display: flex;
      flex-direction: column;

      > div:first-child {
        margin-right: 32px;
        padding: 0;
        flex: 1.5;
      }
      > div:nth-child(2) {
        flex: 1;
        > h3 {
          margin-top: 0;
        }
      }
    }
  }
}

.embedDescription {
  h5 {
    margin-bottom: 0;
  }
  ul {
    margin-top: 0;
    padding: 0 0 0 16px;
  }

  a {
    color: #68bcff;
    text-decoration: underline;
  }
}

.feedContainer {
  border-top: 1px solid #979797;
  margin-top: 32px;
  padding-top: 32px;
}

.videoWrapper {
  @include video-container;
  min-height: 400px;

  > div:first-child {
    height: 200px;
  }
}

.imageContainer {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: inherit;

  @include media($min-sm) {
    flex: 1;
    min-height: 280px;
  }

  @include media($max-sm) {
    height: 175px;
  }
}

.eventHeader {
  margin-bottom: 16px;
  
  > h3 {
    margin-bottom: 10px;
  }

  > p {
    color: #226fb7;
    font-weight: bold;
    padding: 0;
  }
}

.eventBranding {
  max-width: 320px;
  height: auto;
  margin: 16px 0;
}

.cCapIframe {
  min-height: 32px;
  width: 100%;
}