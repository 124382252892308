@import "~stylesheets/utils/index";

.container {
  @include content-container;

  display: flex;
  flex-direction: row;

  @include media($max-md) {
    display: flex;
    flex-direction: column;
  }

  .videoContainer,
  .mainContent,
  .bottomContent {
    display: flex;
  }

  .videoContainer {
    flex: 1;
    flex-direction: column;

    .mainContent {
      flex-direction: column;
    }
    .bottomContent {
      flex-direction: column;
    }

    .vidoeFrame {
      flex: 1;
    }
    .textContainer {
      width: 100%;
    }

    @include media($max-md) {
      .mainContent {
        flex-direction: row;
      }
      .textContainer {
        width: 60%;
      }
    }
    @include media($min-sm) {
      .vidoeFrame {
        margin-right: 32px;
      }
    }
    @include media($max-sm) {
      .mainContent {
        flex-direction: column;
      }
      .textContainer {
        width: 100%;
      }
    }
  }

  .graphContainer {
    flex: 1.5;
  }
}

.selectContainer {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;

  > div {
    width: 50%;
    max-width: 264px;
    margin-right: 20px;
  }

}

.scatterPlotActive {
  > svg {
    pointer-events: none;
  }
}