// -----------------------------------------------
//    Typography
// -----------------------------------------------
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-size: 100%;
  line-height: $base-line-height;
  font-weight: 400;
  color: $base-font-color;
  font-family: $base-font-family;
  overscroll-behavior-y: none;
}

a {
  color: $base-link;
  transition: all 0.3s ease-out;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: $base-link-hover;
  }
}

// ------------------------------------------------------------
//   Filling in H1-H6 default styling will prep the CMS editor
// ------------------------------------------------------------
h1 {
  font-family: $heading-font-family;
  @include type-scale(30, 24, 26);
  @include leading-scale(1.12, 1.12);
  color: $accent-primary;
  font-weight: 300;
  text-transform: uppercase;
}

h2 {
  font-family: $heading-font-family;
  @include type-scale(26, 20, 22);
  @include leading-scale(1.3, 1.4, 1.5);
  color: $accent-secondary;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: -0.0025em;
}

h3 {
  font-family: $heading-font-family;
  @include type-scale(24, 18, 20);
  @include leading-scale(1.2, 1.2, 1.5);
  color: $accent-secondary;
  font-weight: 400;
}

h4 {
  font-family: $heading-font-family;
  @include type-scale(20, 18, 20);
  @include leading-scale(1.2, 1.4, 1.5);
  color: $base-font-color;
  font-weight: 400;
}

.small-heading {
  font-family: $heading-font-family;
  @include type-scale(12, 12, 12);
  @include leading-scale(1.3, 1.4, 1.5);
  color: $accent-primary;
  text-transform: uppercase;
  margin: 0.5rem 0;
}

p {
  padding-bottom: 0.85em;
  margin: 0;
}

blockquote {
  border-left: 5px solid $base-border-color;
  margin: 20px 0;
  padding-left: 15px;
}

// Only display content to screen readers
// See: http://a11yproject.com/posts/how-to-hide-content/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
