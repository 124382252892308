@import "~stylesheets/utils/index";

.base {
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    margin-top: 0;
    margin-bottom: 108px;
  }

  .slideContainer {
    flex: 1;
    position: relative;

    .scrollCover {
      min-height: 1px;
      width: 100%;
      position: absolute;
    }
  }

  .scrollContainer {
    padding: 0;
    margin: 0;
    flex: 1;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    li {
      list-style: none;
      align-self: center;
      scroll-snap-align: start;
    }

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
}

.navContainer {
  @include vw-container($lg);
  padding-top: 16px;
  padding-bottom: 32px;
  min-height: 1px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.scrollContainer::-webkit-scrollbar {
  width: 0.1;
  height: 0.1;
}
.scrollContainer::-webkit-scrollbar-button {
  background: $tran;
}
.scrollContainer::-webkit-scrollbar-track-piece {
  background: $tran;
}
