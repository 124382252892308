@import "~stylesheets/utils/index";

.base {
  position: relative;
}

.container {
  @include content-container;
}

.controls {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid $base-border-color;

  @include media($min-sm) {
    @include grid(2);
    padding-bottom: 0;
  }
}

.grid-footer {
  text-align: center;
}

.paginator {
  margin: 32px auto;
}

.featuredContainer {
  @include media($min-sm) {
    margin-bottom: 64px;
  }
  @include media($max-sm) {
    border-bottom: 1px solid rgba(148, 201, 249, 0.5);
    margin-bottom: 16px;
  }
}
