@import "src/stylesheets/utils/index";

.base {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background: rgba(121, 191, 254, 0.2);
	z-index: 5000;
	display: flex;
	justify-content: center;
	align-items: center;
}

.hide {
	display: none;
}

.block {
	background-color: white;
	padding: 38px 18px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.title {
	margin: 0;
	padding-bottom: 8px;
	color: $color-blue;
	width: 100%;
	text-align: left;

	svg {
		margin-right: 8px;
		path {
			fill: $color-blue;
		}
	}
}

.content {
	max-width: 600px;
}
