.popover {
  height: 20px;
}
.popover__icon {
  color: $c--gray-light;
  margin-left: 5px;
}

.popover__root {
  pointer-events: none;
  background-color: rgba(#fff, 0.1);
}

.popover__content {
  padding: 5px 15px;
}
