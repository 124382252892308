@import "~stylesheets/utils/index";

.base {
  display: flex;
  position: relative !important;
  height: 100%;
  .image {
    position: absolute;
    min-height: 1px;
    height: 100%;
    width: 100%;
    // opacity: 0;

    &.loaded {
      opacity: 1;
      transition: opacity 0.8s ease-in-out;
    }
  }
  .under {
    min-height: 1px;
    height: 100%;
    width: 100%;
    visibility: hidden;
  }
}
