@import '~stylesheets/utils/index';

.base {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.pageContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.fullScreen {
  height: 100vh;
  overflow: hidden;
}
