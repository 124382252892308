@import "~stylesheets/utils/index";

.base {
  @include vw-container($md);
  display: flex;
  flex-direction: column;
  justify-content: center;

  .sectionText {
    max-width: 636px;
    align-self: center;
    margin-bottom: 64px;
  }
}
