@import "~stylesheets/utils/index";

.contentHeader {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div:first-child {
    h3 {
      margin-top: 0;
    }
    flex: 1;
  }

  @include media($max-sm) {
    flex-direction: column;
    margin-bottom: 16px;
  }
}

.eventHeader {
  margin-bottom: 16px;

  > h3 {
    margin-bottom: 10px;
  }

  > p {
    color: #226fb7;
    font-weight: bold;
    padding: 0;
  }
}

//EventRegisterButton

.regiserButtonContainer {
  display: flex;
  align-items: center;
}
