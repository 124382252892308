@import "~stylesheets/utils/index";

.base,
.blockWrap {
  display: flex;
  flex-direction: row;
  position: relative;
}

.base {
  margin-bottom: 16px;
  @media screen and (max-width: 359px) {
    flex-direction: column;
  }

  .stateCode {
    display: flex;
    align-items: center;

    @include media($min-sm) {
      height: 66px;
      width: 74px;
    }
    @include media($max-sm) {
      height: 29px;
      width: 40px;
    }

    .title {
      color: $color-dark-gray;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .blockWrap {
    align-items: center;
  }
}
