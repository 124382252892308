@import '~stylesheets/utils/index';

.base {
}

.container {
  @include content-container;
}

.tooltip {
  font-size: 90%;
  text-align: right;
  padding-right: 5px;
}
