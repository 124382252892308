@import "~stylesheets/utils/index";

.base {
  display: flex;
  background: #f1c400;

  .innerContainer {
    @include content-container;
    display: flex;
    padding: 16px 32px;

    .contentBlock {
      display: flex;
      @include media($max-lg) {
        flex-direction: column;
        gap: 8px;
        flex: 1;
      }
    }

    .introBlock {
      display: flex;
      flex-direction: row;
      align-items: center;

      &.expand {
        flex-direction: column;
        align-items: flex-start;
      }

      .intro {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
        text-transform: capitalize;
      }
      .date {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #092c74;
      }
      .dateBlock {
        display: flex;
        flex-direction: column;
      }

      @include media($min-lg) {
        .intro {
          margin-right: 12px;
        }
        padding-right: 12px;
        border-right: 1px solid #535353;
        margin-right: 32px;
      }

      @include media($max-lg) {
        flex-direction: column;
        align-items: flex-start;
      }

      @include media($max-sm) {
        .intro {
          font-size: 16px;
        }
      }

      @media screen and (max-width: 356px) {
        .dateBlock {
          flex-direction: column;
          align-items: flex-start;

          .buttonBlock {
            margin-left: 0 !important;
          }
        }
      }
    }

    .buttonBlock {
      display: none;
      min-width: 130px;

      &.right {
        display: flex;
        align-items: center;
        margin-left: auto;

        @include media($max-md) {
          display: none;
        }
      }

      &.mobile {
        display: flex;
        align-items: center;
        margin-left: 0;

        @include media($min-md) {
          display: none;
        }
      }

      .button {
        @include remove-button-appearance;
        display: flex;
        flex-direction: row;
        gap: 8px;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #092c74;
      }

      .chevronDown {
        align-self: center;
        height: 24px;

        path {
          fill: #092c74;
        }

        &.expand {
          transform: rotate(180deg);
        }
      }

      @include media($max-lg) {
        display: block;
      }
    }

    .detailsBlock {
      display: flex;
      align-items: center;

      @include media($max-lg) {
        display: none;
      }
      &.expand {
        display: flex;

        .details {
          max-width: 648px;
        }
      }

      flex: 1;
    }

    .details {
      padding: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #110f24;
    }
  }
}

.closeButtonBlock {
  align-items: flex-start !important;
  height: 100%;
  align-items: center;
  margin-left: 32px;

  @include media($max-lg) {
    margin-left: auto;
  }

  .closeButton {
    .crossIcon {
      height: 28px;
      width: 28px;

      path {
        fill: #110f24;
      }
    }
  }
}
