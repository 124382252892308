@import '~stylesheets/utils/index';

.container {
  @include content-container;

  h2 {
    font-family: $base-font-family;
    text-transform: none;
    color: $color-dark-blue;
    @include font-size(32px);
    margin: 0 0 16px;
    line-height: 1.1em;
  }

  h3 {
    font-family: $base-font-family;
    text-transform: none;
    color: $color-dark-blue;
    @include font-size(28px);
    margin: 0 0 16px;
    line-height: 1.1em;
  }
}

.intro {
  margin: 32px auto;

  @include media($max-sm) {
    .introCol2 {
      display: none;
    }
  }
}

@include media($min-sm) {
  .intro {
    display: flex;
    align-items: center;
  }

  .introCol1 {
    padding-right: 32px;
  }

  .introCol2 {
    width: 33.333333333%;
    flex-shrink: 0;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.tableHeader {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .container {
    display: flex;
  }

  span {
    @include font-size(24px);
    color: white;
    font-weight: 700;
    flex-shrink: 0;

    // IE
    @media all and (-ms-high-contrast:none) {
      color: $accent-secondary;
    }
  }

  &::after {
    content: '';
    display: block;
    @include centerY;
    position: absolute;
    width: 100%;
    height: 60px;
    background-color: $accent-secondary;
    z-index: -1;

    // IE
    @media all and (-ms-high-contrast:none) {
      display: none;
    }
  }

  .tableCol1,
  .tableCol2,
  .tableCol3 {
    display: flex;
    align-items: center;
    flex-grow: 1;

    img {
      margin-left: 32px;
      width: auto;
      height: 120px;
    }
  }
}

.questionRow {
  width: 100%;
  display: flex;
  align-items: center;
  color: $accent-secondary;
  @include font-size(14px);
  margin-bottom: 32px;

  h6 {
    display: none;
  }

  .tableCol1 {
    font-weight: bold;

    span {
      display: block;
    }
  }

  .tableCol2,
  .tableCol3 {
    position: relative;

    &::before {
      content: '';
      width: 2px;
      @include centerY;
      position: absolute;
      left: 0;
      background: #5a94bc;
      height: 60px;
    }
  }
}

.tableCol1 {
  width: 140px;
  flex-shrink: 0;
  padding-right: 12px;
  text-align: center;
  justify-content: center;
}

.tableCol2,
.tableCol3 {
  width: 100%;
  flex-grow: 1;
  padding: 0 24px;
}

@include media($max-sm) {
  .tableHeader {
    display: none;
  }

  .tableBody {
    h6 {
      margin: 4px 0;
      @include font-size(14px);
      text-transform: uppercase;
      display: block;
    }

    .questionRow {
      flex-wrap: wrap;
      align-items: flex-start;
    }

    .tableCol1 {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      @include font-size(16px);
      border-bottom: 2px solid #5a94bc;
      padding-bottom: 8px;
      line-height: 1;

      img {
        width: auto;
        height: 20px;
        margin-right: 8px;
        flex-shrink: 0;
      }
    }

    .tableCol2,
    .tableCol3 {
      width: 50%;
      padding: 8px 16px 0 0;

      &::before {
        content: initial;
      }
    }
  }
}

.button {
  display: inline-flex;
  background: #2972cf;
  color: white;
  font-weight: bold;
  padding: 18px 24px;
  margin-top: 8px;
  margin-right: 16px;
  justify-content: flex-start;
  align-items: center;

  @include media($max-sm) {
    width: 100%;
  }

  svg {
    width: 18px;
    height: 15px;
    vertical-align: middle;
    transition: all $interaction-shift;

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }

  span {
    vertical-align: middle;
  }

  &:hover,
  &:active,
  &:focus {
    background: $accent-secondary;
    color: white;

    svg {
      transform: translateX(8px);
    }
  }
}
