.base {

    h3 {
        font-size: 16px;
    }
    
    ul {
        list-style: none;
        padding: 0;
        
        >*:not(:last-child){
            margin-bottom: 10px;
        }
    }
}