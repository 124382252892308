/**
 * @license
 * MyFonts Webfont Build ID 2792584, 2014-04-10T13:17:10-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed ../../Webfonts/gentona(s).
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=2792584
 * Licensed pageviews: 1,000,000
 *
 * © 2014 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
/* @import url("//hello.myfonts.net/count/2a9c88"); */




/*==================*/
/*=== Bold (700) ===*/
/*==================*/
@font-face {
  font-display: swap;
  font-family: 'gentona';
  src: url('/webfonts/gentona/Gentona-Bold.eot');
  src: url('/webfonts/gentona/Gentona-Bold.woff') format('woff'),
       url('/webfonts/gentona/Gentona-Bold.ttf') format('truetype'),
       url('/webfonts/gentona/Gentona-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'gentona';
  src: url('/webfonts/gentona/Gentona-BoldItalic.eot');
  src: url('/webfonts/gentona/Gentona-BoldItalic.woff') format('woff'),
       url('/webfonts/gentona/Gentona-BoldItalic.ttf') format('truetype'),
       url('/webfonts/gentona/Gentona-BoldItalic.eot?#iefix') format('embedded-opentype');
  font-weight: 700;
  font-style: italic;
}





/*======================*/
/*=== Semi-Bold (600) ===*/
/*======================*/
@font-face {
  font-display: swap;
  font-family: 'gentona';
  src: url('/webfonts/gentona/Gentona-SemiBold.eot');
  src: url('/webfonts/gentona/Gentona-SemiBold.woff') format('woff'),
       url('/webfonts/gentona/Gentona-SemiBold.ttf') format('truetype'),
       url('/webfonts/gentona/Gentona-SemiBold.eot?#iefix') format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'gentona';
  src: url('/webfonts/gentona/Gentona-SemiBoldItalic.eot');
  src: url('/webfonts/gentona/Gentona-SemiBoldItalic.woff') format('woff'),
       url('/webfonts/gentona/Gentona-SemiBoldItalic.ttf') format('truetype'),
       url('/webfonts/gentona/Gentona-SemiBoldItalic.eot?#iefix') format('embedded-opentype');
  font-weight: 600;
  font-style: italic;
}





/*====================*/
/*=== Medium (500) ===*/
/*====================*/
@font-face {
  font-display: swap;
  font-family: 'gentona';
  src: url('/webfonts/gentona/Gentona-Medium.eot');
  src: url('/webfonts/gentona/Gentona-Medium.woff') format('woff'),
       url('/webfonts/gentona/Gentona-Medium.ttf') format('truetype'),
       url('/webfonts/gentona/Gentona-Medium.eot?#iefix') format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'gentona';
  src: url('/webfonts/gentona/Gentona-MediumItalic.eot');
  src: url('/webfonts/gentona/Gentona-MediumItalic.woff') format('woff'),
       url('/webfonts/gentona/Gentona-MediumItalic.ttf') format('truetype'),
       url('/webfonts/gentona/Gentona-MediumItalic.eot?#iefix') format('embedded-opentype');
  font-weight: 500;
  font-style: italic;
}





/*==================*/
/*=== Book (400) ===*/
/*==================*/
@font-face {
  font-display: swap;
  font-family: 'gentona';
  src: url('/webfonts/gentona/Gentona-Book.eot');
  src: url('/webfonts/gentona/Gentona-Book.woff') format('woff'),
       url('/webfonts/gentona/Gentona-Book.ttf') format('truetype'),
       url('/webfonts/gentona/Gentona-Book.eot?#iefix') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'gentona';
  src: url('/webfonts/gentona/Gentona-BookItalic.eot');
  src: url('/webfonts/gentona/Gentona-BookItalic.woff') format('woff'),
       url('/webfonts/gentona/Gentona-BookItalic.ttf') format('truetype'),
       url('/webfonts/gentona/Gentona-BookItalic.eot?#iefix') format('embedded-opentype');
  font-weight: 400;
  font-style: italic;
}





/*===================*/
/*=== Light (300) ===*/
/*===================*/
@font-face {
  font-display: swap;
  font-family: 'gentona';
  src: url('/webfonts/gentona/Gentona-Light.eot');
  src: url('/webfonts/gentona/Gentona-Light.woff') format('woff'),
       url('/webfonts/gentona/Gentona-Light.ttf') format('truetype'),
       url('/webfonts/gentona/Gentona-Light.eot?#iefix') format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'gentona';
  src: url('/webfonts/gentona/Gentona-LightItalic.eot');
  src: url('/webfonts/gentona/Gentona-LightItalic.woff') format('woff'),
       url('/webfonts/gentona/Gentona-LightItalic.ttf') format('truetype'),
       url('/webfonts/gentona/Gentona-LightItalic.eot?#iefix') format('embedded-opentype');
  font-weight: 300;
  font-style: italic;
}
