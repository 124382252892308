@import "~stylesheets/utils/index";

.base {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  @include media($min-md) {
    max-width: 696px;
  }
  @include media($max-md) {
    max-width: 640px;
  }
  @include media($max-sm) {
    max-width: 308px;
    margin-bottom: 20px;
  }
  @include media($min-sm) {
    margin-bottom: 40px;
  }

  .borderContainer,
  .textContainer {
    width: 100%;
  }

  .borderContainer {
    padding: 0 12px;

    @media screen and (max-width: 359px) {
      padding-left: 0;
    }

    .borderLine {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 9px;
      width: 100%;
      flex: 1;
      border-left: 1px solid #a9a9a9;
      border-right: 1px solid #a9a9a9;

      hr {
        width: 100%;
        height: 1px;
        margin: 0;
        background-color: #a9a9a9;
      }
    }
  }

  .textContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div {
      display: flex;
      flex-direction: column;
    }
    > *:last-child {
      svg {
        margin-left: auto;
      }
    }
    @include media($min-md) {
      svg {
        display: none;
      }
    }

    span {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      color: #a9a9a9;
    }
  }

  .left,
  .right {
    height: 20px;
    width: 28px;
  }
  .left {
    transform: rotateY(180deg);
  }
}

.base {
  @include media($max-md) {
    > .left,
    > .right {
      display: none;
    }
  }
}
