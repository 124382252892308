@import "~stylesheets/utils/index";

// .base {
// }

.speakersCards {
  display: flex;
  flex-direction: row;

  > div:not(:last-child) {
    margin-right: 30px;
  }

  @include media($max-md) {
    flex-direction: column;
    align-items: center;

    > div:not(:last-child) {
      margin: 0;
      margin-bottom: 30px;
    }
  }

  .speakerCard {
    text-align: center;
    padding: 16px;
    background-color: #e8f0f8;
    min-height: 268px;
    width: 220px;

    .portrait div {
      height: 224px;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
    }

    .portrait {
      > div,
      img {
        border-radius: 100%;
      }
      img {
        max-height: 150px;
      }
    }

    h4 {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      color: #226fb7;
    }
    p {
      padding: 0;
      font-size: 18px;
      font-weight: 400;
      color: #9c9c9c;
    }
  }
}
