@import "src/stylesheets/utils/index";

.base {
  @include content-container;
  padding-top: 0;
}

.header {
  padding: 32px 0;

  h1 {
    margin: 0;
  }

  @include media($min-sm) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > div:last-child {
      margin-bottom: 6px;
    }
  }
}

.embedSiteLink {
  margin-top: 8px;
}

.available,
.unavailable {
  font-weight: bold;
  font-size: 16px;
}

.available {
  color: #85c6bb;
}

.unavailable {
  color: #ea927e;
}

.regionBanner {
  margin-bottom: 12px;
}
