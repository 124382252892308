@import '~stylesheets/utils/index';

.container {
  @include content-container;

  h2 {
    margin-bottom: 8px;
  }

  h3 {
    color: $base-font-color;
    margin-top: 0;
  }
}
