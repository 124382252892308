@import '~stylesheets/utils/index';

.container {
  @include content-container;
  padding-top: 32px;
  padding-bottom: 48px;
}

.header {
  padding: 0 0 32px;

  h1 {
    margin: 0;
  }

  @include media( $min-sm ) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > div:last-child {
      margin-bottom: 6px;
    }
  }
}
