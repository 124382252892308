@import '~stylesheets/utils/index';

.base {
  position: relative;
  display: flex;
  background: #f3f6f8;
  padding: 16px;

  // IE
  @media all and (-ms-high-contrast:none) {
    height: 62px;
  }
}

.icon-search,
.icon-clear {
  @include svg-color($color-blue);
  width: 24px;
  height: auto;
  position: relative;
  z-index: 1;
}

.icon-search {
  margin-right: 16px;
  &.invisible {
    display: none;
  }
}

.icon-clear {
  cursor: pointer;
  margin-left: 16px;
  opacity: 0;

  &.visible {
    opacity: 1;
  }

  &:hover {
    @include svg-color($color-light-blue);
  }
}

.input {
  position: relative;
  z-index: 1;
  background: transparent;
  border: none;
  flex-grow: 1;
  outline: none;
  color: $color-dark-blue;
  font-weight: 300;
  @include font-size(18px);

  @include media($min-sm) {
    @include font-size(24px);
  }
}

.placeholder {
  @include centerY;
  left: 60px;
  right: 16px;
  position: absolute;
  z-index: 0;
  pointer-events: none;
  font-style: italic;
  font-weight: 600;
  color: #aaaaaa;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include font-size(14px);

  @include media($min-sm) {
    @include font-size(24px);
  }
}

.nav {
  background: #226FB7;
  .placeholder {
    color: rgba(#FFF, .75);
    left: 17px;
  }
  .input {
    border-bottom: 1px solid rgba(#FFF, .75);
    color: rgba(#FFF, .75);
  }
  .icon-search,
  .icon-clear {
    position: absolute;
    right: 15px;
    width: 15px;
    height: 15px;
    margin-right: 0;
    path {
      fill: rgba(#FFF, .75);
    }
  }
  .icon-clear {
    width: 20px;
    height: 20px;
    top: 15px;
  }
}
