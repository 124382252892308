@import "~stylesheets/utils/index";

.base {
  width: 100%;
  display: flex;

  @include media($min-xs) {
    border-bottom: 1.2px solid $color-blue;
    flex-direction: row;
    > div:not(:first-child) {
      margin-left: 4px;
    }
  }
  @include media($max-xs) {
    flex-direction: column;
  }
}
