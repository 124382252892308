@import '../NavRegionSearch.module.scss';

.dropMenu {
  position: absolute;
  z-index: 20;
  transform: translateY(-1px);

  border: 1px solid rgba(#949494, .5);
  box-shadow: 0 0 3px 0 rgba(#000000, .15);
  background: #FFF;

  width: 150px;
  @include media($min-lg) {
   width: 232px;
  }

  max-height: calc(100vh - 100px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  transition: opacity .3s,
    visibility .3s;
  opacity: 0;
  visibility: hidden;
  &.open {
    opacity: 1;
    visibility: visible;
  }

  .heading, .empty {
    color: #8E8E8E;
    padding: 6px 8px 4px;
    font-size: 14px;
    @include media($min-lg) {
      padding: 7px 14px;
      font-size: 18px;
    }
  }
  .heading {
    background: #EDEDED;
    text-transform: uppercase;
    padding-bottom: 4px;
  }

  .item, .showAll {
    padding: 9px;
    @include media($min-lg) {
      padding: 9px 14px;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color .3s;
    path {
      fill: #226FB7;
    }
    &:hover,
    &.active {
      background: #79BFFE;
      color: #092C74;
    }
  }
  .item {
    background: #F3F6F8;
    font-size: 14px;
    @include media($min-lg) {
      font-size: 16px;
    }
  }
  .showAll {
    text-transform: uppercase;
    font-size: 14px;
    @include media($min-lg) {
      font-size: 18px;
    }
    padding-bottom: 7px;
    text-decoration-line: underline;
    text-decoration-color: rgba(#092C74, 0);
    transition: background-color .3s,
      text-decoration-color .3s;
    &:hover, &:active, &:focus {
      text-decoration: underline;
      text-decoration-color: rgba(#092C74, 1);
    }
  }

  .mdHide {
    @include media($max-lg) {
      display: none;
    }
  }
}
