@import '~stylesheets/utils/index';

.base {
  border-bottom: 1px solid #dbeeff;

  @include media($max-sm) {
    width: 100%;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 100%;
  }
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;

  > *:first-child {
    flex-shrink: 0;
  }

  img {
    height: 32px;
    margin-right: 16px;
  }

  h2 {
    @include type-scale(48px, 26px);
    line-height: 0.9em;
    font-weight: 300;
    color: $accent-primary;
    padding: 0;
    margin: -4px 0 0 0;
    padding-top: 8px;

    @include media($min-md) {
      margin: -4px 0 0 0;
    }
  }

  @include media($min-md) {
    padding-top: 16px;
  }

  p {
    font-size: 20px;
    @include type-scale(18px, 14px);
    font-weight: 400;
    color: $accent-secondary;
    line-height: 1.1em;
  }
}

.light {
  border-bottom: 1px solid white;
  color: white;
  p,
  h2 {
    color: white;
  }
}

.headerContent {
  @include media($min-md) {
    display: flex;
  }

  @include media($max-md) {
    display: none;
  }
}
