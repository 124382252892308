@import "~stylesheets/utils/index";

.container {
  @include vw-container($sm);
  &.legacy {
    @include content-container;
  }

  hr {
    background-color: $color-medium-gray;
  }

  > ul {
    list-style-type: none;
    padding-bottom: 32px;
    border-bottom: 1px solid $color-medium-gray;
    margin-bottom: 32px;

    > li {
      font-size: 22px;
      font-weight: 500;
      color: #110f24;
    }
    > li:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  ul li::before {
    content: "\25AA"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: $color-blue; /* Change the color */
    font-size: 30px;
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }

  /* Prevent long URLs in footnotes from breaking laouy: */
  a {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
}

.image {
  display: block;
  object-fit: contain;
  margin: auto;
  width: 100%;
  max-height: 572px;
  max-width: 480px;
}

span.date {
  font-size: 22px;
  font-weight: 400;
  color: #8e8e8e;
}

.blockQuote {
  color: $color-dark-blue;
  font-style: italic;
  font-weight: 600;

  @include media($min-md) {
    font-size: 39px;
    line-height: 57px;
    margin: 40px -96px;
  }
  @include media($max-md) {
    font-size: 24px;
    line-height: 34px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
