@import "~stylesheets/utils/index";

.container {
  @include content-container;
  .iframeContainer {
    @include video-container;
  }

  .contentContainer {
    @media screen and (min-width: 1280px) and (max-height: 900px) {
      display: flex;
      flex-direction: row;
      margin-bottom: -250px;

      > div:first-child {
        margin-right: 32px;
        flex: 1.5;
      }
      > div:nth-child(2) {
        flex: 1;
        > h3 {
          margin-top: 0;
        }
      }
    }
  }
}

.interiorHeader {
  @include content-container;
  display: flex;
  flex-direction: row;
  > div:first-child {
    flex-basis: 40%;
  }
  > div:not(:first-child) {
    flex: 1;
    > p:first-child {
      margin-top: 20px;
    }
  }
}

.embedDescription {
  h5 {
    margin-bottom: 0;
  }
  ul {
    margin-top: 0;
    padding: 0 0 0 16px;
  }

  a {
    text-decoration: underline;
  }
}

.videoWrapper {
  @include video-container;
  min-height: 400px;

  > div:first-child {
    height: 200px;
  }
}

.contentRight {
  @include media($min-sm) {
    margin-top: 20px;
  }
  > div {
    float: right;
  }
}

.cardOptions {
  margin-top: 32px;
  border-top: 1px solid $color-light-gray;
  padding-top: 32px;
}

.textBanner {
  margin-bottom: 24px;
}
