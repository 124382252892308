@import '~stylesheets/utils/index';

.container {
  @include content-container;

  p {
    max-width: 55ch;
  }
}

.wideHeadingCard h3 {
  max-width: none !important;
}

.faq-button {
  line-height: 1.25;
  font-size: 1.25rem;
}

.testingToolkit {
  margin-top: 1.5rem;
}
