@import '~stylesheets/utils/index';

.base {
  display: flex;

  &.light {
    img {
      filter: brightness(100);
    }
  }
}
