@import "~stylesheets/utils/index";

.base {
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  background: #eeeeee;
  gap: 20px;

  .contentBlock {
    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
      color: #110f24;
      margin: 0;
      margin-bottom: 8px;
    } 

    ol, ul {
      padding-left: 16px;
    }

    .content {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;

      color: #535353;
    }
  }

  .buttonBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

  }
}
