@import "~stylesheets/utils/index";

.container {
  @include vw-container($md);
}

.selectorRow {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 16px;
}

.selector {
  max-width: 275px;
  width: calc(100% + 160px);
}

.button {
  width: 80px;
  max-width: 90px;
}
