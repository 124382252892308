@import "~stylesheets/utils/index";

.regionDataContainer {
  padding: 20px;
  background-color: #fbfbfb;
  border: 1px solid #f0f0f0;

  ul {
    padding-left: 15px;
    margin-bottom: 0;
  }

  > h3 {
    margin-top: 0;
    margin-bottom: 16px;
  }

  > p {
    border-left: 5px solid #d4eaff;
    padding-left: 15px;
    padding-bottom: 0;
  }
  > a {
    font-size: 12px;
    font-weight: 500;
  }

  .noteDate {
    color: $color-dark-blue;
    font-weight: 500;
  }
}
