@import 'src/stylesheets/utils/index';

// :NOTE: These labels are reversed
$covid: #60c6c6;
$non-covid: #e39260;
$empty: #fff;
$empty-border: #c2c2c2;

table.base {
  border-collapse: collapse;
  border: 0;
  margin: 0;
  td,
  th {
    border: 0;
  }
}
tr.labels,
tr.bedsRow,
tr.icuRow,
tr.legend {
  > td {
    vertical-align: middle;
    padding-top: 16px;
    padding-bottom: 8px;
    height: 29px;
  }
}

tr.labels,
tr.legend {
  td {
    color: #727272;
    font-size: 12px;
  }
}

td.label {
  text-align: left;
  font-size: 18px;
  line-height: 14px;
}

td.balls {
  display: flex;
  justify-content: space-between;
  .ball {
    width: 29px;
    height: 29px;
    border-radius: 30px;
    margin-left: 8px;
    position: relative;
    &:first-child {
      margin-left: 0;
    }
    .half {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 15px;
      border-radius: 15px 0 0 15px;
    }
    &.non-covid,
    .non-covid {
      background: $non-covid;
    }
    &.covid,
    .covid {
      background: $covid;
    }
    &.empty {
      background: $empty;
      border: 1px solid $empty-border;
      .half {
        left: -1px;
        bottom: -1px;
      }
    }
  }
}

td.totalLastWeek {
  padding-left: 20px;
  padding-right: 24px;
  border-right: 1px solid #d0e9ff !important;
  text-align: right;
  color: #226fb7;
  @include media($min-sm) {
    .valueLabel {
      display: none;
    }
  }
}
td.totalTwoWeeks {
  padding-left: 20px;
  padding-right: 40px;
  text-align: right;
  color: #226fb7;
  @include media($min-sm) {
    .valueLabel {
      display: none;
    }
  }
}

td.lastWeek {
  text-align: center;
  white-space: nowrap;
}
td.lastWeekSpacer {
  border-right: 1px solid #d0e9ff !important;
}
td.twoWeeks {
  white-space: nowrap;
  padding-left: 20px;
  text-align: left;
}

.sub {
  font-size: 12px;
  color: #727272;
  white-space: nowrap;
}

td.legend {
  display: flex;
  .nonCovid,
  .covid,
  .empty {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-right: 31px;
    &::before {
      content: ' ';
      width: 15px;
      height: 15px;
      border-radius: 15px;
      margin-right: 9px;
    }
  }
  .nonCovid::before {
    background: $non-covid;
  }
  .covid::before {
    background: $covid;
  }
  .empty::before {
    background: $empty;
    border: 1px solid $empty-border;
  }
}


@include media($max-sm) {
  table.base {
    display: block;
    > thead {
      display: block;
    }
    > tbody {
      display: block;
    }
    > tfoot {
      display: block;
    }
    > thead,
    > tbody,
    > tfoot {
      > tr {
        display: flex;
        flex-direction: column;
        > td:empty {
          display: none;
        }
        > td {
          text-align: left;
          padding-left: 12px;
          padding-right: 0;
          border: 0;
          &.totalLastWeek,
          &.totalTwoWeeks {
            text-align: right;
            .valueLabel {
              padding-right: 12px;
            }
            > span {
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  thead tr.labels td {
    display: none;
  }
  tr.bedsRow,
  tr.icuRow {
    td {
      height: auto;
    }
    > td {
      padding-bottom: 12px;
    }
    td.label {
      order: 1;
      color: #226fb7;
    }
    td.balls {
      order: 2;
      padding-top: 0;
      padding-bottom: 6px;
      .ball {
        margin-left: 0;
      }
    }
    td.totalLastWeek {
      order: 3;
      padding-bottom: 6px;
      border: 0 !important;
      padding-top: 9px;
    }
    td.totalTwoWeeks {
      order: 4;
      border-bottom: 1px solid #d0e9ff !important;
      padding-left: 0;
      padding-top: 0;
      margin-left: 9px;
    }
    td.totalLastWeek,
    td.totalTwoWeeks {
      color: inherit;
      font-size: 12px;
      color: #727272;
      .value {
        float: right;
        font-size: 16px;
        color: #226fb7;
      }
    }
  }
  tr.icuRow {
    padding-top: 9px;
  }
  td.label {
    .sub::before {
      content: ' ';
    }
    br {
      display: none;
    }
  }
  td.legend {
    padding-left: 0 !important;
    padding-top: 0;
    > div {
      margin-right: 9px !important;
    }
  }
}
