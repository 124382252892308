@import '~stylesheets/utils/index';

.base {
  cursor: pointer;

  svg {
    display: block;
    width: 30px;
    height: auto;
  }

  @include media($min-md) {
    display: none;
  }
}
