@import "~stylesheets/utils/index";

.base {
  color: $color-dark-blue;
  font-style: italic;
  font-weight: 600;

  @include media($min-lg) {
    margin: 40px -96px;
  }
  @include media($min-md) {
    font-size: 39px;
    line-height: 57px;
  }
  @include media($max-md) {
    font-size: 24px;
    line-height: 34px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
