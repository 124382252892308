@import '~stylesheets/utils/index';

.container {
  @include content-container;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

// Items marked "styleguide-annotation" are internal to the styleguide and
// should be styled as parts of the styleguide, not as parts of the app
.styleguide-annotation {
  color: #110f24;
  font-family: 'Lato';
  font-size: 20px;
  max-width: 800px;
  padding: 0;

  h1,
  h2,
  h3,
  p {
    color: inherit;
    font-family: inherit;
  }
}
h1.styleguide-annotation,
h2.styleguide-annotation,
.styleguide-annotation h1,
.styleguide-annotation h2 {
  font-weight: 900;
  text-transform: none;
}
h1.styleguide-annotation,
.styleguide-annotation h1 {
  font-size: 40px;
}
h2.styleguide-annotation,
.styleguide-annotation h2 {
  font-size: 32px;
  margin-top: 1.75em;
  margin-bottom: 0.5em;
}
h3.styleguide-annotation,
.styleguide-annotation h3 {
  font-weight: 600;
  font-size: 22px;
  color: #171c28;
  margin: 2em 0 0.5em;
}
code.styleguide-annotation,
.styleguide-annotation code {
  display: inline-block;
  font-size: 0.8em;
  transform: translateY(-3%);
  font-family: monospace;
  font-weight: 500;
  color: #171c28;
  background: $color-light-gray;
  opacity: 0.85;
  padding: 0.15em 0.25em;
}

a.styleguide-annotation,
.styleguide-annotation a {
  color: adjust-color($color-dark-blue, $saturation: 30%, $lightness: 10%);
  font-weight: 500;
  &:hover {
    color: $color-blue;
  }
}
ul.styleguide-annotation,
ol.styleguide-annotation,
.styleguide-annotation ul,
.styleguide-annotation ol {
  margin: 0 0 0 2ch;
}
// Add margin between adjacent p, ul, ol
// this is terrible and could be better
p.styleguide-annotation + p.styleguide-annotation,
.styleguide-annotation p + p,
p.styleguide-annotation + ul.styleguide-annotation,
.styleguide-annotation p + ul,
p.styleguide-annotation + ol.styleguide-annotation,
.styleguide-annotation p + ol,
ul.styleguide-annotation + p.styleguide-annotation,
.styleguide-annotation ul + p,
ul.styleguide-annotation + ul.styleguide-annotation,
.styleguide-annotation ul + ul,
ul.styleguide-annotation + ol.styleguide-annotation,
.styleguide-annotation ul + ol,
ol.styleguide-annotation + p.styleguide-annotation,
.styleguide-annotation ol + p,
ol.styleguide-annotation + ul.styleguide-annotation,
.styleguide-annotation ol + ul,
ol.styleguide-annotation + ol.styleguide-annotation,
.styleguide-annotation ol + ol {
  margin-top: 0.5em;
}

.row {
  display: flex;
  flex-direction: row;

  width: 100%;
  > *:not(:last-child) {
    margin-right: 20px;
  }
}

.backdrop {
  padding: 10px;
}

.backdropDark {
  background-color: #aa8181;
  padding: 10px;
}

.testBox {
  height: 100px;
  width: 300px;
  background-color: lightgrey;
}
