@import "src/stylesheets/utils/index";

// .contentSection {
//   > h2 {
//     margin: 48px 0 48px 0;
//   }
// }

.learn-more-row {
  @include media($min-sm) {
    @include grid(3);
  }
}

.learnMoreColumn {
  display: flex;
  flex-direction: column;

  @include media($max-sm) {
    padding-bottom: 16px;
    border-bottom: 1px solid $color-light-gray;

    &:last-child {
      border-bottom: none;
    }
  }

  .learnMoreData {
    > span {
      color: $color-blue;
    }
    > span:first-child {
      font-weight: 300;
      @include font-size(36px);
    }
    > span:last-child {
      font-size: 1rem !important;
    }
  }
}

.learnMoreList {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      line-height: 32px;
    }
  }
}

.recommendedContainer {
  display: flex;
  align-items: baseline;
  padding-top: 8px;

  svg {
    padding-right: 15px;
    width: 52px;
  }

  > span {
    text-transform: uppercase;
    font-size: 26px;
    font-weight: bold;
  }

  &.negative {
    transform: translateY(-8px);
    svg {
      transform: translateY(16px);
    }
  }
}

/* Color Schemes */
.available,
.unavailable {
  font-weight: bold;
  font-size: 16px;
}

.available {
  color: #85c6bb;
}

.unavailable {
  color: #ea927e;
}

.regionDisclaimer {
  p {
    font-style: italic;
    font-size: 14px;
    padding: 16px 0 0 0;
  }
}

.embedSiteLink {
  margin-top: 8px;
}

.iconHeader {
  display: flex;
  align-items: center;
  svg {
    margin-right: 16px;
  }
}
