@import '~stylesheets/utils/index';

.legendText {
  fill: #000;
}

.maxLine {
  stroke: #E5E7E9;
  stroke-dasharray: 7 3;
  fill: none;
  stroke-width: 2;
}

.thresholdLine {
  stroke-dasharray: 3 7;
  stroke: #E5E7E9;
  fill: none;
  stroke-width: 3;
}
