@import "~stylesheets/utils/index";

.base {
  position: relative;

  @include media($min-lg) {
    display: flex;
  }
}

.toggle {
  position: relative;
  margin-top: 32px;

  @include media($min-lg) {
    display: none;
  }
  @media print {
    display: none;
  }
}

.content-header {
  @include content-container;
  padding-top: 16px;

  &:after {
    content: initial;
  }

  h1,
  h2,
  h3,
  h5,
  p {
    margin: 0 0 8px;
  }

  .header-info {
    max-width: 600px;
  }

  @include media($min-md) {
    display: flex;
    justify-content: space-between;
  }
}

.content {
  @include content-container;
  padding: 0 !important;
  padding-bottom: 32px;

  @include media($max-lg) {
    &:after {
      content: "";
      display: block;
      position: absolute;
      @include trbl(0, 0, 0, auto);
      z-index: $foreground;
      background: $color-black;
      opacity: 0;
      transition: opacity $layout-shift;
    }
  }
}

.with-sidebar {
  .content {
    @include media($min-lg) {
      margin: 0;
      flex-shrink: 0;
      width: 970px;
      margin-left: calc(50vw - 640px);
    }
  }
}

.sidebar {
  position: fixed;
  z-index: $foreground;
  right: 0;
  bottom: 0;
  top: 120px;
  overflow-x: hidden;
  overflow-y: auto;

  padding: 16px;
  background: #fafafa;
  width: 100vw;

  @include media($min-sm) {
    width: 420px;
    padding: 32px;
  }

  @include media($min-md) {
    top: 154px;
  }

  @include media($min-lg) {
    width: calc(100vw - 970px);
    position: relative;
    @include trbl(auto);

    > * {
      max-width: 420px;
    }
  }

  @include media($max-lg) {
    transition: all $layout-shift;
    transform: translateX(100%);
  }
  @media print {
    display: none;
  }
}

.sidebar-open {
  @include media($max-lg) {
    .content {
      &:after {
        left: 0;
        opacity: 0.5;
      }
    }
    .sidebar {
      transform: none;
    }
  }
}

.sidebar-close {
  position: absolute;
  top: 16px;
  right: 16px;

  @include media($min-lg) {
    display: none;
  }
}

.sidebar-heading {
  margin-top: 0;
  padding-right: 64px;
}

.stopped-alert {
  position: relative;
}
