@import "~stylesheets/utils/index";

.container {
  @include content-container;

  p {
    max-width: 92ch;
  }
}

.wideHeadingCard h3 {
  max-width: none !important;
}

.faq-button {
  line-height: 1.25;
  font-size: 1.25rem;
}

.noBgColor {
  > div {
    background: none !important;
  }
}
.noBgColorNested {
  > div {
    a > div:first-child {
      background: none !important;
    }
  }
}

.bladeAlignEnd {
  > div {
    align-items: flex-end;
  }
}

.filterContainer {
  // @include media($min-md) {
  //   .selectFilter {
  //     display: none;
  //   }
  // }
  // @include media($max-md) {
  //   .tabBar {
  //     display: none;
  //   }
  // }
  .selectFilter {
    margin-bottom: 32px;

    @include media($min-md) {
      max-width: 472px;
      margin-bottom: 64px;
    }
  }
}
