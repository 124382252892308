@import "~stylesheets/utils/index";

.container {
  @include content-container;
}

.expertsContainer {
  ul {
    padding: 0;
    li {
      display: flex;
      flex-direction: row;
      margin-bottom: 24px;

      > a {
        display: contents;
      }

      @include media($min-md) {
        img {
          height: 180px;
          margin-right: 18px;
        }
      }
      @include media($max-md) {
        flex-direction: column;
        img {
          object-fit: cover;
          height: 548px;
          margin-bottom: 18px;
        }
      }
    }
  }
}
