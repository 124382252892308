@import "~stylesheets/utils/index";

.container {
  @include vw-container($md);

  p {
    max-width: 720px;
  }

  h2 {
    font-family: $base-font-family;
    text-transform: none;
    color: $color-dark-blue;
    @include font-size(32px);
    margin: 0 0 16px;
  }

  h3 {
    text-transform: uppercase;
    margin-bottom: 0;

    &:first-child {
      margin-top: 0;
    }
  }

  h4 {
    margin-top: 0;
    text-transform: none;
  }

  h3,
  h4 {
    @include font-size(24px);
    font-family: $base-font-family;
    font-weight: 300;
    color: #092c74;
    letter-spacing: 0.75px;
  }
}

.question {
  margin-bottom: 32px;

  h2 {
    font-weight: 600;
    @include font-size(20px);
    color: #092c74;
    letter-spacing: 0.62px;
  }
}

.sheetMenu {
  // background: #f9f8f8;
  margin-top: -32px;

  @include media($min-sm) {
    margin-bottom: 48px;
  }

  .container {
    display: flex;
  }
}

.sheetLink {
  position: relative;
  display: block;
  padding: 16px 0;
  margin: 0 32px 0 0;

  &:last-child {
    margin-right: 0;
  }

  text-transform: uppercase;
  @include font-size(14px);
  color: $color-medium-gray;
  letter-spacing: 0.06px;

  @include media($min-sm) {
    @include font-size(20px);
  }
}

.active {
  color: $accent-secondary;

  &::after {
    content: "";
    display: block;
    position: absolute;
    @include trbl(auto, 0, 0, 0);
    height: 4px;
    color: $accent-secondary;
    background: $accent-secondary;
  }
}
