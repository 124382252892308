@import '../StyleGuide.module.scss';

.container {
  h1:not(.styleguide-annotation),
  h2:not(.styleguide-annotation),
  h3:not(.styleguide-annotation),
  p:not(.styleguide-annotation) {
    padding: 0;
    margin: 0;
  }
}

.color-palette {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  $palette-item-spacing: 40px;

  position: relative;
  margin-top: 1.5rem;
  width: 800px + $palette-item-spacing;

  .color {
    position: relative;
    width: calc((100% - #{$palette-item-spacing} * 4) / 4);
    margin-right: $palette-item-spacing;
    margin-bottom: 2rem;

    .color-tile {
      width: 100%;
      padding-bottom: 50%;
      background: $color-light-gray;
      border-radius: 4px;
    }

    $colors: (
      'color-dark-blue': $color-dark-blue,
      'color-blue': $color-blue,
      'color-light-blue': $color-light-blue,
      'color-yellow': $color-yellow,
      'color-black': $color-black,
      'color-dark-gray': $color-dark-gray,
      'color-medium-gray': $color-medium-gray,
      'color-light-gray': $color-light-gray,
    );

    @each $name, $value in $colors {
      &.#{$name} {
        .color-tile {
          background: $value;
          margin-bottom: 4px;
        }

        .color-detail-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 700;
          padding: 0 1px;
          line-height: 1.35;

          &::before {
            opacity: 0.4;
            font-size: 11px;
            text-transform: uppercase;
          }
          &::after {
            font-size: 14px;
          }
        }

        .color-detail-row.name::before {
          content: 'Var:';
        }
        .color-detail-row.name::after {
          content: '$#{$name}';
        }

        .color-detail-row.value::before {
          content: 'Hex:';
        }
        .color-detail-row.value::after {
          content: '#{$value}';
          text-transform: uppercase;
        }
      }
    }
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -1rem;

  & > * {
    margin-bottom: 1rem;
  }
}
