@import '~stylesheets/utils/index';

.svg {
  width: 100%;
}

.maxLine {
  stroke: #E5E7E9;
  stroke-dasharray: 7 7;
  fill: none;
  stroke-width: 2;
}

.thresholdLine {
  stroke: #E5E7E9;
  stroke-dasharray: 3 7;
  fill: none;
  stroke-width: 3;
}
