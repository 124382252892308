@import '~stylesheets/utils/index';

.container {
  @include content-container;
  .iframeContainer {
    @include video-container;
  }

  .contentContainer {
    @media screen and (min-width: 1280px) and (max-height: 900px){
      display: flex;
      flex-direction: row; 
      height: 410px;
      
      >div:first-child{
        margin-right: 32px;
        padding: 0;
        flex: 1.5;
      }
      >div:nth-child(2){
        flex: 1;
        >h3{
          margin-top: 0;
        }
      }
    }
  }
}

.interiorHeader {
  @include content-container;
  display: flex;
  flex-direction: row;
  >div:first-child {
    flex-basis: 40%;
  }
  >div:not(:first-child){
    flex: 1;
    >p:first-child {
      margin-top: 20px;
    }
  }
}

.embedDescription {
  h5 {
    margin-bottom: 0;
  }
  ul {
    margin-top: 0;
    padding: 0 0 0 16px;
  }

  a {
    color: #68bcff;
    text-decoration: underline;
  }
}

.feedContainer {
  border-top: 1px solid #979797;
  padding-top: 32px;
}

.videoWrapper {
  @include video-container;
  min-height: 400px;

  >div:first-child {
    height: 200px;
  }
}

  .imageContainer {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: inherit;

    @include media($min-sm) {
      flex: 1;
      min-height: 280px;
    }

    @include media($max-sm) {
      height: 175px;
    }
  }