@import "~stylesheets/utils/index";

.base {
  margin: 0 0 60px 0;
  @include media($max-md) {
    margin-bottom: 32px;
  }
  .tabbar {
    @include media($max-md) {
      display: none !important;
    }
  }
  .select {
    @include media($min-md) {
      display: none !important;
    }
  }
}
