@import "~stylesheets/utils/index";

.container {
  @include content-container;
  margin-bottom: 32px;
}

.selectorRow {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto 16px;
}

.selectContainer {
  min-width: 275px;
}

.barGrid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  @include media($max-md) {
    flex-direction: column;
  }
}
