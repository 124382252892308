@import '~stylesheets/utils/index';

.container {
  margin-top: 0;
  @include content-container;
}

.iframe {
  border: 0;
  max-width: 960px;
}
