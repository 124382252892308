@import "~stylesheets/utils/index";

.statename {
  text-transform: uppercase;
  color: #666;
  padding-bottom: 0;
  margin-bottom: .25em;
}

.day {
  margin: 0;
  padding: 0;
  font-size: .9em;
}

.updated {
  font-size: .7em;
  font-style: italic;
  margin: 0;
  padding: .5em 0 .5em;
}
