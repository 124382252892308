@import "~stylesheets/utils/index";

.base {
  @include vw-container;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.main {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include media($min-lg) {
    flex-direction: row;
  }

  .selectContainer {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .graphContainer {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    &.us {
      height: 592px;
      max-height: 592px;
      @include media($min-sm) {
        height: 607px;
        max-height: 607px;
      }
    }
    &.world {
      height: 593px;
      max-height: 593px;
      @include media($min-sm) {
        height: 600px;
        max-height: 600px;
      }
    }
    &.open {
      height: auto;
      max-height: inherit;
    }
  }
}

.button {
  margin: 30px auto;
  padding: .5rem 1.75rem .5rem 2.25rem;
  width: 180px;
}

.placeholder {
  height: 600px;
  width: 100%;
  overflow: hidden;
}
