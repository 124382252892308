@import 'src/stylesheets/utils/index';

.base {
  position: relative;
  margin: 48px 0 0;
  padding: 48px 0;
  border-top: 1px solid #cde8ff;

  &:before {
    content: '';
    display: block;
    width: 280px;
    max-width: 50%;
    height: 4px;
    background-color: $accent-primary;
    position: absolute;
    top: 0;
  }

  @include media($min-md) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.title {
  margin-bottom: 18px;

  @include media($min-sm) {
    flex-shrink: 0;
    padding-right: 48px;
  }

  @include media($min-md) {
    padding-right: 64px;
  }
}

.content {
  flex-grow: 1;
  max-width: 646px;
  padding-right: 20px;
}

.titleIcon {
  width: 40px;
  height: auto;
  flex-shrink: 0;
  margin-right: 16px;
  img {
    display: block;
  }
}

.title {
  display: flex;
  align-items: center;
  color: $accent-secondary;

  h2 {
    margin: 0;
    line-height: 1;
  }
}

.vaccinesLink {
  color: $accent-primary;
  text-decoration: underline;
  @include font-size(14px);

  &:hover,
  &:active,
  &:focus {
    color: $accent-secondary;
  }
}

.dataSource {
  padding: 0;
  @include font-size(12px);
  opacity: 0.75;
  position: absolute;
  @include trbl(auto, auto, 0, 0);

  @include media($min-sm) {
    @include trbl(auto, 0, 0, auto);
  }

  a {
    color: $accent-primary;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      color: $accent-secondary;
    }
  }
}

.blurb {
  max-width: 476px;
  @include media($min-md) {
    padding-left: 56px;
  }
  p {
    margin-top: 0;
  }
}

.chart {
  margin-top: 0;
}
