@import '~stylesheets/utils/index';

.contentHeader {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  >div:first-child {
    h3 {
      margin-top: 0;
    }
    flex: 1;
  }

  @include media($max-sm) {
    flex-direction: column;
    margin-bottom: 16px;
  }
}

.eventHeader {
  margin-bottom: 16px;
  
  > h3 {
    margin-bottom: 10px;
  }

  > p {
    color: #226fb7;
    font-weight: bold;
    padding: 0;
  }
}

//EventRegisterButton

.regiserButtonContainer{
  display: flex;
  align-items: center;
}

//EventSpeakers

.speakersCards {
  display: flex;
  flex-direction: row;

  >div:not(:last-child){
    margin-right: 30px;
  }

  @include media($max-md) {
    flex-direction: column;
    align-items: center;

    >div:not(:last-child){
      margin: 0;
      margin-bottom: 30px;
    }
  }

  .speakerCard {
    text-align: center;
    padding: 16px;
    background-color: #E8F0F8;
    min-height: 268px;
    width: 220px;

    .portrait div{
      height: 224px;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
    }

    .portrait {
      >div, img {
        border-radius: 100%;
      }
      img {
        max-height: 150px;
      }
    }
    
    h4 {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      color: #226FB7;
    }
    p {
      padding: 0;
      font-size: 18px;
      font-weight: 400;
      color: #9C9C9C;
    }
  }
}