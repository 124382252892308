@import '~stylesheets/utils/index';

.base {
  position: relative;
}

.header {
  position: relative;
  position: sticky;
  z-index: $middleground;
  transition: all $layout-shift;
  background-color: white;
  padding-top: 16px;

  top: 70px;

  @include media($min-sm) {
    top: 78px;
  }

  @include media($min-md) {
    top: 138px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.aside {
  width: 280px;
  flex-shrink: 0;
  margin-left: 32px;
  padding-top: 16px;
  position: relative;

  @include media($max-md) {
    position: fixed;
    @include trbl( auto, 16px, 16px, 16px );
    width: auto;
    z-index: $foreground;
    margin: 0;
    padding: 0;
  }
}

.asideContainer {
  @include media($min-md) {
    position: absolute;
    display: flex;
    flex-direction: column;
  }

  @include media($max-md) {
    > *:first-child {
      display: none;
    }

    > *:nth-child(2) {
      width: 100%;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 100%;
  }
}

.container {
  @include content-container;
  position: relative;

  > *:first-child {
    flex-grow: 1;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     flex: 1 1 100%;
    }
  }
}

.timelineContainer {
  @include content-container;
  padding-top: 32px;
  padding-bottom: 32px;
}

.phaseGroup {
  display: flex;
  @include media($min-md) {
    padding-right: 315px;
  }
}

.phaseGroupTimeSpan {
  flex-shrink: 0;
  margin-right: 16px;

  @include media($min-sm) {
    margin-right: 32px;
  }
}

.phaseGroupPhases {
  @include media($min-md) {
    max-width: 580px;
  }
}

.phaseGroupPhase {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px 0;

  @include media($min-sm) {
    padding: 180px 0;
  }

  @include media($min-md-ht) {
    padding: 120px 0;
  }
}
