@import '~stylesheets/utils/index';

.container {
  @include content-container;

  ul,
  p {
    max-width: 720px;
  }

  & > p > a {
    margin: 0 0.5rem 0.5rem 0;

    @include media($max-xs) {
      width: 100%;
    }
  }

  .accordion + p {
    max-width: none;
    margin-top: 2.5em;
  }

  .accordion {
    margin-top: 50px;
  }

  .accordion-trigger-content {
    display: flex;
    align-items: center;

    img {
      height: 4.5rem;
      width: auto;
      margin: -0.35rem 1.5ch -0.35rem 0;
    }

    h3 {
      margin: 0;
      text-transform: uppercase;
      font-weight: normal;
      letter-spacing: 0.02em;
      font-size: 1.25rem;
    }

    @include media($max-xs) {
      img {
        display: none;
      }
      h3 {
        line-height: 1.25;
        font-size: 1.1rem;
        text-transform: none;

        &::after {
          content: '.';
        }
      }
    }
  }

  .accordion-content {
    font-size: 1.1rem;
    font-weight: 600;
    color: $color-dark-blue;
    line-height: 1.4;

    ul {
      padding-left: 2rem;

      @include media($max-xs) {
        margin: 0.5em 0 0;
        padding-left: 0.5rem;
      }
    }

    li {
      color: $color-blue;
      $bullet-scale: 1.25;
      font-size: $bullet-scale * 1em;
      line-height: 1.4 / $bullet-scale;

      &:not(:last-child) {
        margin-bottom: 0.5em;
      }

      span {
        font-size: 1em / $bullet-scale;
        vertical-align: center;
        color: $color-dark-blue;
      }
    }
  }
}
