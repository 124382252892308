@import 'src/stylesheets/utils/index';

.container {
  @include content-container;
}

.ethics-section {
  h2 {
    text-transform: none;
  }

  .ethics-principles-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: $color-dark-blue;
    line-height: 1.2;

    img {
      width: 8rem;
      height: 8rem;
      // IE
      display: block;
      margin: auto;
    }

    // IE
    .ethics-principles-item-content {
      width: 100%;
    }
  }

  .ethics-actions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;

    a {
      margin: 0 0.5rem 0.5rem 0;
      white-space: nowrap;
    }

    @include media ($max-xs) {
      flex-direction: column;
      align-items: stretch;

      a {
        margin: 0 0 0.5rem;
      }
    }
  }
}

.coursera-card {
  font-size: 1.1rem;

  *[class*='image'] {
    background-color: $color-dark-blue;
  }

  *[class*='text'] {
    line-height: 1.35;

    header:not(:last-child) {
      margin-bottom: 1rem;
    }
  }



  @include media ($min-md) {
    >div:last-child {
      max-width: 50%;
    }
    &.cardImageReverse {
      flex-direction: row;
    }  
  }

  @include media ($max-md) {
    &.cardImageReverse {
      margin-top: 16px;
    }  
  }
}
