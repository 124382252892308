@import '~stylesheets/utils/index';

.base {
  @include content-container;
  padding-top: 3rem;
  padding-bottom: 3rem;

  :global {
    #play-button:hover {
      background-color: lightgray;
      color: black;
    }
    #play-button:focus {
      outline:none;
    }
    .ticks {
      font-size: 10px;
      color: white;
    }
    .track,
    .track-inset,
    .track-overlay {
      stroke-linecap: round;
    }
    .track {
      stroke: #000;
      stroke-opacity: 0.3;
      stroke-width: 3px;
    }
    .track-inset {
      stroke: lightgray;
      stroke-width: 2px;
    }

    .track-overlay {
      pointer-events: stroke;
      stroke-width: 50px;
      stroke: transparent;
      cursor: crosshair;
    }
    .handle {
      fill: #fff;
      stroke: #000;
      stroke-opacity: 0.5;
      stroke-width: 1.25px;
    }
    #vis{
      @include media($max-md) {
        display: block;
        svg {
          display: block;
          margin: auto;
        }
      }
      @include media($min-md) {
        display: flex;
        justify-content: space-around;
      }
    }
    #mobility-buttons{
      clear: left;
      button {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
    #text{
      color: white;
      display: inline-block;
    }
    div.tooltip {
      position: absolute;
      text-align: center;
      width: auto;
      height: auto;
      font: 12px sans-serif;
      color: black;
      background: white;
      border: 0px;
      pointer-events: none;
      text-align: center;
      vertical-align: center;
    }
    .line {
      stroke: #0072CE;
      fill: none;
      stroke-width: 3;
      shape-rendering: auto;
    }
    .grid line {
      stroke: lightgrey;
      stroke-opacity: 0.7;
      shape-rendering: crispEdges;
    }
    .grid path {
      stroke-width: 0;
    }
    .axisGray line{
      stroke: #7f7f7f;
    }
    .axisGray path{
      stroke: #7f7f7f;
    }
    .axisGray text{
      fill: #7f7f7f;
    }
  }
}
