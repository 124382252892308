@import "src/stylesheets/utils/index";

.mainSections {
  > div {
    border-bottom: 1px solid #cde8ff;
    padding-bottom: 32px;
    margin-bottom: 32px;
  }

  &.noBorder > div:nth-child(-n + 2):not(:last-child) {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.overviewBlock {
  h3 {
    margin: 0;
  }

  .statValue {
    font-weight: 300;
    font-size: 2.25rem;
    color: $color-blue;

    &.loading {
      color: scale-color($color-blue, $saturation: 50%, $alpha: -50%);
    }
  }
}

.highestRecord {
  h4 {
    font-size: 0.875rem;
    font-weight: 500;
    color: $color-dark-blue;
    margin: 0.5rem 0 0.15rem;

    svg {
      margin-right: 6px;
      width: 10px;
      height: auto;
      vertical-align: middle;
    }
  }

  div {
    color: $color-blue;

    :first-child {
      font-size: 1.5em;
    }

    &.loading {
      color: scale-color($color-blue, $saturation: 50%, $alpha: -50%);
    }
  }
}

.stateAboutContainer {
  margin-top: 32px;

  @media screen and (min-width: 769px) {
    max-width: 70%;
  }
  > h3 {
    margin-top: 0;
    margin-bottom: 16px;
  }
  > p {
    border-left: 5px solid #d4eaff;
    padding-left: 15px;
    padding-bottom: 0;
    margin-bottom: 32px;
  }
}

.countryOverview {
  @include media($max-sm) {
    display: flex;
    flex-direction: column-reverse;
  }
  @include media($min-sm) {
    display: flex;
    flex-direction: row;
    > * {
      width: 100%;
      flex: 1;
    }
    > :first-child {
      margin-right: 24px;
    }
  }

  .country-image {
    border: solid 32px #f3f6f8;
    background-color: #f3f6f8;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 180px;

    @include media($min-sm) {
      min-height: 240px;
    }
  }
}

.iconHeader {
  display: flex;
  align-items: center;
  svg {
    margin-right: 16px;
  }
}
