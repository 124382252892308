@import '~stylesheets/utils/index';

.base {
  display: flex;
  // justify-content: space-between;
  flex-direction: column;

  width: 72px;
  height: 100%;
  min-height: 400px;
  background-color: #3c629b;

  p {
    text-align: center;
    font-size: 16px;
    line-height: 15px;
    font-weight: 700;
    padding: 36px 8px;
    position: sticky;
    top: 300px;
    bottom: 60px;
    margin-top: 120px;
    margin-bottom: auto;

    @include media($min-md-ht) {
      margin-top: 72px;
    }
  }

  .arrowHeadContainer {
    background-color: #1b4581;

    .arrowHead {
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 36px 36px 0 36px;
      border-color: #3c629b transparent transparent transparent;
    }
  }

  .arrowTail {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 36px 36px 0 36px;
    border-color: #1b4581 transparent transparent transparent;
  }

  &.light {
    background-color: #e8f0fa;
    color: $accent-secondary;
    .arrowHeadContainer {
      background-color: white;
    }
    .arrowHead {
      border-color: #e8f0fa transparent transparent transparent;
    }
    .arrowTail {
      border-color: white transparent transparent transparent;
    }
  }

  &.dark {
    background-color: #3c629b;
    color: white;
    .arrowHead {
      border-color: #3c629b transparent transparent transparent;
    }
  }
}
