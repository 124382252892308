@import "~stylesheets/utils/index";

tr.base {
  &.active {
    background: #dae7f4;
  }
  &.stopped {
    background-color: rgba($color: #e87563, $alpha: 0.1) !important;
  }
}
.item {
  // padding-left: 10px !important;

  a {
    padding-left: 20px;
  }
}
.stoppedDate {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-top: 5px;

  h4 {
    font-family: Gentona;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #e87563;
    margin: 0;
    margin-left: 5px;
  }

  span {
    color: #8e8e8e;
  }
}

.alert {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  left: -45px;
  top: 40px;
}
