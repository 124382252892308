@import './var';

.timeline {
  width: 100%;
  position: relative;
  max-width: 1088px;
  margin: 0 auto;

  .filter__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
  }

  .filter__left__side {
    display: flex;
    flex-direction: column;

    .filter__header__section {
      display: flex;
      flex-direction: row;
      align-items: center;

      .filter__header {
        font-size: 26px;
        font-weight: bold;
        text-align: left;
        color: #002961;
      }
    }
  }

  .event__row {
    display: flex;
    flex-direction: column;
    color: #002961;
  }

  .event__row__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    padding-bottom: 5px;
    border-bottom: 1px solid #002961;
  }

  .event__row__header__cumulative__section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
