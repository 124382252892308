@import "~stylesheets/utils/index";

.base {
  display: flex;
  flex-direction: column;
  max-width: 832px;
  margin-bottom: 64px;

  .header {
    margin: 0;
    margin-bottom: 12px;

    font-family: "Gentona";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
    text-transform: uppercase;

    color: #092c74;
  }

  p {
    font-family: "Gentona";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #535353;

    padding: 0;
  }
}
