@import "~stylesheets/utils/index";

.mainContent,
.bottomContent {
  display: flex;
}

.mainContent,
.bottomContent {
  flex-direction: column;
}

.vidoeFrame {
  flex: 1;
  margin-bottom: 24px;
}

@include media($min-md) {
  .vidoeFrame,
  .textContainer {
    margin-right: 32px;
  }
}
@include media($min-sm) {
  .vidoeFrame {
    margin-right: 32px;
  }
}
@include media($max-md) {
  .mainContent {
    flex-direction: row;
  }
  .textContainer {
    width: 60%;
  }
}

@include media($max-sm) {
  .mainContent {
    flex-direction: column;
  }
  .textContainer {
    width: 100%;
  }
}

.showButton {
  width: fit-content;
}
