@import "~stylesheets/utils/index";

.base {
  display: flex;
  flex-direction: column;

  max-width: 832px;

  @include media($max-md) {
    width: 100%;
    max-width: 100%;
  }

  .imageBlock {
    display: flex;
    align-items: center;

    // @include media($min-md) {
    //   height: 100%;
    // }

    @include media($max-md) {
      display: none;
    }
  }

  .contentBlock {
    background: #fbfbfb;
    width: 100%;
    padding: 20px 24px;

    @include media($max-md) {
      padding: 16px 0;
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    h2 {
      margin: 0;
      margin-bottom: 12px;

      font-weight: 400;
      font-size: 26px;
      line-height: 31px;
      text-transform: uppercase;
    }

    h3 {
      margin-top: 24px;
      margin-bottom: 4px;

      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
      color: #535353;
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #535353;
    padding: 0;
  }

  @include media($max-sm) {
    margin-top: 30px;
    @include clearfix;
  }

  display: flex;
  @include media($min-md) {
    align-items: center;
    flex: 2.5 1;
  }
}

.crcNowWrapper {
  .imageBlockOuter {
    display: none;
    @include content-container;
    width: 100%;
    padding: 0 !important;
    // max-height: 232px;

    // div {
    //   height: 213px;
    //   width: inherit;
    //   background-size: cover;
    //   background-repeat: no-repeat;
    //   background-position: top;

    //   flex: none;
    //   order: 0;
    //   align-self: stretch;
    //   flex-grow: 0;
    // }
  }

  @include media($max-md) {
    .imageBlockOuter {
      display: flex;
      align-items: center;
    }
  }

  .crcNowContainer {
    @include content-container;

    display: flex;
    margin-top: 52px;

    .crcNowBlock {
      margin-right: 32px;
    }

    @include media($max-md) {
      flex-direction: column;
      margin-top: 0;

      .crcNowBlock {
        margin-top: 0;
        margin-right: 0;
      }

      gap: 48px;
    }
  }
}
