.button {
  font-family: $ff--regular;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: $border-radius;
  cursor: pointer;
  background-color: $c--gray-light;
  color: $c--text;
  transition: $t--dur color, $t--dur background-color;
  border: none;

  &:not(:last-child) {
    margin-right: $s--small;
  }

  &.is-selected {
    color: #fff;
    background-color: $c--blue-dark;
  }

  &:focus {
    outline: none;
    border: none;
  }
}

a.button {
  text-decoration: none;
}
