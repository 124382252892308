@import '~stylesheets/utils/index';

.base {
  position: fixed;
  z-index: 10;
  right: 0;
  top: 0;
  display: none;

  &.open {
    display: block;
    width: 100%;
    height: 100%;
    background-color: $tran;
  }
}
