@import "~stylesheets/utils/index";

.base {
  display: flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;

  .infoBlock {
    display: none;
    @include media($min-sm) {
      left: calc(100% + -16px);
    }
    @include media($max-sm) {
      left: calc(100% + -9px);
    }
  }

  .highlight {
    display: none;
    position: absolute;
    background-color: rgba(34, 111, 183, 0.4);
    top: -3px;
    left: -3px;
    height: calc(100% + 6px);

    @include media($min-sm) {
      width: calc(100% + -10px);
    }
    @include media($max-sm) {
      width: calc(100% + -3px);
    }
  }

  &:hover {
    @include media($min-md) {
      .infoBlock {
        display: flex;
      }
      .highlight {
        display: flex;
      }
    }
  }
}

.modal {
  background-color: transparent;
  svg {
    top: 36px;
    right: 36px;
    path {
      fill: #979797;
    }
  }
  .infoBlockMobile {
    position: unset;
  }
}
