@import '~stylesheets/utils/index';

.base {
  table {
    border-collapse: collapse;
    border: none;
    font-size: 14px;
    color: #06276d;
    letter-spacing: 0.04px;

    table-layout: fixed;

    td {
      border: none;
      text-align: left;
      line-height: 1;
      font-size: 12px;

      @include media($min-lg) {
        font-size: 14px;
      }
    }

    tr:first-child {
      td {
        padding-top: 16px;
      }
    }

    th {
      text-align: left;
      font-size: 14px;
      color: #49ace1;
      letter-spacing: 0.05px;
      text-transform: uppercase;
      font-weight: normal;
      border: none;
      border-bottom: 1px solid #dfdfdf;
      line-height: 1;
      vertical-align: bottom;
    }
  }
}

.legend {
  margin-bottom: 32px;

  strong {
    padding: 16px 0;
    text-align: left;
    font-size: 16px;
    color: #49ace1;
    letter-spacing: 0.05px;
    text-transform: uppercase;
    font-weight: normal;
  }

  span {
    font-style: italic;
  }
}
