@import '../StyleGuide.module.scss';

.container {
  @include vw-container($max-width: $lg);
}

.major-row-2 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 2rem;
}

.minor-row-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
}

.minor-row-2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 2rem;
}
