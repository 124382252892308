@import '~stylesheets/utils/index';

// .base {
// }

.container {
  @include content-container;
  padding: 8px 0;
}

.embed {
  position: relative;
  height: calc(100vh - 68px);

  @include media($min-sm) {
    height: calc(100vh - 104px);
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    @include trbl;
  }

  //Negate Header Nav to prevent double scroll bar on y axis 
  @media screen and (min-width: 768px){
      margin-top: -50px;
  }
}

.loading {
  @include centerXY;
  background: white;
  width: 320px;
  text-align: center;

  h5 {
    color: $accent-secondary;
  }
}
