@import '~stylesheets/utils/index';

.base {
  flex-grow: 1;
  margin-left: 16px;
  flex-shrink: 0;

  @include media($min-sm) {
    margin-left: 0;
  }
}