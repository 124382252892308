@import '~stylesheets/utils/index';

.base {
  overflow: hidden;

  g a:hover {
    opacity: 0.5;
  }
}

.baseUSA {
  svg {
    margin-left: -25px;
  }
}

.chooser {
  @include select-wrapper($accent-secondary, transparent, white);
  border-radius: 4px;
  padding: 0;

  @include media($max-sm) {
    flex-grow: 1;
  }
  select:focus {
    outline: 0;
  }
}

.chooser select {
  background-color: transparent;
  color: black;
}

.chooser::before {
  color: black;
}

.chartContainer {
  margin: 0;
}

.snapshotMenu {
  display: none;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  border-top: 1px solid #CDE8FF;
  padding-top: 16px;

  @include media( $min-sm ) {
    padding-top: 32px;
  }
}

.buttonRow button {
  height: 100%;
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
  display: inline-block;
  padding: 0 12px;
  border-radius: 4px;
  margin-right: 20px;
  text-align: center;
  cursor: pointer;
}

.activeState {
  background: #002d72;
  color: white;
  select {
    color: white;
  }
  option {
    background-color: #002d72;
  }
}

.activeState::before {
  color: white;
}

.inactiveState {
  background-color: lightgrey;
  color: black;
}

.statePageLink {
  @include media($max-sm) {
    margin-top: 16px;
  }

  @include media($min-sm) {
    margin-left: 16px;
  }
}

.chartToggles {
  margin-top: 64px;
  padding-left: 64px;
  display: flex;
  justify-content: center;
  @media (max-height: 899px) {
    max-width: 720px;
  }
  @include media ($max-sm) {
    padding-left: 0;
  }

  .button {
    @include generic-button();
    background-color: $color-light-gray;
    color: $base-font-color;
    margin-right: 8px;
    cursor: pointer;
    margin: 0 16px 0 0;
    &:hover,
    &.active {
      color: white;
      background: $accent-secondary;
    }
  }
}

.chartInstruction {
  margin: 0;
  padding-left: 48px;
  @media (max-height: 899px) {
    max-width: 720px;
  }
  @include media ($max-sm) {
    padding-left: 0;
  }
  p {
    margin: 0;
    text-align: center;
    padding: 0;
    padding-top: 16px;
  }
}
