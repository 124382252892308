@import '~stylesheets/utils/index';

.base {
  position: relative;
  height: 100%;

  @include media( $max-md ) {
    padding-top: 16px;
  }

  @include media( $max-sm ) {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.container {
  @include content-container;
  display: flex;
  height: 100%;
}

.aside {
  @include media($min-md) {
    width: 280px;
    flex-basis: 280px;
    flex-shrink: 0;
    margin-right: 32px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
  }

  @include media($max-md) {
    position: fixed;
    @include trbl(auto, 16px, 16px, 16px);
    width: auto;
    z-index: $foreground;
    margin: 0;
    padding: 0;

    > *:first-child {
      display: none;
    }

    > *:nth-child(2) {
      width: 100%;
    }
  }
}

.main {
  display: flex;
  flex-direction: column;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex-basis: calc(100% - 312px);
  }
}

.mainHeader {
  display: flex;
  align-items: center;

  @include media( $max-sm ) {
    flex-shrink: 0;
  }
}

.tourCTA {
  flex-shrink: 0;
  margin-left: 32px;
  position: relative;
  padding-left: 16px;
  display: flex;
  align-items: center;

  > *:first-child {
    top: 50%;
  }

  @include media( $max-md ) {
    margin-bottom: 16px;
  }
}

.startButton {
  margin-left: 8px;
}
