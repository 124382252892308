@import "src/stylesheets/utils/index";

// .base {
// 	position: absolute;
// 	width: 100%;
// 	max-width: 250px;
// 	height: fit-content;
// 	font-family: Gentona;
// 	background-color: white;
// 	z-index: 2;
// 	padding: 16px;
// 	border-radius: 16px;
// 	box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.28);
// 	box-sizing: content-box;
// }

// .title {
// 	font-size: 16px;
// 	line-height: 19px;
// 	text-transform: uppercase;
// }

// .content {
// 	color: $color-dark-gray;
// 	padding: 0;
// 	margin: 16px 0;
// 	font-size: 14px;
// 	line-height: 17px;
// }

.base {
	position: absolute;
	z-index: 4000;
	display: flex;
	flex-direction: column;
	margin: 0 32px;
	padding: 12px;
	background: linear-gradient(0deg, rgba(232, 117, 99, 0.1), rgba(232, 117, 99, 0.1)), rgba(255, 255, 255, 0.8);
	border: 1px solid rgba(232, 117, 99, 0.6);
	border-radius: 2px;
	margin-bottom: 20px;

	h3 {
		font-size: 16px;
		margin: 0;
	}
	p {
		line-height: 16.8px;
		padding-bottom: 10px;
	}
	.stoppedReportingDate {
		color: #e87563;
		padding-bottom: 0;
		font-weight: 500;

		span {
			font-weight: 400;
			color: #8e8e8e;
		}
	}

	@include media($max-md) {
		margin: 0 16px;
		margin-top: 20px;
	}
}
