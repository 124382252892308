@import '~stylesheets/utils/index';

.base {
  display: inline-flex;
  align-items: center;
  padding: 4px 1px;

  border: 1px solid $color-light-gray;
  border-radius: 4px;
  background: $color-white;

  .arrow,
  .number {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1rem;
    color: $color-black;

    margin: 0 3px;
    border-radius: 4px;
    transition: background 0.25s, color 0.25s;

    // resets
    appearance: none;
    background: none;
    border: 0;
    outline: 0;
    padding: 0;
    cursor: pointer;

    &:not(.active):hover {
      background: $color-light-gray;
    }
    &.active {
      background: $color-dark-blue;
      color: $color-white;
    }
  }

  .arrow {
    svg {
      width: 1.5rem;
      height: 1.5rem;
      transition: filter 0.25s, opacity 0.25s;
    }
    &:disabled,
    &.disabled {
      cursor: not-allowed;

      svg {
        filter: grayscale(100%);
        opacity: 0.2;
      }
      &:hover {
        background: none;
      }
    }
  }
}
