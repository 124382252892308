@import '~stylesheets/utils/index';

.base {
  // Overrides
  border: 0;
  // outline: 0;
  background: none;
  padding: 0 15px;
  cursor: pointer;
  font-weight: 400;
  // Layout
  font-size: 1.125rem;
  $button-height: 2.33em;
  height: $button-height;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background 0.25s, color 0.25s;

  .icon {
    width: 19px;
    height: 19px;
    margin-left: 0.6em;
    transition: filter 0.25s;


    &.icon-left {
      transform: rotate(180deg);
    }
  }

  &.style-filled {
    background: $color-dark-blue;
    color: $color-white;
    font-weight: 300;

    &:hover,
    &.active {
      background: $color-blue;
      .icon {
        filter: brightness(1.5) grayscale(100%);
      }
    }
  }

  &.style-bordered {
    box-shadow: inset 0 0 0 1.5px $color-blue;
    color: $color-dark-blue;

    &:hover,
    &.active {
      background: $color-blue;
      color: $color-white;
      .icon {
        filter: brightness(1.5) grayscale(100%);
      }
    }
  }

  &.style-plain,
  &.style-underlined {
    color: $color-dark-blue;
    padding: 0;

    @include media($min-sm) {
      &:hover,
      &.active {
        color: $color-blue;
      }
    }
  }


  &.style-tip {
    color: $color-blue;
    padding: 0;

    @include media($min-sm) {
      &:hover,
      &.active {
        color: white;
        background: $color-dark-blue;
      }
    }
  }

  &.style-underlined {
    text-decoration: underline;
  }

  &.style-filled-light {
    background: #f3f6f8;
    color: $color-dark-blue;
    font-weight: 600;
    @include font-size( 14px );
    line-height: 1em;
    display: flex;
    height: auto;

    span {
      flex-grow: 1;
    }

    &:hover,
    &.active {
      background: $color-blue;
      color: white;
    }
  }

  &.shape-square {
    border-radius: 0;
  }

  &.shape-rounded {
    border-radius: calc(#{$button-height} / 2);
  }

  // Icon-only buttons should be exactly square
  // Render at double size and scale down to avoid rounding issues
  &.icon-only {
    height: $button-height * 2;
    width: $button-height * 2;
    margin-right: -$button-height;
    margin-bottom: -$button-height;
    transform-origin: top left;
    transform: scale(0.5);

    .icon {
      width: 38px;
      height: 38px;
      margin: 0;
    }

    &.shape-rounded {
      border-radius: 50%;
      .icon.icon-play {
        transform: translateX(2px);
      }
    }
    &.style-bordered {
      box-shadow: inset 0 0 0 6px $color-blue;
    }
  }
}
