@import '~stylesheets/utils/index';

.container {
  display: -ms-grid;
  display: grid;
  width: 100%;
  padding: 0;
  margin: 1rem 0;
}

$divider-color: transparentize(
  mix($color-light-blue, $color-light-gray, 75%),
  0.5
);

.divider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '';
    display: block;
    background: $divider-color;
  }

  &.horizontal::after {
    width: 100%;
    height: 3px;
    transform: scaleY(0.5);
  }

  &.vertical::after {
    width: 3px;
    height: 100%;
    transform: scaleX(0.5);
  }
}
