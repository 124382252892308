@import '~stylesheets/utils/index';

.container {
  @include content-container;
  margin-bottom: 32px;
}

.actions {
  padding-top: 16px;
  margin-bottom: 32px;

  @include media($min-sm) {
    @include grid(2);
    margin: 0;
  }
}

.button {
  @include generic-button;
  border-radius: 0;
  display: flex;
  align-items: center;
  margin: 0 0 16px;
  padding: 16px;
  @include font-size(14px);
  font-weight: bold;
  line-height: 1.2;

  @include media($min-sm) {
    padding: 32px;
    @include font-size(24px);
    margin: 0;
    margin-right: 16px;
  }

  > svg {
    width: 52px;
    height: auto;
    margin-right: 16px;
    flex-shrink: 0;

    @include media($min-sm) {
      width: 140px;
    }
  }

  span {
    svg {
      display: inline;
    }
  }

  &:hover,
  &:active,
  &:focus {
    transform: scale(1.1);
    z-index: $middleground;
  }

  &.new {
    @include new;
  }
}
