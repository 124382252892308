@import '~stylesheets/utils/index';

.base {
  > h3 {
    @include type-scale( 40px, 28px );
    line-height: 1.1em;
    color: $accent-secondary;
    margin: 16px 0 8px 0;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: $accent-secondary;
    line-height: 1.4;
  }
}
