@import "~stylesheets/utils/index";

.container {
  @include content-container;
  p {
    max-width: 92ch;
  }
  .articleFeed {
    a {
      background: none !important;
      box-shadow: none !important;
    }
  }
}
