@import 'src/stylesheets/utils/index';

.base {
  position: relative;
  margin: 48px 0 0;
  padding: 48px 0;
  border-top: 1px solid #cde8ff;
  border-bottom: none !important;
  margin-bottom: 0 !important;

  &:before {
    content: '';
    display: block;
    width: 280px;
    max-width: 50%;
    height: 4px;
    background-color: $accent-primary;
    position: absolute;
    top: 0;
  }

  @include media($min-sm) {
    display: flex;
    align-items: flex-start;
  }
}

.title {
  margin-bottom: 32px;

  @include media($min-sm) {
    flex-shrink: 0;
    padding-right: 48px;
  }

  @include media($min-md) {
    padding-right: 64px;
  }
}

.titleIcon {
  width: 40px;
  height: auto;
  flex-shrink: 0;
  margin-right: 16px;
}

.title {
  display: flex;
  align-items: center;
  color: $accent-secondary;

  h2 {
    margin: 0;
    line-height: 1;
  }
}

.content {
  flex-grow: 1;

  @include grid( 1 );

  @include media( $min-sm ) {
    @include grid( 3 );
  }
}

.statTitle {
  margin: 0;
  line-height: 1.2;
}

.statValue {
  font-weight: 300;
  font-size: 2.25rem;
  color: $accent-primary;

  @include media($min-sm) {
    font-size: 1.8rem;
  }

  @include media($min-lg) {
    font-size: 2.25rem;
  }
}

.vaccinesLink {
  color: $accent-primary;
  text-decoration: underline;
  @include font-size(14px);

  &:hover,
  &:active,
  &:focus {
    color: $accent-secondary;
  }
}

.unavailable {
  opacity: 0.4;
}