@import '~stylesheets/utils/index';

.base {
  position: relative;
}

.acceleratedTimelineContainer {
  position: fixed;
  width: 100vw;
  @include fit-viewport-height;
  background: #1B4581;
  z-index: $foreground;

  @include trbl(70px, 0, 0, 0);

  @include media($min-sm) {
    @include trbl(78px, 0, 0, 0);
  }

  @include media($min-md) {
    @include trbl(154px, 0, 0, 0);
  }

  @include media( $max-sm ) {
    padding-bottom: 100px;
  }
}
