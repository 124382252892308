@import '~stylesheets/utils/index';

.base {
  padding-top: 32px;
  margin-bottom: 48px;
  @media print {
    display: none;
  }
}

.header {
  padding: 0 0 16px;

  h1 {
    margin: 0;
  }

  @include media($min-sm) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > div:last-child {
      margin-bottom: 6px;
    }
  }
}

.title {
  line-height: 1;
  padding-bottom: 16px;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 16px;
}

.items {
  margin-top: 16px;

  @include media($min-xs) {
    columns: 2;
  }

  @include media($min-sm) {
    columns: 3;
  }

  @include media($min-md) {
    columns: 4;
  }

  @include media($min-lg) {
    columns: 5;
  }

  a {
    display: block;
    background: #f3f6f8;
    color: $color-dark-blue;
    padding: 8px 12px;
    font-weight: 600;
    margin-bottom: 16px;
    break-inside: avoid-column;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      background: $color-blue;
      color: white;
    }
  }
}
