@import '~stylesheets/utils/index';

.base {
  background: black;
  padding: 0;
  flex-grow: 1;
  margin-top: -8px;
}

.container {
  @include media($min-sm) {
    width: 100%;
    max-width: none;
    flex-direction: column;
    justify-content: stretch;
  }
}

.embedContainer {
  position: relative;
  height: 0;
  max-width: 100%;
  width: 100%;
  margin-bottom: 0;
  padding-bottom: calc(100vh - 126px);

  @include media($min-sm) {
    padding-bottom: calc(100vh - 140px);
  }
}

.embedContainer iframe,
.embedContainer object,
.embedContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
