@import "~stylesheets/utils/index";

.base {
  display: flex;
  flex-direction: column;
  position: relative;

  .initialBG {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    transition: opacity 3s ease-in-out;
    background: white;
  }

  .gridBlock {
    position: relative;

    > *.grid:first-child {
      position: absolute;
      z-index: 1;
    }
  }

  .grid {
    margin: auto;

    &.fadeOut {
      transition: opacity 1s ease-in-out;
    }

    @include media($min-lg) {
      max-width: 832px;
    }
    @include media($min-sm) {
      @include grid(8, 0px);
    }
    @include media($max-sm) {
      @include grid(4, 0px);
    }
  }
}
