$c--black: #515c6a;
$c--gray: #9697a0;
$c--gray-light: #cbcbcb;
$c--gray-lighter: #e5e5e5;
$c--gray-lightest: #f7f7f7;

$c--blue-dark: #002961;

$c--teal: #60C6C6;

$c--text: $c--black;

$ff--regular: noto-sans-extracondensed, sans-serif;
$ff--alternative: noto-sans, sans-serif;
$ff--title: 'Libre Baskerville', Georgia, serif;

$fw--regular: 400;
$fw--medium: 500;
$fw--semibold: 600;
$fw--black: 900;

$s--content-width: 1100px;
$s--export-width: 530px;
$s--small: 15px;
$s--medium: 20px;
$s--large: 50px;
$s--gutter: $s--medium;

// Breakpoints
$s--phone: 767px;
$s--tablet: 1023px;
$s--desktop-sm: $s--content-width;

$t--dur: 0.2s;
$t--delay-int: $t--dur / 5;

$box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
$box-shadow-hover: 0 6px 8px 0 rgba(0, 0, 0, 0.16);
$border-radius: 5px;

$ff--regular: Gentona, Verdana, sans-serif;
