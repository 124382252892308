@import '~stylesheets/utils/index';

.container {
  @include content-container;
}

.image {
  max-width: 480px;
  width: 100%;
  margin: auto;
  display: block;
}
