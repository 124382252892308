@import '~stylesheets/utils/index';

.base {
  flex-grow: 1;
  position: relative;
  @include content-section();
  background: $accent-secondary;
  background: linear-gradient(
    -45deg,
    darken($accent-secondary, 20),
    darken($accent-secondary, 3)
  );
  color: white;

  @include media($min-sm) {
    padding: 0 0 32px;
  }
}

.embed {
  iframe {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  }

  @include media($min-sm) {
    padding: 16px 32px 0;
  }
}

.container {
  @include content-container();
}

.info {
  @include media($min-sm) {
    .callout {
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    }
  }

  h1 {
    color: white;
    @include type-scale(48px, 42px);
    margin: 0;
    padding: 0;
    color: white;
    line-height: 1;
  }

  h2 {
    margin: 0;
    text-transform: uppercase;
    @include type-scale(32px, 28px);
    color: lighten($color-blue, 30);
    margin-bottom: 8px;
  }

  p {
    @include type-scale(16px);
    line-height: 1.2;
    color: #eee;
  }
}

.embedDescription {
  padding-top: 16px;
  h5 {
    margin-top: 8px;
    margin-bottom: 0;
    text-transform: uppercase;
    @include font-size(16px);
  }

  ul {
    margin-top: 0;
    padding: 0 0 0 16px;
  }

  a {
    color: #0062ff;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      color: white;
    }
  }

  .button {
    @include generic-button;
  }
}
