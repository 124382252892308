@import "~stylesheets/utils/index";

.tooltip {
  background-color: #FCE689cc;
  opacity: 0;
  padding: 10px;
  border-radius: 20px;
  text-align: center;
  color: black;
}

.tooltipH5 {
  margin: 0;
}

.tooltipText {
  font-size: 75%;
  margin: 0;
  padding: 0;
}

.resetButton {
  width: 100%;
}
