@import "~stylesheets/utils/index";

div.base {
  --base-height: 283px;
  --breakout-side-offset: 64px;
  --breakout-vertical-offset: 64px;

  @include media($max-lg) {
    --breakout-side-offset: 32px;
    --breakout-vertical-offset: 32px;
  }

  @include media($max-sm) {
    --breakout-side-offset: 40px;
    --breakout-vertical-offset: 60px;
  }

  @include media($max-xs) {
    --breakout-side-offset: 20px;
  }

  background-color: transparent;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  min-height: 320px;
  // padding-bottom: var(--breakout-vertical-offset);

  .contentContainer {
    // @include media($min-sm) {
    //   @include vw-container($lg);
    // }

    @include media($max-sm) {
      padding-left: 0;
    }

    @include media($min-md) {
      padding-left: 224px;
    }

    @include media($min-lg) {
      padding-left: 448px;
    }

    @include media($max-md) {
      padding-right: 0;
    }
  }
}

.blowOutContainer {
  position: relative;
  @include media($max-sm) {
    height: calc(100% + var(--breakout-vertical-offset));
  }

  @include media($max-sm) {
    padding: 0 30px 0 0;
  }

  .card {
    margin-right: var(--breakout-side-offset);

    @include media($max-sm) {
      margin-right: 0;
    }

    @include media($min-sm) {
      transition: opacity 345ms 100ms, transform 345ms 100ms;
      opacity: 0;
      transform: translateX(-100px);
    }

    p {
      font-size: 20px;
    }

    font-size: 20px;

    .active & {
      transition-delay: 700ms;
      opacity: 1;
      transform: translateX(0);
    }

    @include media($max-sm) {
      margin-bottom: calc(var(--breakout-vertical-offset) / 2);
    }
  }
}

.blade {
  width: calc(100wv - 10px);
  // padding: 0 5px;
}
