@import '~stylesheets/utils/index';

.empty {
  padding: 32px 0;
  text-align: center;
  margin: 16px 0 32px;
}

.dropDown {
  background: #226FB7;
  height: calc(100vh - 126px);
  overflow: auto;
  padding: 0 20px 20px;

  h2 {
    color: #FFF;
    border: 0;
    margin-top: 0;
  }
}
