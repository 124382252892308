@import "src/stylesheets/utils/index";

.overviewBlock {
  padding: 5px 10px;
  h3 {
    margin: 0;
  }
  &.stopped {
    background-color: #e875631a;
    cursor: pointer;

    &:hover {
      .alert {
        visibility: visible;
        opacity: 1;
        transition: all 0.3s;
      }
    }
  }
  .statsBlock {
    display: flex;
    flex-direction: column;

    .statAvg {
      font-size: 14px;
      font-weight: 500;
      color: $color-medium-gray;
    }
  }
  .statValue {
    font-weight: 300;
    font-size: 2.25rem;
    line-height: 1;
    color: $color-blue;

    &.loading {
      color: scale-color($color-blue, $saturation: 50%, $alpha: -50%);
    }
  }
}

.highestRecord {
  h4 {
    font-size: 0.875rem;
    font-weight: 500;
    color: $color-dark-blue;
    margin: 0.5rem 0 0.15rem;

    svg {
      margin-right: 6px;
      width: 10px;
      height: auto;
      vertical-align: middle;
    }
  }

  div {
    color: $color-blue;

    :first-child {
      font-size: 1.5em;
    }

    &.loading {
      color: scale-color($color-blue, $saturation: 50%, $alpha: -50%);
    }
  }
}

.iconHeader {
  display: flex;
  align-items: center;
  svg {
    margin-right: 16px;
  }
}

.stoppedDate {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-top: 5px;

  h4 {
    font-family: Gentona;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #e87563;
    margin: 0;
    margin-left: 5px;
  }

  span {
    color: #8e8e8e;
  }
}

.alert {
  visibility: hidden;
  z-index: 2000;
  opacity: 0;
  transition: all 0.3s;
  width: 250px;
  left: -28px;
  top: 35px;

  @include media($max-lg) {
    left: -60px;
    top: 50px;
  }
  @include media($max-md) {
    left: -45px;
    top: 40px;
  }
  @media screen and (min-width: 423px) and (max-width: 847px) {
    width: 100%;
    left: -18px;
    top: 20px;
  }
  @media screen and (max-width: 423px) {
    width: 100%;
    left: -18px;
    top: 10px;
  }
}
