@mixin button(
  $color: $accent-primary,
  $text-color: $blue-dk,
  $text-hover-color: $bg,
  $hover-color: $blue-dk,
  $border-color: $blue-dk
) {
  font-family: $heading-font-family;
  background: $bg !important;
  //width: 100%;
  display: block;
  cursor: pointer;
  user-select: none;
  padding: 8px 14px;
  border: 1px solid $border-color;
  border-radius: 2px;

  background: $color;
  color: $text-color;
  @include font-size(12px);
  text-transform: uppercase;
  letter-spacing: 0.1em;

  transition: all 123ms;

  span {
    vertical-align: middle;
  }

  .icon {
    @include font-size(24px);
    vertical-align: middle;

    &:last-child {
      margin-left: 10px;
    }

    &:first-child {
      margin-right: 10px;
    }
  }

  &:hover {
    background: $hover-color !important;
    color: $text-hover-color;
    transition: all 333ms ease-in-out;
  }

  @include media($min-xs) {
    width: auto;
    //display: inline-block;
  }
}

@mixin pill-button($color: $accent-primary) {
  @include button($color, white, shade($color, 20), white, transparent);
}

@mixin pill-button-sm($color: $accent-primary) {
  @include button($color, white, shade($color, 20), white, transparent);
  padding: 3px 9px;
  @include font-size(10px);

  .icon {
    @include font-size(14px);

    &:last-child {
      margin-left: 6px;
    }

    &:first-child {
      margin-right: 6px;
    }
  }
}

@mixin ghost-button($color: $accent-primary) {
  @include button(transparent, $color, $color, white, $color);
}

.mapButton {
  display: inline-block;
  border: none;
  background: $color-blue;
  padding: 8px 16px;
  border-radius: 6px;
  color: white;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 12px;
    vertical-align: middle;

    &:last-child {
      margin-right: 0;
      margin-left: 12px;
    }
  }

  span {
    vertical-align: middle;
  }
}

@mixin generic-button {
  display: inline-block;
  border: none;
  background: $color-blue;
  padding: 8px 16px;
  border-radius: 6px;
  color: white;
  text-decoration: none;
  margin: 16px 0 32px;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 12px;
    vertical-align: middle;

    &:last-child {
      margin-right: 0;
      margin-left: 12px;
    }
  }

  span {
    vertical-align: middle;
  }

  &:hover,
  &:active,
  &:focus {
    color: white;
    background-color: $color-dark-blue;
  }
}
