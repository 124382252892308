@import '~stylesheets/utils/index';

.interiorHeader {
  background-color: #efefef;
  padding: 16px 0;

  .interiorHeaderContainer {
    @include content-container;

    @include media($min-sm) {
      display: flex;
      align-items: center;
    }
  }

  p {
    @include leading-scale(1.4, 1.2);

    &:last-child {
      padding-bottom: 0;
    }
  }

  h1,
  h3 {
    margin-bottom: 4px;
  }

  .contentLeft {
    @include media($min-sm) {
      flex-grow: 1;
      flex: 10;
    }
  }

  .contentMiddle {
    @include media($max-md) {
      display: none;
    }

    margin-left: 32px;

    .postCountdown {
      position: absolute;
      visibility: hidden;
    }
  }

  .contentRight {
    display: flex;

    @include media($min-sm) {
      flex-grow: 1;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 16px;
      padding-left: 16px;
      border-left: 1px solid #999999;
    }

    @include media($min-md) {
      margin-left: 32px;
      padding-left: 32px;
    }

    @include media($min-xlg) {
      flex-direction: row;
    }

    @media screen and (max-width: 380px){
      flex-direction: column;
    }

    &.postEvent {
      border: none;
    }
  }

  .eventShare {
    flex-grow: 1;
    width: 100%;
  }

  .eventCalendar {
    flex-grow: 1;
    margin-left: 16px;
    flex-shrink: 0;

    @include media($min-sm) {
      margin-left: 0;
    }
  }
}

.countdownContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  h3 {
    text-transform: uppercase;
    color: #6d6d6d;
    @include font-size(16px);
    font-weight: bold;
  }

  .timerContainer {
    display: flex;
    align-items: center;
  }

  .timerBlock {
    margin: 0 8px;

    span {
      display: block;
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .timerValue {
    font-weight: bold;
    color: #2d2d2d;
    @include font-size(32px);
    line-height: 1;
  }

  .timerLabel {
    @include font-size(10px);
    text-transform: uppercase;
    color: #6d6d6d;
  }
}