@import "src/stylesheets/utils/index";

.insightSection {
  h2 {
    font-size: 1.625rem;
    // margin: 0 0 1.4rem;
    line-height: 1.3;
  }

  .map-container {
    @include aspect-ratio(1, 1);
  }

  // When there's only one column to show (because there's no timeline data
  // available), render the map with a wider aspect ratio and at full width
  .columns.single .map-container {
    @include aspect-ratio(3, 2);

    @include media($min-sm) {
      @include aspect-ratio(2, 1);
    }
  }

  // When there are two columns to show, they go side-by-side on screens that are big enough
  .columns:not(.single) {
    @include media($min-sm) {
      @include grid(2);

      // Align top edge of map with section header
      .map-container {
        transform: translateY(#{(1.625rem * 1.3 * -1) - 1.4rem});
      }
    }
  }
}

.insightContent {
  ul {
    padding-left: 20px;
  }
}

.map-container {
  position: relative;
}

.map-container .map {
  position: absolute;
  @include trbl;
}

.map-legend {
  opacity: 0;
  position: absolute;
  @include trbl(8px, auto, auto, 8px);
  transform-origin: top left;
  transform: scale(0.8);
  background: white;

  &.visible {
    opacity: 1;
  }

  :global(.esri-legend__service) {
    padding: 8px;
  }
}

.recommendedContainer {
  display: flex;
  align-items: baseline;
  padding-top: 8px;

  svg {
    padding-right: 15px;
    width: 52px;
  }

  > span {
    text-transform: uppercase;
    font-size: 26px;
    font-weight: bold;
  }

  &.negative {
    transform: translateY(-8px);
    svg {
      transform: translateY(16px);
    }
  }
}

.highestRecord {
  > div {
    > div {
      display: inline-block;
      > span {
        font-size: 1.5rem !important;
        font-weight: 400 !important;
      }
      > span:last-child {
        font-size: 1rem !important;
      }
    }
  }

  > span {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: $color-dark-blue !important;

    > svg {
      vertical-align: middle;
      margin-right: 8px;
      width: 10px;
      height: auto;
      @include svg-color($color-dark-blue);
    }
  }
}

/* Color Schemes */
.available,
.unavailable {
  font-weight: bold;
  font-size: 16px;
}

.available {
  color: #85c6bb;
}

.unavailable {
  color: #ea927e;
}

.regionDisclaimer {
  p {
    font-style: italic;
    font-size: 14px;
    padding: 16px 0 0 0;
  }
}

.embedSiteLink {
  margin-top: 8px;
}

.iconHeader {
  display: flex;
  align-items: center;
  svg {
    margin-right: 16px;
  }
}
