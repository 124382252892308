@import '~stylesheets/utils/index';

.base {
  position: relative;
  background: white;
  border-radius: 6px;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.28);
  margin-bottom: 16px;
  padding: 16px;
  color: $accent-primary;
}

.heading {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  @include font-size(18px);
  margin-bottom: 16px;
  line-height: 1;

  span {
    margin-top: 2px;
  }

  > *:first-child {
    width: 30px;
    height: 24px;
    margin-right: 16px;
    flex-basis: 10%;
    img {
      max-width: 30px;
      margin-right: 10px;
    }
  }
}

.timeline {
  display: flex;
  flex-direction: column;
}

.phase {
  position: relative;
  background-color: #E3EDF7;
  border-radius: 6px;
  padding: 8px;
  margin-bottom: 12px;
  transition: background-color $layout-shift;
  display: flex;
  align-items: center;

  &.active {
    background-color: $accent-primary;
    color: white;
  }

  > *:first-child {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    flex-basis: 20%;
    img {
      max-width: 30px;
      margin-right: 10px;
    }
  }

  span {
    display: block;
    flex-grow: 1;
    line-height: 1;
    @include font-size( 14px );
  }

  &:after {
    content: "";
    background-image: url('./down-caret.svg');
    width: 6px;
    height: 3px;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
  }

  &:last-child {
    margin-bottom: 0;

    &:after {
      content: initial;
    }
  }
}

#preclinical-trials {
}

#phase-1-clinical-trials {
}

#phase-2-clinical-trials {
}

#phase-3-clinical-trials {
}

#approval {
}

#manufacturing {
}

#safety-monitoring {
}
