@import "~stylesheets/utils/index";

.base,
.iconContainer {
  max-height: 42px;
  max-width: 42px;

  @include media($min-md) {
    max-height: 72px;
    max-width: 72px;
  }
}
.base {
  position: relative;

  .iconContainer {
    border-radius: 9999px;
    padding: 12px;
    background: #3278c3;

    &.med {
      padding: 10px;
      background: #084067;
    }
  }

  .icon {
    width: 100%;
    height: 100%;
  }
}
