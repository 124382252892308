@import "~stylesheets/utils/index";

.base {
  background-color: $accent-secondary;
  color: white;
  padding: 32px 0;

  @include media($min-sm) {
    padding: 64px 0 120px;
  }
  @media screen and (max-width: 845px) {
    padding: 27px 0 64px;
  }

  @media print {
    display: none;
  }
}

.container {
  @include vw-container($lg);

  @include media($min-sm) {
    display: flex;
    flex-direction: column;
  }
}

.brand {
  display: block;
  max-width: 100%;
  height: auto;
}

.banner {
  width: 100%;
  max-width: 330px;
  @include media($max-xs) {
    max-width: 320px;
  }
  margin-bottom: 32px;
  height: 40px; // IE
}

.info {
  text-align: left;

  @media screen and (max-width: 514px) {
    margin-top: 16px;
    text-align: center;
  }

  a {
    color: white;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      color: $accent-primary;
    }
  }
}

.footerContent {
  display: flex;
  margin-bottom: 16px;
  border-bottom: 1.3px solid #2ab2f1;
  flex-direction: column;

  @media screen and (min-width: 1280px) {
    flex-direction: row;
  }
}

.description {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media screen and (min-width: 1280px) {
    flex-basis: 33%;
    margin-right: 64px;
  }

  div {
    @media screen and (min-width: 1280px) {
      max-width: 80%;
    }
  }

  @media screen and (max-width: 845px) {
    width: 296px;
    p {
      width: 288px;
    }
  }
}

.footerList {
  > h3 {
    font-size: 16px;
    color: #2ab2f1;
    font-weight: normal;
  }
  li > a {
    color: white;
  }
}

.infoLinks {
  li > a {
    color: white;
  }

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 514px) {
      justify-content: center;
      text-align: center;
    }

    @media screen and (min-width: 515px) {
      flex-direction: row;
      text-align: start;

      > *:not(:last-child) {
        padding-right: 20px;
        margin-right: 20px;
        border-right: 1.3px solid #2ab2f1;
      }
    }
  }
}

.donorsSection {
  display: flex;
  flex-direction: column;

  @include media($min-xlg) {
    height: 100%;
  }

  p.donorText {
    a {
      color: $color-light-blue;
      font-weight: 700;
    }
  }
  .donorImage {
    margin-top: auto;
    max-width: 350px;
    img {
      margin-left: -8px;
    }
  }
}
