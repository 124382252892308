@import '~stylesheets/utils/index';

.base {
  position: relative;
}

.story {
  @include font-size(14px);
  padding-bottom: 0;
  margin-bottom: 24px;
}

.countryItemHeader {
  position: relative;
  margin-bottom: 8px;

  display: flex;
  align-items: center;

  h3 {
    text-transform: uppercase;
    font-weight: 500;
    color: #2c3c4c;
    @include font-size(28px);
    margin: 0 16px 0 0;
    padding: 0 16px 0 0;
    border-right: 1px solid #d8d8d8;
    flex-shrink: 0;
  }

  p {
    flex-shrink: 1;
    @include font-size(10px);
    padding: 0;
    line-height: 1;
  }

  @include media($max-sm) {
    flex-wrap: wrap;

    h3 {
      width: 100%;
      width: 100%;
      padding: 0 0 2px 0;
      margin: 0 0 4px 0;
      border-right: none;
      border-bottom: 1px solid #d8d8d8;
    }

    p {
      flex-grow: 1;
      margin: 8px 0;
      @include font-size(12px);
    }
  }
}

.countryItemTrend {
  position: relative;
  margin-left: 16px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  flex-shrink: 0;

  &::before {
    @include font-size(28px);
    vertical-align: middle;
  }
}

.up {
  color: #ff6666;

  &::before {
    content: '▲ ';
  }
}

.down {
  color: #32d196;

  &::before {
    content: '▼ ';
  }
}

.countryItemPlot {
  position: relative;
}
