@import "~stylesheets/utils/index";

.base {
  &.sticky {
    display: none;
  }
  &.sticky.active {
    display: block;
  }
}

.selectBase {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;

  > p {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 0 16px 0 0 !important;
    margin: 0 !important;
  }
  > div {
    width: 100%;
    max-width: 300px;
  }
}
