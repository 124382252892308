@import "~stylesheets/utils/index";

.main {
  display: flex;
  flex-direction: column;
  font-family: "gentona", Tahoma, sans-serif !important;

  h4,
  h3 {
    font-family: "gentona", Tahoma, sans-serif !important;
    text-transform: none !important;
    letter-spacing: none !important;
    margin-bottom: 10px;
  }

  h3 {
    color: #002961;
    font-weight: bold;
    margin: 20px 0 10px 0;
  }
  h3:first-child {
    margin: 4px 0 10px 0;
  }

  p {
    font-size: 14px;
  }
  p,
  h4 {
    font-weight: 500;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 20px;

  > div {
    align-self: flex-end;
    min-width: 230px;
  }

  > div:not(:first-child) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    align-items: flex-end;

    > div {
      flex: 1;
      min-width: 230px;
      padding-right: 15px;
    }
    > div:last-child {
      flex: 1.5;
    }
  }

  > :first-child {
    display: flex;
    padding: 0 10px;
    padding-left: 0;
    flex: 1;
    flex-direction: column;
    align-self: flex-start;
    max-width: 504px;

    @media screen and (min-width: 535px) {
      p {
        flex-basis: 55%;
        padding-bottom: 0;
      }
    }
  }
}

.row:last-child {
  > div:not(:first-child) {
    > div:last-child {
      flex: 1;
    }
  }
}

.row:not(:last-child) {
  border-bottom: 2px solid lightgrey;
}

.columnHead {
  display: flex;
  flex-wrap: wrap;
  img {
    min-width: 150px;
    width: 200px;
    max-height: 84px;

    @include media($min-sm) {
      margin-right: 8px;
    }
  }
  p {
    min-width: 200px;
  }
}

.trendDescription {
  display: flex;
  flex-direction: column;
  @include media($min-sm) {
    flex-direction: row;
  }

  > * {
    flex: 1;
  }
}

.svgs {
  max-width: 45px;
  align-self: center;
  padding-right: 10px;
}

.modal {
  top: 80px !important;
}

.toolTipIconContainer {
  display: flex;
  margin-right: 48px;
  > .iconContainer:not(:last-child) {
    margin-right: 8px;
  }
}
