@import '~stylesheets/utils/index';

.base {
  position: relative;
}

.heightFix {
  >div{
    >div, >div > a {
      height: 100%;
    }
  }
}