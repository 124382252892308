@import "~stylesheets/utils/index";

.container {
  @include content-container;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include media($max-md) {
    flex-direction: column;
  }
}
