@import "~stylesheets/utils/index";

.container {
  @include content-container;
  @include media($min-sm) {
    margin-bottom: 156px;
  }
  @include media($max-sm) {
    margin-bottom: 88px;
  }
}

.eligibilityVisual {
  @include media($min-md) {
    margin-left: 32px;
  }
  @include media($max-md) {
    margin-left: 60px;
  }
  @include media($max-sm) {
    margin-left: 24px;
  }
  @media screen and (max-width: 359px) {
    margin-left: 0;
  }
}
