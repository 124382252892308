@import "~stylesheets/utils/index";

.container {
  @include content-container;
}

.raceToVaccineContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include media($min-md) {
    flex-direction: row;
  }

  .graphContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.section {
  @include vw-container($md);
}

.map {
  @include content-container;
}

.raceToVaccineContainer {
  margin-bottom: 64px;
}

.anchorsContainer {
  @include vw-container($lg);
  display: flex;
  flex-direction: row;
  align-items: center;

  @include media($max-lg) {
    display: block;
    margin-bottom: 60px;
  }

  h2 {
    // margin-right: 8px;
    color: $color-blue;
  }
  > * {
    font-size: 18px;
  }
  .anchors {
    > a {
      @include media($max-lg) {
        display: block;
      }
      > span {
        text-decoration: underline;
      }
      &::before {
        content: "• ";
      }
    }
    > * {
      margin-left: 8px;
    }
    > *:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.tableContainer {
  @include vw-container($md);
}