@import "~stylesheets/utils/index";

.base {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  .infoBlock {
    display: none;
  }
  @include media($min-md) {
    &:hover {
      .infoBlock {
        display: flex;
        left: 40%;
      }
    }
  }

  .block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include media($min-sm) {
      height: 66px;
      width: 74px;
      margin-right: 16px;
    }

    @include media($max-sm) {
      height: 29px;
      width: 32px;
      margin-right: 9px;
    }
    @media screen and (max-width: 320px) {
      margin-right: 5px;
    }

    span {
      font-weight: 700;
      color: $color-dark-gray;
      @include media($min-sm) {
        font-size: 38px;
      }
      @include media($max-sm) {
        font-size: 20px;
      }
    }

    &.legend {
      cursor: default;
      height: 17px;
      width: 20px;
      margin-right: 10px;

      span {
        font-size: 12px;
      }
    }

    &.joinNext {
      margin-right: 0;
    }

    &.hclc {
      span {
        color: #c85545;
      }
      background-color: rgba(226, 118, 101, 0.4);
    }
    &.rstl {
      span {
        color: #80a022;
      }
      background-color: rgba(167, 193, 87, 0.4);
    }
    &.sixtyfive {
      span {
        color: #d98d44;
      }
      background-color: rgba(233, 153, 79, 0.4);
    }
    &.cong {
      span {
        color: #33b589;
      }
      background-color: rgba(97, 199, 163, 0.4);
    }
    &.tchr {
      span {
        color: #e1ac2e;
      }
      background-color: rgba(249, 198, 79, 0.4);
    }
    &.othr {
      span {
        color: #2d7ab8;
      }
      background-color: rgba(129, 195, 250, 0.4);
    }
    &.rpop {
      span {
        color: #635dc8;
      }
      background-color: rgba(147, 141, 232, 0.4);
    }
    &.join {
      background-color: #b4b4b4;
    }
  }
}

.joinLink {
  background-color: #b4b4b4;

  @include media($min-sm) {
    height: 28px;
    width: 16px;
  }
  @include media($max-sm) {
    height: 16px;
    width: 8px;
  }
  &.legend {
    height: 11px;
    width: 5px;
  }
}

.legendText {
  font-size: 16px;
  font-weight: 400;
  color: $color-medium-gray;
}

.modal {
  background-color: transparent;
  svg {
    top: 36px;
    right: 36px;
    path {
      fill: #979797;
    }
  }
  .infoBlockMobile {
    position: unset;
  }
}
