@import "~stylesheets/utils/index";

.base {
  position: relative;

  @include media($min-md) {
    display: flex;
  }
}

.videoEmbed {
  position: relative;
  flex-grow: 1;
}

.videoContainer {
  @include video-container();
  margin-bottom: 0;
}

.chatEmbed {
  flex-shrink: 0;
  width: 100%;

  iframe {
    width: 100%;
    height: 100%;
    min-height: 320px;
  }

  @include media($min-md) {
    width: 320px;
  }
}
