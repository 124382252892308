@import "~stylesheets/utils/index";

a.link,
div.link {
  color: #fff;
  display: block;
  @include font-size(10px);
  line-height: 1;
  transition: color $interaction-shift;
  text-align: center;

  &:hover,
  &.active {
    color: $color-yellow;
  }

  &.active {
    font-weight: bold;
  }

  @include media($min-md) {
    @include font-size(14px);
  }

  @include media($min-lg) {
    @include font-size(16px);
  }

  &.grouped {
    font-size: 14px !important;
    white-space: nowrap;
  }
}

@include media($max-md) {
  .open {
    opacity: 1;
    visibility: visible;
    transform: none;
  }

  a.link,
  div.link {
    font-size: 16px;
    padding: 12px 16px;
    text-align: left;
  }
}
