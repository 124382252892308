@import "src/stylesheets/utils/index";

.regionDisclaimer {
  p {
    font-style: italic;
    font-size: 14px;
    padding: 16px 0 0 0;
  }
}

.embedSiteLink {
  margin-top: 8px;
}

.iconHeader {
  display: flex;
  align-items: center;
  svg {
    margin-right: 16px;
  }
}

.sectionsContainer {
  display: flex;
  flex-direction: column;

  > *:not(.page-nav-scroll-target) {
    border-top: 1px solid #cde8ff;
    padding-top: 32px;
    margin-top: 32px;
    @include media($min-sm) {
      padding-top: 48px;
    }
  }
}
