@import '~stylesheets/utils/index';

.base {
  position: relative;
}

.pageHeader {
  padding-top: 32px;
  padding-bottom: 8px;
  background: darken($color-dark-blue, 3);
  color: white;

  @include media($min-sm) {
    padding: 64px 0 42px;
  }

  h1,
  h2,
  h3,
  p,
  time {
    margin-top: 0;
    margin-bottom: 16px;
    color: white;
  }

  h1 {
    @include type-scale(48px, 36px, 36px, 50px);
    line-height: 1.1;
    margin-bottom: 8px;
    font-weight: 600;
  }

  h2 {
    @include type-scale(28px, 24px);
    font-weight: 300;
    margin-top: 0;
    max-width: 720px;
    line-height: 1.2;

    &:last-child {
      margin-bottom: 24px;
    }
  }

  h3 {
    color: $color-yellow;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-weight: 200;
    margin-bottom: 4px;
    max-width: 720px;
  }

  &.poster {
    @include wallpaper;
    @include mask-bg($accent-secondary, 0.9);
  }
}

.container {
  @include vw-container($lg);
  padding: 0 !important;
}

.content {
  padding: 32px 0;
  position: relative;
  z-index: 1;

  &.flush {
    padding: 0;
  }
}

.caption {
  max-width: 640px;
}
