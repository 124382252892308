@import '~stylesheets/utils/index';

.base {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 4px;
  background-image: linear-gradient(
    180deg,
    rgba(243, 247, 251, 0.84) 0%,
    rgba(227, 237, 246, 0.8) 100%
  );
  border: 4px solid transparent;
  transition: all $interaction-shift;

  &.active {
    border: 4px solid #74a3cc;
    background-image: linear-gradient(180deg, #e3edf6 0%, #e3edf6 100%);
  }

  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;

    h3 {
      margin-top: 4px;
      @include type-scale(14px, 16px, 20px, 20px);
      line-height: 1;
      padding-top: 2px;
    }

    img {
      display: block;
      flex-shrink: 0;
      height: 28px;
      width: 28px;
      margin-right: 8px;
      margin-left: 8px;
    }
  }

  @include media($max-md) {
    padding: 8px;

    h3 {
      overflow-wrap: break-word;
    }
  }
}
