@import "src/stylesheets/utils/index";

.container {
  @include content-container;
  padding-top: 32px;
}

.header {
  padding: 0 0 32px;

  h1 {
    margin: 0;
  }

  @include media($min-sm) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > div:last-child {
      margin-bottom: 6px;
    }
  }
}

.country-overview {
  position: relative;
  border-bottom: 0;
}

.country-image {
  border: solid 32px #f3f6f8;
  background-color: #f3f6f8;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 180px;

  @include media($min-sm) {
    min-height: 240px;
  }
}

.sectionsContainer {
  display: flex;
  flex-direction: column;

  > *:not(.page-nav-scroll-target) {
    border-top: 1px solid #cde8ff;
    padding-top: 32px;
    margin-top: 32px;
    @include media($min-sm) {
      padding-top: 48px;
    }
  }
}
