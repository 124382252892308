@import "~stylesheets/utils/index";

.outter {
  border-bottom: 1px solid #e5e5e5;
}

.base {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;

  @include media($min-sm) {
    flex-direction: row;
    align-items: stretch;
  }

  .textContentWrapper {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    padding: 16px;

    @include media($min-sm) {
      padding: 42px 16px;
    }

    @include media($min-md) {
      padding: 42px 32px;
      margin-left: calc(50vw - 485px);
    }

    @include media($min-lg) {
      margin-left: calc(50vw - 640px);
    }

    h5,
    h2 {
      color: #226fb7;
      font-weight: 300;
      text-transform: uppercase;
    }

    h3 {
      color: #092c74;
      font-weight: 400;
      font-size: x-large;
      text-transform: none;
      line-height: 1.1;
    }

    p {
      padding: 0;
      line-height: 1.28;
    }

    > *:not(:last-child) {
      margin: 0 0 10px 0;
    }

    @include media($max-sm) {
      order: 2;
      flex-basis: none;
    }

    @media print {
      margin-top: 0;
      padding-top: 0;
    }
  }

  .imageContainer {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include media($min-sm) {
      flex: 1;
      min-height: 280px;
    }

    @include media($max-sm) {
      height: 175px;
    }

    iframe {
      position: relative;
      width: 100%;
      height: 100%;
    }

    @media print {
      display: none;
    }
  }

  .shareContainer {
    display: flex;
    flex-direction: row;
    color: #8e8e8e;
    align-items: center;
    span {
      font-size: 14px;
      margin-right: 5px;
    }
    > div {
      margin: 0;
    }
    @media print {
      display: none;
    }
  }
}

.base.hasMediaLink {
  @include media($max-lg) {
    flex-direction: column;
  }
}

.stoppedAlert {
  position: relative;
  z-index: 2000;

  @include media($min-md) {
    margin-left: calc(50vw - 453px);
  }

  @include media($min-lg) {
    margin-left: calc(50vw - 608px);
  }
}
