@import "~stylesheets/utils/index";

.base {
  position: relative;

  .title {
    @include media($max-sm) {
      margin-bottom: 0;
    }
  }
}

.container {
  @include content-container;
}

.controls {
  margin-bottom: 32px;
}

.grid-footer {
  text-align: center;
}

.paginator {
  margin: 32px auto;
}

.noResults {
  text-align: center;
  padding-bottom: 64px;
}
