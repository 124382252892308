@import "~stylesheets/utils/index";

.base {
  display: flex;
  flex-direction: column;

  gap: 32px;

  .grid {
    max-width: 1280px;
    margin: auto;

    @include grid(1, 16px);

    @include media($min-md) {
      @include grid(3, 20px);
      > * {
        margin-bottom: 12px;
      }
    }
    @include media($max-lg) {
      @include grid(1, 8px);
    }
  }

  .header {
    margin: 0;
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
  }

  .contributorBlock {
    .chevronRight {
      transform: scale(1.3);
      path {
        fill: white;
      }
    }
    .button {
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      background-color: #226fb7;
      justify-content: space-between;

      @include media($max-lg) {
        font-size: 20px;
      }
      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
      @media screen and (max-width: 464px) {
        font-size: 12px;
      }
      @media screen and (max-width: 367px) {
        font-size: 10px;
      }
    }
  }
}
