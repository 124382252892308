@import '~stylesheets/utils/index';

// .base {
// }

.isMajor {
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;

  div {
    padding-right: 32px;
    margin-right: 32px;
    border-right: 1px solid #b0b8ca;

    > h2 {
      text-transform: uppercase;
      color: $color-blue;
      margin: 8px 0;
      line-height: 1em;
    }
  }
  
  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    max-width: 350px;
    line-height: 1.4;
  }
}

.isMinor {
  display: flex;
  flex-direction: column;

  @include media($min-sm) {
    max-width: 540px;
  }
}
