@import "~stylesheets/utils/index";

div.base {
  min-height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0 20px 0;
  cursor: pointer;
  position: relative;

  *:focus {
    outline: none;
  }
  &.active,
  &:hover {
    > button {
      padding-bottom: 4px;
    }
  }
  &.active {
    border-bottom: 4px solid;
  }

  .selectContainer {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 2px;

    top: 0;
    padding: 0;
    max-height: 100vh;

    @include media($min-sm) {
      max-height: 656px;
    }

    .selectButton {
      padding: 0px 36px;
      display: flex;
      align-items: center;
      flex-direction: row;
      position: relative;
      width: 100%;

      .selectDisplay {
        margin: 0;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        min-width: 180px;

        color: $color-dark-blue;
        text-overflow: ellipsis;
        font-size: 18px;
        font-weight: 600;
        line-height: 50px;
        letter-spacing: 0.1em;

        @include media($min-md) {
          max-width: 296px;
        }
      }
    }

    background: #F0F0F0;

    &.open {
      background: #F0F0F0;
      border-radius: 0;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;

      .selectButton {
        border-bottom: 1px solid rgba(255, 255, 255, 0.31);
      }
    }

    &.tabbar {
      .selectDisplay {
        font-size: 24px !important;
        font-weight: 500 !important;
        line-height: 63px !important;
      }
    }

    .optionsContainer {
      display: none;
    }
  }

  &.open {
    background: transparent;

    .optionsContainer {
      position: absolute;
      z-index: 200;
      top: 100%;
      width: 100%;
      display: flex;
      background-color: $color-light-gray;
      flex-direction: column;
      overflow: auto;

      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;

      @keyframes growDown {
        0% {
          transform: scaleY(0);
        }
        80% {
          transform: scaleY(1.1);
        }
        100% {
          transform: scaleY(1);
        }
      }
    }
  }
}

div.selectOverLay {
  position: fixed;
  z-index: 100;
  display: none;

  &.open {
    display: block;
    background-color: transparent;
    width: 100%;
    height: 100%;
  }
}

.base.dark {
  .selectContainer {
    border: 2px solid transparent;
  }
}

.displayContainer {
  display: flex;
}

.triangleIcon {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 13px 12.5px 0 12.5px;
  border-color: white transparent transparent transparent;
  &.open {
    border-width: 0 12.5px 13px 12.5px;
    border-color: transparent transparent white transparent;
  }
}
