@import "~stylesheets/utils/index";

.container {
  @include content-container;
  padding-top: 0;
}

.header {
  padding: 32px 0;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 32px;

  h1 {
    margin: 0;
  }

  @include media($min-sm) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > div:last-child {
      margin-bottom: 6px;
    }
  }
}

.menuItem {
  color: #fff;
  margin-bottom: 4px;
  font-weight: 600;
  @include font-size(16px);

  &:hover {
    color: $color-light-blue;
  }
}

.activeMenuItem {
  color: $color-yellow;
}

.base {
  position: relative;
}

.list {
  @include remove-list-styles;
  position: relative;
  padding-bottom: 16px;

  @include media($min-xs) {
    column-count: 2;
  }

  @include media($min-sm) {
    column-count: 3;
  }

  @include media($min-md) {
    column-count: 4;
  }

  @include media($min-lg) {
    column-count: 5;
  }

  li {
    position: relative;
  }
}

.controls {
  @include media($min-md) {
    display: flex;
    width: 40%;

    > div:first-child {
      margin-right: 16px;
    }
  }

  > div {
    margin-bottom: 16px;
  }

  > div {
    flex: 1;
  }
}

.showMoreContainer {
  display: flex;
  justify-content: center;
}

.regionNotesHeader {
  color: $color-blue;
  font-size: 22px;
  font-weight: 400;
}
